import React, { Fragment, memo } from 'react';
import PropTypes from 'prop-types';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {
  FileField,
  FileInput,
  SimpleForm,
  minLength,
  required,
  translate,
} from 'react-admin';
import { withStyles } from '@material-ui/core/styles';

import { SimpleFormToolbar } from 'src/components';

const styles = {
  dialogContent: {
    padding: '0 16px 0',
  },
  formInner: {
    '&>div:first-child': {
      paddingTop: 0, // to offset the extra space in CardContentInner (implemented by react-admin's SimpleForm)
    },
  },
  formToolbar: {
    margin: '8px 0px',
    justifyContent: 'flex-end',
  },
  textAboveForm: {
    paddingLeft: '24px',
    paddingBottom: '16px',
  },
};

const UploadModal = ({
  // logical props
  accept = 'image/*,video/*,application/*',
  maxSize,
  multiple,
  // presentational props
  classes,
  infoText,
  inputLabel,
  saveCommand,
  title = 'ui.action.upload',
  translate,
  // event handlers
  onCancel,
  onUpload,
}) => {
  const fileValidators = multiple
    ? [required(), minLength(1, 'ui.message.minFile')]
    : [required()];
  return (
    <Fragment>
      <DialogTitle id="upload-dialog-title">
        {translate(title, { _: title })}
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <div className={classes.textAboveForm}>{infoText}</div>
        <SimpleForm
          className={classes.formInner}
          destroyOnUnmount={true} // remove user input when closing the dialog
          form="upload-form"
          save={onUpload}
          toolbar={
            <SimpleFormToolbar
              className={classes.formToolbar}
              onCancel={onCancel}
              saveCommand={saveCommand}
            />
          }
        >
          <FileInput
            accept={accept}
            maxSize={maxSize}
            label={inputLabel} // is required bcz the react-admin's FileInput uses the Labeled component
            multiple={multiple}
            source={multiple ? 'files' : 'file'} // used to access the input values in onUpload() method
            validate={fileValidators}
          >
            {/* this "source" and "title" are used for the `transformFile` func in FileInput;
            also to display info about the File object */}
            <FileField source="src" title="title" />
          </FileInput>
        </SimpleForm>
      </DialogContent>
    </Fragment>
  );
};

UploadModal.propTypes = {
  inputLabel: PropTypes.string.isRequired,
  multiple: PropTypes.bool.isRequired,
};

export default memo(translate(withStyles(styles)(UploadModal)));
