import React, { Fragment } from 'react';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';

import styles from './styles';

const Breadcrumbs = withStyles(styles)(({ classes, items }) => (
  <ol className={classes.bcList}>
    {(items || []).map((item, index) => (
      <Fragment key={item.level}>
        {index < items.length - 1 ? (
          <Fragment>
            <li>
              <Link
                to={item.path}
                className={classnames(classes.bcText, classes.bc)}
              >
                {item.text}
              </Link>
            </li>
            <li className={classnames(classes.bcText, classes.bcDisabled)}>
              {'>'}
            </li>
          </Fragment>
        ) : (
          <span className={classnames(classes.bcText, classes.bcDisabled)}>
            {item.text}
          </span>
        )}
      </Fragment>
    ))}
  </ol>
));

export default Breadcrumbs;
