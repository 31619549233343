import deepPurple from '@material-ui/core/colors/deepPurple';

export default {
  primary: {
    main: deepPurple[500],
    dark: deepPurple[700],
  },
  secondary: {
    // used for TabIndicator
    main: deepPurple[50],
  },
};
