import React from 'react';
import { DateInput as Unstyled } from 'react-admin';
import { withStyles } from '@material-ui/core/styles';

import { inputStyles as styles } from 'src/utils/styles';

// A browser fix for this Chromium bug: https://bugs.chromium.org/p/chromium/issues/detail?id=1087501
const isChromium = navigator.userAgent.indexOf('Chrome') > -1;

const DateInput = withStyles(styles)(({ classes, ...props }) => (
  <Unstyled
    {...props}
    className={classes.root}
    InputProps={{
      // disableUnderline: true,
      classes: { root: classes.muiInput },
      inputComponent: isChromium ? ClearableInput : undefined,
    }}
  />
));

export default DateInput;

const ClearableInput = ({ inputRef, onChange, value, ...props }) => (
  <>
    <input onChange={onChange} value={value} {...props} />
    <button
      type="button"
      disabled={!value}
      onClick={() => onChange({ target: { value: '' } })}
    >
      X
    </button>
  </>
);
