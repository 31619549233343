// adapted from react-admin <SelectArrayInput> in order to style the
// <InputLabel>, <Input> and overwrite the renderValue method (in case
// we don't want to display Chips).
// source: https://github.com/marmelab/react-admin/blob/master/packages/ra-ui-materialui/src/input/SelectArrayInput.js

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Chip from '@material-ui/core/Chip';
import classnames from 'classnames';
import { compose } from 'recompose';
import get from 'lodash.get';
import { FieldTitle, addField, translate } from 'react-admin';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { withStyles } from '@material-ui/core/styles';

const sanitizeRestProps = ({
  addLabel,
  allowEmpty,
  basePath,
  choices,
  className,
  component,
  crudGetMatching,
  crudGetOne,
  defaultValue,
  filter,
  filterToQuery,
  formClassName,
  initializeForm,
  input,
  isRequired,
  label,
  limitChoicesToValue,
  locale,
  meta,
  onChange,
  options,
  optionValue,
  optionText,
  perPage,
  record,
  reference,
  resource,
  setFilter,
  setPagination,
  setSort,
  sort,
  source,
  textAlign,
  translate,
  translateChoice,
  validation,
  ...rest
}) => rest;

const styles = theme => ({
  root: {},
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: theme.spacing.unit / 4,
  },
});

export class SelectArrayInput extends Component {
  /*
   * Using state to bypass a redux-form comparison but which prevents re-rendering
   * @see https://github.com/erikras/redux-form/issues/2456
   */
  state = {
    value: this.props.input.value || [],
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.input.value !== this.props.input.value) {
      this.setState({ value: nextProps.input.value || [] });
    }
  }

  handleChange = event => {
    this.props.input.onChange(event.target.value);
    // HACK: For some reason, redux-form does not consider this input touched without calling onBlur manually
    this.props.input.onBlur(event.target.value);
    this.setState({ value: event.target.value });
  };

  renderMenuItemOption = choice => {
    const { optionText, translate, translateChoice } = this.props;
    if (React.isValidElement(optionText))
      return React.cloneElement(optionText, {
        record: choice,
      });
    const choiceName =
      typeof optionText === 'function'
        ? optionText(choice)
        : get(choice, optionText);
    return translateChoice
      ? translate(choiceName, { _: choiceName })
      : choiceName;
  };

  renderMenuItem = choice => {
    const { optionValue } = this.props;
    return (
      <MenuItem key={get(choice, optionValue)} value={get(choice, optionValue)}>
        {this.renderMenuItemOption(choice)}
      </MenuItem>
    );
  };

  renderValue = selected => {
    const { choices, classes, optionValue } = this.props;
    return (
      <div className={classes.chips}>
        {selected
          .map(item =>
            choices.find(choice => get(choice, optionValue) === item)
          )
          .map(item => (
            <Chip
              key={get(item, optionValue)}
              label={this.renderMenuItemOption(item)}
              className={classes.chip}
            />
          ))}
      </div>
    );
  };

  render() {
    const {
      choices,
      classes,
      className,
      error: fieldError,
      helperText,
      InputLabelProps,
      InputProps,
      isRequired,
      label,
      meta,
      options,
      optionText,
      optionValue,
      renderValue,
      resource,
      source,
      ...rest
    } = this.props;
    if (typeof meta === 'undefined') {
      throw new Error(
        "The SelectInput component wasn't called within a redux-form <Field>. Did you decorate it and forget to add the addField prop to your component? See https://marmelab.com/react-admin/Inputs.html#writing-your-own-input-component for details."
      );
    }
    const { touched, error: formError } = meta;

    return (
      <FormControl
        margin="normal"
        className={classnames(classes.root, className)}
        error={fieldError || !!(touched && formError)}
        {...sanitizeRestProps(rest)}
      >
        <InputLabel {...InputLabelProps} htmlFor={source}>
          <FieldTitle
            label={label}
            source={source}
            resource={resource}
            isRequired={isRequired}
          />
        </InputLabel>
        <Select
          autoWidth
          multiple
          input={<Input id={source} {...InputProps} />}
          value={this.state.value}
          error={fieldError || !!(touched && formError)}
          renderValue={
            // the default is Chip; consumer of this component can specify
            // their desired format via the props renderValue;
            // renderValue === null will use Material UI Select rendering style
            renderValue !== undefined ? renderValue : this.renderValue
          }
          // data-testid="selectArray" // use this for testing later
          {...options}
          onChange={this.handleChange}
        >
          {choices.map(this.renderMenuItem)}
        </Select>
        {touched && formError && (
          <FormHelperText formError>{formError}</FormHelperText>
        )}
        {helperText && (
          <FormHelperText error={fieldError}>{helperText}</FormHelperText>
        )}
      </FormControl>
    );
  }
}

SelectArrayInput.propTypes = {
  children: PropTypes.node,
  choices: PropTypes.arrayOf(PropTypes.object),
  classes: PropTypes.object,
  className: PropTypes.string,
  input: PropTypes.object,
  InputLabelProps: PropTypes.object,
  InputProps: PropTypes.object,
  isRequired: PropTypes.bool,
  label: PropTypes.string,
  meta: PropTypes.object,
  options: PropTypes.object,
  optionText: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
    PropTypes.element,
  ]).isRequired,
  optionValue: PropTypes.string.isRequired,
  renderValue: PropTypes.func,
  resource: PropTypes.string,
  source: PropTypes.string,
  translate: PropTypes.func.isRequired,
  translateChoice: PropTypes.bool,
};

SelectArrayInput.defaultProps = {
  classes: {},
  choices: [],
  InputLabelProps: {},
  InputProps: {},
  options: {},
  optionText: 'name',
  optionValue: 'id',
  translateChoice: true,
};

const EnhancedSelectArrayInput = compose(
  addField,
  translate,
  withStyles(styles)
)(SelectArrayInput);

export default EnhancedSelectArrayInput;
