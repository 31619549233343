import React, { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { translate } from 'react-admin';

import DatePickerForm from './date-picker-form';
import ExportModalToolbar from './modal-toolbar';
import { getDateString, today } from 'src/utils/helpers';

const DatesReconfirmModal = ({
  isOpen,
  onCancel,
  onDownload,
  translate,
  userDateFrom,
  userDateTo,
}) => {
  const [dates, setDates] = useState({
    transDateFrom: userDateFrom,
    transDateTo: userDateTo || getDateString(today),
  });
  const title = translate('ui.message.confirmTransDateFilter');
  const advice = translate('ui.message.maxDateRange');
  return (
    <Dialog
      disableBackdropClick
      maxWidth="md"
      open={isOpen}
      onClose={onCancel}
      aria-labelledby="dates-reconfirm-dialog-title"
    >
      <DialogTitle id="dates-reconfirm-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText paragraph>{advice}</DialogContentText>
        <DatePickerForm
          endDate={dates.transDateTo}
          endDateId="transDateTo"
          endDateLabel={translate('common.transDateTo')}
          onChange={e => {
            const { id, value } = e.target;
            setDates(state => ({ ...state, [id]: value }));
          }}
          startDate={dates.transDateFrom}
          startDateId="transDateFrom"
          startDateLabel={translate('common.transDateFrom')}
        />
      </DialogContent>
      <ExportModalToolbar
        handleConfirm={() => onDownload(dates)}
        handleDialogClose={onCancel}
      />
    </Dialog>
  );
};

export default translate(DatesReconfirmModal);
