import fetchNormal from 'src/data-provider/fetch-normal';
import { ACCOUNT_TYPE_V2, API_URL } from 'src/utils/constants';

function makeKeyWithSeller(key, sellerId) {
  return `${key}-seller${sellerId}`;
}

const ENUM_KEYS = [
  'bank',
  'branch',
  'debt-status',
  'object-report-status',
  'object-type',
  'region',
  'seller',
  'voucher-type',
];
export const clearEnums = () => {
  ENUM_KEYS.forEach(key => sessionStorage.removeItem(key));

  // Special handling for enums with sellerId
  Object.keys(sessionStorage).forEach(key => {
    if (key.startsWith(ACCOUNT_TYPE_V2)) {
      sessionStorage.removeItem(key);
    }
  });
};

export const getEnumPromise = (
  key,
  { sellerId, ...options },
  shouldFetchNew
) => {
  const actualKey =
    key === ACCOUNT_TYPE_V2 ? makeKeyWithSeller(key, sellerId) : key;
  let value = sessionStorage.getItem(actualKey);
  if (!value || value === '[]' || shouldFetchNew) {
    return fetchEnum(key, options, sellerId);
  } else {
    try {
      const tempArray = JSON.parse(value);
      if (Array.isArray(tempArray)) value = tempArray;
      else throw new Error('Not an array');
    } catch (error) {
      value = [];
    }
    return Promise.resolve(value);
  }
};

const fetchEnum = (key, options, sellerId) => {
  let url = `${API_URL}/enums/${key}`;
  if (key === ACCOUNT_TYPE_V2) {
    url += `?sellerId=${sellerId}`;
  }
  const callbacks = {
    success: payload => {
      const value = Array.isArray(payload.data)
        ? JSON.stringify(payload.data)
        : '';
      const actualKey =
        key === ACCOUNT_TYPE_V2 ? makeKeyWithSeller(key, sellerId) : key;
      sessionStorage.setItem(actualKey, value);
      return Promise.resolve(payload.data);
    },
    // error: error => Promise.reject(error),
  };
  return fetchNormal(url, options, callbacks);
};
