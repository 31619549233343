// adapted from: https://github.com/marmelab/react-admin/blob/master/packages/ra-ui-materialui/src/layout/Layout.js
import React, { Component, Suspense, createElement } from 'react';
import PropTypes from 'prop-types';
import { AppBar, Error, Menu, Notification, Sidebar } from 'react-admin';
import classnames from 'classnames';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import {
  createMuiTheme,
  MuiThemeProvider,
  withStyles,
} from '@material-ui/core/styles';
import { withRouter } from 'react-router';

import ChunkLoadErrorBoundary from './chunk-load-error-boundary';
import defaultTheme from 'src/theme';
import { LoadingDialog } from 'src/components';
import { SIDEBAR_OPEN_SIZE } from './custom-layout';

const styles = theme => ({
  root: {
    // backgroundColor: theme.palette.background.default,
    zIndex: 1,
    position: 'relative',
    width: '100%',
    height: '100vh',
  },
  contentWithSidebar: {
    display: 'flex',
    flexGrow: 1,
    position: 'relative',
    top: theme.appBar.height,
    height: `calc(100vh - ${theme.appBar.height}px)`,
    overflow: 'auto',
  },
  sidebar: {
    maxHeight: '100%',
    overflow: 'auto',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing.unit * 3,
    width: `calc(90% - ${SIDEBAR_OPEN_SIZE}px)`, // don't know why, but this works to make the content div fit the screen's width (i.e. not adding horizontal scrollbar)
  },
  narrowerLeftPadding: {
    paddingLeft: 5,
  },
});

const sanitizeRestProps = ({ staticContext, history, match, ...props }) =>
  props;

class Layout extends Component {
  state = { hasError: false, errorMessage: null, errorInfo: null };

  constructor(props) {
    super(props);
    /**
     * Reset the error state upon navigation
     *
     * @see https://stackoverflow.com/questions/48121750/browser-navigation-broken-by-use-of-react-error-boundaries
     */
    props.history.listen(() => {
      if (this.state.hasError) {
        this.setState({ hasError: false });
      }
    });
  }

  componentDidCatch(errorMessage, errorInfo) {
    this.setState({ hasError: true, errorMessage, errorInfo });
  }

  render() {
    const {
      appBar,
      children,
      classes,
      className,
      customRoutes,
      dashboard,
      error,
      isLoading,
      location,
      logout,
      menu,
      noSideMenu,
      notification,
      open,
      sidebar,
      title,
      ...props
    } = this.props;
    const { hasError, errorMessage, errorInfo } = this.state;

    return (
      <div
        className={classnames('layout', classes.root, className)}
        {...sanitizeRestProps(props)}
      >
        {createElement(appBar, { location, noSideMenu, open, logout })}
        <main className={classes.contentWithSidebar}>
          {!noSideMenu &&
            createElement(sidebar, {
              className: classes.sidebar,
              children: createElement(menu, {
                logout,
                hasDashboard: !!dashboard,
              }),
            })}
          <div
            className={
              noSideMenu
                ? classes.content
                : classnames(classes.content, classes.narrowerLeftPadding)
            }
          >
            <ChunkLoadErrorBoundary>
              <Suspense fallback={<p>Loading...</p>}>
                {hasError
                  ? createElement(error, {
                      error: errorMessage,
                      errorInfo,
                      title,
                    })
                  : children}
              </Suspense>
            </ChunkLoadErrorBoundary>
          </div>
        </main>
        {createElement(notification)}
        {<LoadingDialog open={isLoading} />}
      </div>
    );
  }
}

const componentPropType = PropTypes.oneOfType([
  PropTypes.func,
  PropTypes.string,
]);

Layout.propTypes = {
  appBar: componentPropType,
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
  classes: PropTypes.object,
  className: PropTypes.string,
  customRoutes: PropTypes.array,
  dashboard: componentPropType,
  error: componentPropType,
  history: PropTypes.object.isRequired,
  isLoading: PropTypes.bool,
  location: PropTypes.object.isRequired,
  logout: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.func,
    PropTypes.string,
  ]),
  menu: componentPropType,
  noSideMenu: PropTypes.bool,
  notification: componentPropType,
  open: PropTypes.bool,
  sidebar: componentPropType,
  title: PropTypes.node.isRequired,
};

Layout.defaultProps = {
  appBar: AppBar,
  error: Error,
  menu: Menu,
  noSideMenu: false,
  notification: Notification,
  sidebar: Sidebar,
};

const mapStateToProps = state => ({
  open: state.admin.ui.sidebarOpen,
  isLoading: state.admin.loading > 0,
});

const enhance = compose(
  connect(
    mapStateToProps,
    {}
  ),
  withRouter,
  withStyles(styles)
);

const EnhancedLayout = enhance(Layout);

class LayoutWithTheme extends Component {
  constructor(props) {
    super(props);
    this.theme = createMuiTheme(props.theme);
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.theme !== this.props.theme) {
      this.theme = createMuiTheme(nextProps.theme);
    }
  }
  render() {
    const { theme, ...rest } = this.props;
    return (
      <MuiThemeProvider theme={this.theme}>
        <EnhancedLayout {...rest} />
      </MuiThemeProvider>
    );
  }
}

LayoutWithTheme.propTypes = {
  theme: PropTypes.object,
};

LayoutWithTheme.defaultProps = {
  theme: defaultTheme,
};

export default LayoutWithTheme;
