// adapted from: https://github.com/marmelab/react-admin/blob/master/examples/demo/src/layout/Menu.js
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { MenuItemLink, setSidebarVisibility, translate } from 'react-admin';
import { withStyles } from '@material-ui/core/styles';

import CalendarIcon from '@material-ui/icons/InsertInvitation';
import PeopleIcon from '@material-ui/icons/People';

import SubMenu from '../submenu';

const styles = {
  root: {
    // Style applied to the MenuItem from material-ui
    fontSize: '0.9rem',
    padding: '0.5rem 0.75rem',
  },
  active: { fontWeight: 'bold', fontStyle: 'italic' }, // Style applied when the menu item is the active one
  icon: {
    // Style applied to the icon
    paddingRight: '0.75rem',
  },
};

class PayMenu extends Component {
  state = {
    menuView:
      this.props.location &&
      this.props.location.pathname &&
      /transactions|reports/.test(this.props.location.pathname),
  };

  static propTypes = {
    onMenuClick: PropTypes.func,
  };

  handleToggle = menu => {
    this.setState(state => ({ [menu]: !state[menu] }));
  };

  render() {
    const {
      classes,
      onMenuClick,
      sidebarIsOpen,
      // setSidebarVisibility,
      translate,
    } = this.props;
    // const closeSidebar = () => setSidebarVisibility(false);
    return (
      <div>
        {/* THEO DOI CONG NO */}
        {/* *************************************************************** */}
        <SubMenu
          handleToggle={() => this.handleToggle('menuView')}
          isOpen={this.state.menuView}
          sidebarIsOpen={sidebarIsOpen}
          name={'Theo dõi công nợ'}
          icon={<CalendarIcon />}
        >
          <MenuItemLink
            classes={classes}
            to={'/payables/reports/report-by-order'}
            primaryText={translate(
              'resources.receivables/reports/report-by-order.name'
            )}
            onClick={onMenuClick}
            // uncomment the below if we want to close the sidebar automatically on clicking on a page
            // onClick={onMenuClick || closeSidebar}
          />
          <MenuItemLink
            classes={classes}
            to={'/payables/reports/report-by-object'}
            primaryText={translate(
              'resources.receivables/reports/report-by-object.name'
            )}
            onClick={onMenuClick}
          />
          <MenuItemLink
            classes={classes}
            to={'/payables/transactions/type-money'}
            primaryText={translate(
              'resources.payables/transactions/type-money.name'
            )}
            onClick={onMenuClick}
          />
        </SubMenu>
        {/* TAI KHOAN CONG NO */}
        {/* *************************************************************** */}
        <MenuItemLink
          classes={classes}
          to={'/payables/accounts'}
          primaryText={'Tài khoản công nợ'}
          leftIcon={<PeopleIcon />}
          onClick={onMenuClick}
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  sidebarIsOpen: state.admin.ui.sidebarOpen,
});

const enhance = compose(
  connect(
    mapStateToProps,
    { setSidebarVisibility }
  ),
  withStyles(styles),
  translate
);

export default enhance(PayMenu);
