import React, { Fragment, memo } from 'react';
import PropTypes from 'prop-types';

import { CustomTableCell, CustomTableRow } from './styles';

const InfoTableRow = ({ cells }) => (
  <CustomTableRow>
    {cells.map(cell => {
      const { id, label, rowSpan = 1, source, width } = cell;
      return (
        <Fragment key={id}>
          <CustomTableCell rowSpan={rowSpan}>{label}</CustomTableCell>
          <CustomTableCell rowSpan={rowSpan} style={{ width }}>
            {source}
          </CustomTableCell>
        </Fragment>
      );
    })}
  </CustomTableRow>
);

InfoTableRow.propTypes = {
  cells: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      rowSpan: PropTypes.number,
      source: PropTypes.node,
    })
  ).isRequired,
};

export default memo(InfoTableRow);
