import React, { Fragment } from 'react';
import { TextField, translate } from 'react-admin';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

import Datagrid from './datagrid';

const styles = theme => ({
  errorText: {
    color: theme.palette.error.main,
    padding: '0 0 1rem 24px',
  },
  scrollable: {
    overflow: 'auto',
    maxHeight: '40vh',
    maxWidth: 'max-content',
    padding: '0 1rem',
  },
  table: {
    marginBottom: theme.spacing.unit * 2,
  },
});

const ErrorList = ({ classes, records = [], translate }) => {
  if (!records.length) return null;

  const data = records.reduce((dataObj, entry) => {
    dataObj[entry.rowId] = entry;
    return dataObj;
  }, {});

  return (
    <Fragment>
      <Typography className={classes.errorText}>
        {translate('ui.message.dataError')}:
      </Typography>
      <div className={classes.scrollable}>
        <Datagrid
          className={classes.table}
          currentSort={{ field: 'rowId', order: 'ASC' }} // props required by react-admin
          data={data}
          ids={records.map(r => r.rowId)}
        >
          <TextField
            source="rowId"
            label="common.row"
            align="center"
            sortable={false}
          />
          <ErrorsField label="common.error" />
        </Datagrid>
      </div>
    </Fragment>
  );
};

export default translate(withStyles(styles)(ErrorList));

const ErrorsField = ({ record: { msg = '[]' } }) => {
  try {
    const parsed = JSON.parse(msg);
    if (Array.isArray(parsed)) {
      return (
        <ol>
          {parsed.map((item, index) => (
            <li key={index} style={{ width: 'fit-content' }}>
              {item}
            </li>
          ))}
        </ol>
      );
    } else throw new Error('List of errors not returned as a valid array');
  } catch (e) {
    // not a valid JSON or a valid array
    return msg;
  }
};
