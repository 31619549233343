// adapted from: https://github.com/marmelab/react-admin/blob/master/packages/ra-ui-materialui/src/input/NumberInput.js
// to overwrite the (1) type props and (2) onChange, onBlur behaviors
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { FieldTitle, addField } from 'react-admin';
import MuiTextField from '@material-ui/core/TextField';

import CurrencyNumberFormat from './currency-number-format';
import sanitizeRestProps from './sanitize-rest-props';

class CurrencyInput extends PureComponent {
  handleFocus = event => {
    this.props.onFocus(event);
    this.props.input.onFocus(event);
  };

  handleBlur = event => {
    // use input.value to get the actual numeric value
    // event.target.value will give a (parsed) formatted string, which is
    // not always the value we want, and it is also harder to track
    this.props.onBlur(this.props.input.value);
    this.props.input.onBlur(this.props.input.value);
  };

  handleChange = event => {
    this.props.onChange(event.target.value);
    this.props.input.onChange(event.target.value);
  };

  render() {
    const {
      input,
      InputProps,
      isRequired,
      label,
      meta,
      options,
      source,
      resource,
      ...props
    } = this.props;
    if (typeof meta === 'undefined') {
      throw new Error(
        "The CurrencyInput component wasn't called within a redux-form <Field>. Did you decorate it and forget to add the addField prop to your component? See https://marmelab.com/react-admin/Inputs.html#writing-your-own-input-component for details."
      );
    }
    const { touched, error } = meta;

    return (
      <MuiTextField
        margin="normal"
        error={!!(touched && error)}
        helperText={touched && error}
        label={
          <FieldTitle
            label={label}
            source={source}
            resource={resource}
            isRequired={isRequired}
          />
        }
        InputProps={{
          ...InputProps,
          inputComponent: CurrencyNumberFormat,
        }}
        {...options}
        {...sanitizeRestProps(props)} // to prevent passing irrelevant fields (coming from context) to the underlying div
        {...input}
        onBlur={this.handleBlur}
        onFocus={this.handleFocus}
        onChange={this.handleChange}
      />
    );
  }
}

CurrencyInput.propTypes = {
  className: PropTypes.string,
  input: PropTypes.object,
  isRequired: PropTypes.bool,
  label: PropTypes.string,
  meta: PropTypes.object,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  options: PropTypes.object,
  resource: PropTypes.string,
  source: PropTypes.string,
  validate: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.arrayOf(PropTypes.func),
  ]),
};

CurrencyInput.defaultProps = {
  onBlur: () => {},
  onChange: () => {},
  onFocus: () => {},
  options: {},
};

export default addField(CurrencyInput);
