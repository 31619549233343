import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';

import { buttonStyles as styles } from 'src/utils/styles';
import CustomButton from './custom-button';

const ButtonWithLoading = withStyles(styles)(
  ({ classes, className, color, icon, onClick, processing, ...props }) => {
    const handleClick = event => {
      if (processing) {
        // prevent double submission
        event.preventDefault();
      } else {
        onClick(event);
      }
    };

    return (
      <CustomButton
        {...props}
        className={processing ? classes.disabled : className}
        color={processing ? 'default' : color}
        onClick={handleClick}
        icon={processing ? <CircularProgress size={25} thickness={2} /> : icon}
      />
    );
  }
);

export default ButtonWithLoading;
