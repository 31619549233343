export const getViErrorMsg = message =>
  message === 'Denied invalid accessToken'
    ? 'accessToken không hợp lệ'
    : message === 'Denied not have permission'
    ? 'Không có quyền thao tác'
    : message === 'Duplicate name'
    ? 'Trùng tên'
    : message;

export const getErrorMsgToDisplay = error => {
  if (typeof error === 'string') return getViErrorMsg(error);

  if (isNetworkError(error))
    return 'Network Error. Vui lòng kiểm tra console để biết chi tiết (nhấn F12).';

  const { name, message, status } = error;
  let msgToDisplay = `${name}: ${getViErrorMsg(message)}`;
  if (status) msgToDisplay += ` (${status})`;
  return msgToDisplay;
};

const isNetworkError = error =>
  hasTypeError(error) && isNetworkErrorMessage(error.message);
const hasTypeError = error => error instanceof TypeError;
const isNetworkErrorMessage = message =>
  Object.values(NETWORK_ERROR_MESSAGES_BY_BROWSER).includes(message);

/* Credit: https://medium.com/vinh-rocks/how-to-handle-networkerror-when-using-fetch-ff2663220435 */
const NETWORK_ERROR_MESSAGES_BY_BROWSER = {
  chrome: 'Failed to fetch',
  firefox: 'NetworkError when attempting to fetch resource.',
  safariAndOthers: 'Network request failed',
};
