import React from 'react';
import NumberFormat from 'react-number-format';

const CurrencyNumberFormat = ({ inputRef, onChange, ...props }) => (
  <NumberFormat
    thousandSeparator={true}
    decimalScale={0} // not allow floating number
    {...props}
    ref={inputRef}
    onValueChange={values => {
      onChange({ target: { value: values.floatValue } });
    }}
    isNumericString
  />
);

export default CurrencyNumberFormat;
