import React, { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { translate } from 'react-admin';

import {
  aMonthAgo,
  getAMonthBeforeThisDate,
  getDateString,
  today,
} from 'src/utils/helpers';
import DatePickerForm from './date-picker-form';
import DateScopeRadios from './date-scope-radios';
import ExportModalToolbar from './modal-toolbar';

export const ALL = 'all';
export const CUSTOMIZED = 'customized';

const MissingDatesModal = ({
  isOpen,
  onCancel,
  onDownload,
  translate,
  userDateTo,
}) => {
  const [dateOption, setDateOption] = useState(CUSTOMIZED);
  const [dates, setDates] = useState({
    transDateFrom: userDateTo
      ? getDateString(getAMonthBeforeThisDate(userDateTo))
      : getDateString(aMonthAgo),
    transDateTo: userDateTo || getDateString(today),
  });

  const title = userDateTo
    ? translate('ui.message.missingDateFrom')
    : translate('ui.message.missingDates');
  const advice = translate('ui.message.maxDateRange');

  return (
    <Dialog
      disableBackdropClick
      maxWidth="md"
      open={isOpen}
      onClose={onCancel}
      aria-labelledby="missing-dates-dialog-title"
    >
      <DialogTitle id="missing-dates-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText paragraph>{advice}</DialogContentText>
        <DateScopeRadios
          currentValue={dateOption}
          dateTo={userDateTo}
          onChange={e => setDateOption(e.target.value)}
        />
        <DatePickerForm
          disabled={dateOption === ALL}
          endDate={dates.transDateTo}
          endDateId="transDateTo"
          endDateLabel={translate('common.transDateTo')}
          onChange={e => {
            const { id, value } = e.target;
            setDates(state => ({ ...state, [id]: value }));
          }}
          startDate={dates.transDateFrom}
          startDateId="transDateFrom"
          startDateLabel={translate('common.transDateFrom')}
        />
      </DialogContent>
      <ExportModalToolbar
        handleConfirm={() =>
          dateOption === ALL
            ? userDateTo
              ? onDownload({ transDateTo: userDateTo })
              : onDownload({})
            : onDownload(dates)
        }
        handleDialogClose={onCancel}
      />
    </Dialog>
  );
};

export default translate(MissingDatesModal);
