import React from 'react';

import BaseChip from './base-chip';

const statusIdToChipColor = {
  active: 'green',
  overdue_debt: 'blue',
  exceeding_limit: 'orange',
  overdue_exceeding: 'red',
};

const AccountStatusChip = ({ status }) => {
  const id = (status && status.id) || status;
  const name = (status && status.name) || status;
  if (!name) return null;
  return <BaseChip color={statusIdToChipColor[id]} label={name} />;
};

export default AccountStatusChip;
