import { useEffect, useMemo, useState } from 'react';

import { getEnumPromise, getErrorMsgToDisplay } from 'src/utils/helpers';
import { useSellerState } from 'src/contexts';

const BaseEnumInput = ({
  endpoint,
  fetchNewAlways: shouldFetchNew,
  render,
}) => {
  const abortController = useMemo(() => new AbortController(), []);
  useEffect(() => {
    return () => abortController.abort();
  }, [abortController]);

  const { seller = {} } = useSellerState();
  const [choices, setChoices] = useState([]);
  const [errorInfo, setErrorInfo] = useState(null);
  useEffect(() => {
    const options = { signal: abortController.signal, sellerId: seller.id };
    getEnumPromise(endpoint, options, shouldFetchNew)
      .then(choices => setChoices(choices))
      .catch(error =>
        setErrorInfo({
          error: true,
          helperText: getErrorMsgToDisplay(error),
        })
      );
  }, [abortController.signal, endpoint, seller.id, shouldFetchNew]);

  return render(choices, errorInfo);
};

export default BaseEnumInput;
