import React from 'react';
import { Redirect, Route } from 'react-router-dom';

export default [
  <Route
    key="pay_0"
    exact
    path="/payables"
    render={() => <Redirect to="/payables/reports/report-by-order" />}
  />,
];
