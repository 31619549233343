import React from 'react';
import classnames from 'classnames';
import { withStyles } from '@material-ui/core/styles';

import { getErrorMsgToDisplay } from 'src/utils/helpers';

const styles = theme => ({
  errorText: {
    color: theme.palette.error.main,
    paddingLeft: theme.spacing.unit * 3,
    paddingRight: theme.spacing.unit * 3,
  },
});

const ErrorText = withStyles(styles)(({ classes, className, error }) => (
  <div className={classnames(classes.errorText, className)}>
    {getErrorMsgToDisplay(error)}
  </div>
));

export default ErrorText;
