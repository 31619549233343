import React from 'react';

import { debtTypeChoices } from 'src/utils/choices';
import { SelectInput } from 'src/components';

const DebtTypeInput = ({ component, ...props }) =>
  React.createElement(component || SelectInput, {
    ...props,
    choices: debtTypeChoices,
  });

export default DebtTypeInput;
