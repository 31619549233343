// adapted from https://github.com/marmelab/react-admin/blob/master/packages/ra-ui-materialui/src/layout/UserMenu.js
// to overwrite: onClick={handleClose}

import React, {
  Children,
  Fragment,
  cloneElement,
  isValidElement,
  useMemo,
  useState,
} from 'react';
import PropTypes from 'prop-types';
// import { useTranslate } from 'react-admin'; // react-admin v3
import { translate } from 'react-admin'; // temp fix for react-admin v2
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import AccountCircle from '@material-ui/icons/AccountCircle';

import LogoutButton from 'src/auth/logout-button';

const UserMenu = ({ children, icon, label, logout, translate }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  // const translate = useTranslate();

  const logoutButton = useMemo(() => logout || <LogoutButton />, [logout]);

  if (!logout && !children) return null;
  const open = Boolean(anchorEl);

  const handleMenu = event => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  return (
    <Fragment>
      <Tooltip title={label && translate(label, { _: label })}>
        <IconButton
          aria-label={label && translate(label, { _: label })}
          aria-owns={open ? 'menu-appbar' : null}
          aria-haspopup={true}
          color="inherit"
          disableRipple // to reduce visual noise
          onClick={handleMenu}
        >
          {icon}
        </IconButton>
      </Tooltip>
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        marginThreshold={43}
        open={open}
        onClose={handleClose}
      >
        {Children.map(children, menuItem =>
          isValidElement(menuItem)
            ? cloneElement(menuItem, {
                // onClick: handleClose,
              })
            : null
        )}
        {logoutButton}
      </Menu>
    </Fragment>
  );
};

UserMenu.propTypes = {
  children: PropTypes.node,
  label: PropTypes.string.isRequired,
  logout: PropTypes.element,
  icon: PropTypes.node,
};

UserMenu.defaultProps = {
  label: 'ra.auth.user_menu',
  icon: <AccountCircle />,
};

export default translate(UserMenu); // temp fix for react-admin v2; TODO: remove this HOC once upgraded to v3
