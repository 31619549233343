import React from 'react';
import { Sidebar } from 'react-admin';

import AppBarWithTabs from './custom-appbar';
import LayoutWithTheme from './base-layout';
import MenuSwitch from './menu-switch';
import { useFmRolePermissions } from 'src/utils/helpers';

export const SIDEBAR_OPEN_SIZE = 200;
export const SIDEBAR_CLOSED_SIZE = 47;

const NarrowerSidebar = props => (
  <Sidebar
    {...props}
    size={SIDEBAR_OPEN_SIZE}
    closedSize={SIDEBAR_CLOSED_SIZE}
  />
);

const CustomLayout = props => {
  const { canAccessReceivables, canAccessAdmin } = useFmRolePermissions();
  const { pathname } = window.location;
  const isRecView = pathname.startsWith('/receivables') && canAccessReceivables;
  const isAdminView = pathname.startsWith('/admin') && canAccessAdmin;
  const withSideMenu = isRecView || isAdminView;
  return (
    <LayoutWithTheme
      {...props}
      appBar={AppBarWithTabs}
      menu={MenuSwitch}
      noSideMenu={!withSideMenu}
      sidebar={NarrowerSidebar}
    />
  );
};

export default CustomLayout;
