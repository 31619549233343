export default theme => ({
  bcList: {
    listStyleType: 'none',
    padding: '0 0 8px 0',
    // borderBottom: '1px solid lightgrey',
    margin: 0,
    '& li': {
      position: 'relative',
      display: 'inline',
      paddingRight: theme.spacing.unit,
    },
  },
  bc: {
    textDecoration: 'none',
    '&:hover, &:active': {
      textDecoration: 'underline',
    },
  },
  bcText: {
    color: theme.palette.primary.main,
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.caption.fontSize,
    fontWeight: theme.typography.caption.fontWeight,
    lineHeight: theme.typography.caption.lineHeight,
  },
  bcDisabled: {
    color: theme.typography.caption.color,
  },
});
