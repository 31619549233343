import React from 'react';

import BaseEnumInput from './base';
import { SelectArrayInput } from 'src/components';

const ObjectTypesInput = props => (
  <BaseEnumInput
    endpoint="object-type"
    render={(choices, errorInfo) => (
      <SelectArrayInput {...props} choices={choices} {...errorInfo} />
    )}
  />
);

export default ObjectTypesInput;
