import React from 'react';
import MuiTable from '@material-ui/core/Table';
import MuiTableBody from '@material-ui/core/TableBody';
import MuiTableCell from '@material-ui/core/TableCell';
import MuiTableRow from '@material-ui/core/TableRow';
import { translate } from 'react-admin';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  summaryTable: {
    border: `2px solid ${theme.palette.grey[500]}`,
    width: 300,
    '& tr': { height: theme.tableRow.height },
  },
  valid: {
    '& td': { color: 'green' },
  },
  invalid: {
    '& td': { color: 'red' },
  },
});

const SummaryTable = ({ classes, invalidCount, totalCount, translate }) => (
  <MuiTable className={classes.summaryTable}>
    <MuiTableBody>
      <MuiTableRow>
        <MuiTableCell scope="row">{translate('common.nbRecords')}</MuiTableCell>
        <MuiTableCell numeric>{totalCount}</MuiTableCell>
      </MuiTableRow>
      <MuiTableRow classes={{ root: classes.valid }}>
        <MuiTableCell scope="row">{translate('ui.status.valid')}</MuiTableCell>
        <MuiTableCell numeric>{totalCount - invalidCount}</MuiTableCell>
      </MuiTableRow>
      <MuiTableRow classes={{ root: classes.invalid }}>
        <MuiTableCell scope="row">
          {translate('ui.status.invalid')}
        </MuiTableCell>
        <MuiTableCell numeric>{invalidCount}</MuiTableCell>
      </MuiTableRow>
    </MuiTableBody>
  </MuiTable>
);

export default translate(withStyles(styles)(SummaryTable));
