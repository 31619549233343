import React from 'react';
import classnames from 'classnames';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import { SearchInput as Unstyled } from 'react-admin';
import { withStyles } from '@material-ui/core/styles';

import { inputStyles } from 'src/utils/styles';

const styles = theme => ({
  ...inputStyles(theme),
  fullTextSearch: {
    backgroundColor: 'unset',
    marginLeft: 0,
    marginRight: 0,
    width: '100%',
  },
});

const SearchInput = withStyles(styles)(({ classes, ...props }) => (
  <Unstyled
    {...props}
    // placeholder=""
    // label="ra.action.search"
    className={classnames(classes.root, classes.fullTextSearch)}
    // InputLabelProps={{
    //   shrink: true,
    //   classes: {
    //     shrink: classes.muiLabelShrink,
    //   },
    //   FormLabelClasses: {
    //     root: classes.muiLabel,
    //   },
    // }}
    InputProps={{
      // disableUnderline: true,
      classes: { root: classes.muiInput },
      startAdornment: (
        <InputAdornment position="start">
          <SearchIcon color="disabled" />
        </InputAdornment>
      ),
    }}
  />
));

export default SearchInput;
