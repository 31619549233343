import React from 'react';

import BaseEnumInput from './base';
import { SelectInput } from 'src/components';

const AccountStatusInput = props => (
  <BaseEnumInput
    endpoint="object-report-status"
    render={(choices, errorInfo) => (
      <SelectInput {...props} choices={choices} {...errorInfo} />
    )}
  />
);

export default AccountStatusInput;
