const leftPad = (nb = 2) => value => ('0'.repeat(nb) + value).slice(-nb);
const leftPad4 = leftPad(4);
const leftPad2 = leftPad(2);

/**
 * @param {Date} v value to convert
 * @returns {String} A standardized datetime with second (yyyy-MM-ddThh:mm:ss), to be passed to an <input type="datetime-local" />
 */
const convertDateToString = v => {
  if (!(v instanceof Date) || isNaN(v)) return '';
  const yyyy = leftPad4(v.getFullYear());
  const MM = leftPad2(v.getMonth() + 1);
  const dd = leftPad2(v.getDate());
  const hh = leftPad2(v.getHours());
  const mm = leftPad2(v.getMinutes());
  const ss = leftPad2(v.getSeconds());
  return `${yyyy}-${MM}-${dd}T${hh}:${mm}:${ss}`;
};

// yyyy-MM-ddThh:mm:ss
const dateTimeRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}$/;

/**
 * Converts a date from the Redux store, with timezone, to a date string
 * without timezone for use in an <input type="datetime-local" />.
 *
 * @param {Date | String} value date string or object
 */
export const format = value => {
  // null, undefined and empty string values should not go through convertDateToString
  // otherwise, it returns undefined and will make the input an uncontrolled one.
  if (value == null || value === '') {
    return '';
  }

  if (value instanceof Date) {
    return convertDateToString(value);
  }
  // valid dates should not be converted
  if (dateTimeRegex.test(value)) {
    return value;
  }

  return convertDateToString(new Date(value));
};
