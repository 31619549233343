import React, {
  Fragment,
  createElement,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { connect } from 'react-redux';
import { fetchEnd, fetchStart, showNotification } from 'react-admin';
import { Prompt } from 'react-router-dom';

import DatesReconfirmModal from './dates-reconfirm-modal';
import {
  datetimeFilename,
  getDateString,
  getErrorMsgToDisplay,
  today,
} from 'src/utils/helpers';
import ExportButtonView from '../view';
import { fetchListInfinitely, normalDownload } from '../download-helper';
import {
  hasDateFrom,
  hasDateTo,
  hasFiltersOtherThanDates,
  hasValidDateRange,
} from './date-check-helpers';
import { LoadingDialog, NotificationModal } from 'src/components';
import MissingDatesModal from './missing-dates-modal';
import {
  SAFE_TOTAL_FOR_WRITE_FILE,
  writeDataToExcelFile,
} from '../writedata-helper';

const DatesAwareExportButton = ({
  disableExport,
  fetchEnd,
  fetchStart,
  filename,
  filterValues,
  isNewExport: isNewExportProp,
  permanentFilter,
  resource,
  showNotification,
  total,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [notiMessage, setNotiMessage] = useState('');
  const [downloading, setDownloading] = useState(false);
  const [text, setText] = useState('');

  useEffect(() => {
    const confirmExitWhileDownloading = event => {
      if (downloading === true) {
        // Displays the prompt, as stated by the specs
        event.preventDefault();
        // The following two lines provide legacy support for displaying the prompt
        event.returnValue = '';
        return '';
      }
    };
    window.addEventListener('beforeunload', confirmExitWhileDownloading);
    return () => {
      window.removeEventListener('beforeunload', confirmExitWhileDownloading);
    };
  }, [downloading]);

  const abortController = useMemo(() => new AbortController(), []);
  useEffect(() => {
    return () => {
      abortController.abort();
    };
  }, [abortController]);

  const exportNow = filter => {
    fetchStart();

    let combinedFilters = { ...filter, ...permanentFilter };
    // provide transDateFrom & transDateTo, even fake ones, to ensure
    // backend won't throw error (if any) for missing fields
    if (!hasDateFrom(combinedFilters))
      combinedFilters = { ...combinedFilters, transDateFrom: '2000-01-01' };
    if (!hasDateTo(combinedFilters))
      combinedFilters = {
        ...combinedFilters,
        transDateTo: getDateString(today),
      };

    const callbacks = {
      error: error => {
        if (error.message && error.message.includes('Báo cáo sẽ được gửi')) {
          setNotiMessage(error.message);
        } else showNotification(getErrorMsgToDisplay(error), 'warning');
      },
      final: () => {
        fetchEnd();
        setShowModal(false);
      },
    };

    const abortOption = { signal: abortController.signal };
    normalDownload(
      combinedFilters,
      resource,
      abortOption,
      `${filename}_${datetimeFilename()}`,
      callbacks
    );
  };

  const newExportNow = async filter => {
    setDownloading(true);

    let combinedFilters = { ...filter, ...permanentFilter };
    // provide transDateFrom & transDateTo, even fake ones, to ensure
    // backend won't throw error (if any) for missing fields
    if (!hasDateFrom(combinedFilters))
      combinedFilters = { ...combinedFilters, transDateFrom: '2000-01-01' };
    if (!hasDateTo(combinedFilters))
      combinedFilters = {
        ...combinedFilters,
        transDateTo: getDateString(today),
      };

    const updateProgress = percentComplete => {
      setText(
        `Đang lấy dữ liệu...${
          percentComplete > 0 ? ` ${Math.round(percentComplete * 100)}%` : ''
        }`
      );
    };

    const abortOption = { signal: abortController.signal };
    const { data, error } = await fetchListInfinitely(
      combinedFilters,
      resource,
      updateProgress,
      abortOption
    );
    if (data) {
      writeDataToExcelFile(data, resource, `${filename}_${datetimeFilename()}`);
    } else if (!abortController.signal.aborted) {
      showNotification(getErrorMsgToDisplay(error), 'warning');
    }
    setDownloading(false);
  };

  const isNewExport = useMemo(
    () => isNewExportProp && total <= SAFE_TOTAL_FOR_WRITE_FILE,
    [isNewExportProp, total]
  );

  const handleExportClick = () => {
    if (
      hasFiltersOtherThanDates(filterValues) ||
      hasValidDateRange(filterValues)
    ) {
      isNewExport
        ? newExportNow({ ...filterValues, ...permanentFilter })
        : exportNow({ ...filterValues, ...permanentFilter });
    } else setShowModal(true);
  };

  const userDateFrom = filterValues.transDateFrom;
  const userDateTo = filterValues.transDateTo;
  const modalToRender = userDateFrom ? DatesReconfirmModal : MissingDatesModal;
  return (
    <Fragment>
      <ExportButtonView
        disabled={total === 0 || disableExport || downloading}
        label="ui.action.exportToExcel"
        onClick={handleExportClick}
      />
      {showModal &&
        createElement(modalToRender, {
          isOpen: showModal,
          onCancel: () => setShowModal(false),
          onDownload: isNewExport ? newExportNow : exportNow,
          userDateFrom,
          userDateTo,
        })}
      {isNewExport ? (
        <Fragment>
          <LoadingDialog open={downloading} text={text} />
          <Prompt
            when={downloading}
            message="Quá trình tải file sẽ bị hủy nếu bạn chuyển trang. Bạn có chắc chắn muốn rời đi?"
          />
        </Fragment>
      ) : (
        <NotificationModal
          isOpen={!!notiMessage}
          onCancel={() => setNotiMessage('')}
          notiMsg={notiMessage}
        />
      )}
    </Fragment>
  );
};

export default connect(
  null,
  { fetchEnd, fetchStart, showNotification }
)(DatesAwareExportButton);
