import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import { translate } from 'react-admin';

import { ALL, CUSTOMIZED } from './missing-dates-modal';
import { df } from 'src/utils/constants';

const DateScopeRadios = translate(
  ({ currentValue, dateTo, onChange, translate }) => (
    <FormControl component="fieldset" required>
      <RadioGroup
        aria-label="Date options"
        name="date-options"
        value={currentValue}
        onChange={onChange}
      >
        <FormControlLabel
          value={ALL}
          control={<Radio color="primary" />}
          label={
            dateTo
              ? translate('ui.action.downloadAllUpTo', {
                  date: df.format(new Date(dateTo)),
                })
              : translate('ui.action.downloadAll')
          }
        />
        <FormControlLabel
          checked
          value={CUSTOMIZED}
          control={<Radio color="primary" />}
          label={translate('ui.action.pickDatesToDownload')}
        />
      </RadioGroup>
    </FormControl>
  )
);

export default DateScopeRadios;
