// These are a bit hard-coded, unfortunately :(
export const isOnLocal = /local/i.test(window.ENV_LABEL);

export const isOnFeat = /feat|test-1/i.test(window.ENV_LABEL);

export const isOnDev = /dev/i.test(window.ENV_LABEL);

export const isOnStag = /stag/i.test(window.ENV_LABEL);

export const isOnProd = !isOnLocal && !isOnFeat && !isOnDev && !isOnStag;
