import { GET_LIST, GET_ONE } from 'react-admin';

/**
 * @param {Object} response HTTP response from fetch()
 * @param {String} type One of the constants appearing at the top of this file, e.g. 'GET_LIST'
 * @param {String} resource Name of the resource to fetch, e.g. 'posts'
 * @param {Object} params The Data Provider request params, depending on the type
 * @returns {Object} Data Provider response
 */
export default (response, type, resource, params) => {
  const { json } = response;
  switch (type) {
    case GET_LIST:
      return {
        data: json.data,
        total:
          json.pagination && json.pagination.totalRows
            ? json.pagination.totalRows
            : !json.data || (json.data && !json.data.length)
            ? 0
            : json.data.length,
      };
    case GET_ONE:
      if (json.data && json.data.id === undefined)
        return { data: { id: null, ...json.data } };
      return { data: json.data };
    default:
      return { data: json.data };
  }
};
