import React from 'react';
import { Button as RaButton } from 'react-admin';
import GetAppIcon from '@material-ui/icons/GetApp';

const ExportButtonView = ({ disabled, isLoading, label, onClick }) => (
  <RaButton disabled={disabled || isLoading} label={label} onClick={onClick}>
    <GetAppIcon />
  </RaButton>
);

ExportButtonView.defaultProps = {
  disabled: false,
  label: 'ui.action.download',
  onClick: () => {},
};

export default ExportButtonView;
