import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useReducer,
} from 'react';
import Swal from 'sweetalert2';

import { getEnumPromise, getErrorMsgToDisplay } from 'src/utils/helpers';
import sellerReducer from './reducer';
import {
  updateSeller,
  updateSellers,
  updateSellersFetchError,
  STORAGE_KEY,
} from './actions';

import 'src/index.css';

function getChoiceSavedOrFirst(choices) {
  const savedId = localStorage.getItem(STORAGE_KEY);
  const savedChoice =
    savedId && choices.find(c => c.id.toString() === savedId.toString());
  return savedChoice || choices[0];
}

const SellerStateContext = createContext();
const SellerDispatchContext = createContext();

const fakeSellerChoices = [
  { id: '1', name: 'Lollipop' },
  { id: '2', name: 'Điện Thực Tế' },
];

const initialState = {};
const SellerProvider = ({ children }) => {
  const [state, dispatch] = useReducer(sellerReducer, initialState);

  const abortController = useMemo(() => new AbortController(), []);
  useEffect(() => {
    return () => abortController.abort();
  }, [abortController]);

  useEffect(() => {
    if (process.env.NODE_ENV !== 'test') {
      const options = { signal: abortController.signal };
      getEnumPromise('seller', options)
        .then(choices => {
          updateSeller(dispatch, getChoiceSavedOrFirst(choices));
          updateSellers(dispatch, choices);
        })
        .catch(error => {
          updateSellersFetchError(dispatch, error);
        });
    } else {
      updateSeller(dispatch, getChoiceSavedOrFirst(fakeSellerChoices));
      updateSellers(dispatch, fakeSellerChoices);
    }
  }, [abortController.signal]);

  if (Object.keys(state).length === 0) {
    return <p className="center-screen center-text">Lấy danh sách seller...</p>;
  }

  if (state.error) {
    Swal.fire({
      icon: 'error',
      title: 'Đã có lỗi xảy ra',
      text: getErrorMsgToDisplay(state.error),
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
    });
    return null;
  }

  if (state.sellers && state.sellers.length === 0) {
    Swal.fire({
      icon: 'warning',
      title: 'Bạn chưa có quyền seller nào',
      text: 'Vui lòng liên hệ đội hỗ trợ để được cấp quyền',
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
    });
    return null;
  }

  return (
    <SellerStateContext.Provider value={state}>
      <SellerDispatchContext.Provider value={dispatch}>
        {children}
      </SellerDispatchContext.Provider>
    </SellerStateContext.Provider>
  );
};

const useSellerState = () => {
  const context = useContext(SellerStateContext);
  if (context === undefined) {
    throw new Error('useSellerState must be used within a SellerProvider');
  }
  return context;
};

const useSellerDispatch = () => {
  const context = useContext(SellerDispatchContext);
  if (context === undefined) {
    throw new Error('useSellerDispatch must be used within a SellerProvider');
  }
  return context;
};

export * from './actions';
export { SellerProvider, useSellerState, useSellerDispatch };
