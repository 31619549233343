/**
 * A safe fallback in case branch enum set is empty, because
 * branchChoices is critical in many places throughout our app
 */
export default [
  { id: '04', name: '04', fullName: 'Cửa hàng Phong Vũ - 31 NTD', sellerId: 1 },
  {
    id: '14001',
    name: '14001',
    fullName:
      'ĐỊA ĐIỂM KINH DOANH 47 - CÔNG TY CỔ PHẦN THƯƠNG MẠI - DỊCH VỤ PHONG VŨ',
    sellerId: 1,
  },
  {
    id: '14002',
    name: '14002',
    fullName:
      'ĐỊA ĐIỂM KINH DOANH 43 - CÔNG TY CỔ PHẦN THƯƠNG MẠI - DỊCH VỤ PHONG VŨ',
    sellerId: 1,
  },
  { id: '15001', name: '15001', fullName: 'CN Phong Vũ Lazada', sellerId: 1 },
  { id: '15002', name: '15002', fullName: 'CN Phong Vũ Umbala', sellerId: 1 },
  { id: '15003', name: '15003', fullName: 'CN Phong Vũ Shopee', sellerId: 1 },
  {
    id: '24001',
    name: '24001',
    fullName:
      'ĐỊA ĐIỂM KINH DOANH 42 - CÔNG TY CỔ PHẦN THƯƠNG MẠI - DỊCH VỤ PHONG VŨ',
    sellerId: 1,
  },
  {
    id: '34001',
    name: '34001',
    fullName:
      'ĐỊA ĐIỂM KINH DOANH 44 - CÔNG TY CỔ PHẦN THƯƠNG MẠI - DỊCH VỤ PHONG VŨ',
    sellerId: 1,
  },
  {
    id: 'BH97',
    name: 'BH97',
    fullName: 'TTBH - Miền Bắc - Công ty TNHH DV- CN Phong Vũ',
    sellerId: 1,
  },
  {
    id: 'BH98',
    name: 'BH98',
    fullName: 'TTBH - Miền Trung - Công ty TNHH DV- CN Phong Vũ',
    sellerId: 1,
  },
  {
    id: 'BHMBA',
    name: 'BHMBA',
    fullName: 'Trung tâm bảo hành miền Bắc',
    sellerId: 1,
  },
  {
    id: 'CH0000',
    name: 'CH0000',
    fullName:
      'ĐỊA ĐIỂM KINH DOANH - CÔNG TY CỔ PHẦN THƯƠNG MẠI - DỊCH VỤ PHONG VŨ',
    sellerId: 1,
  },
  {
    id: 'CH0001',
    name: 'CH0001',
    fullName: 'Umbala - ĐĐKD - Công Ty CP TM DV Phong Vũ',
    sellerId: 1,
  },
  {
    id: 'CH0901',
    name: 'CH0901',
    fullName:
      'ĐỊA ĐIỂM KINH DOANH 45 - CÔNG TY CỔ PHẦN THƯƠNG MẠI - DỊCH VỤ PHONG VŨ',
    sellerId: 1,
  },
  {
    id: 'CH0902',
    name: 'CH0902',
    fullName:
      'ĐỊA ĐIỂM KINH DOANH 46 - CÔNG TY CỔ PHẦN THƯƠNG MẠI - DỊCH VỤ PHONG VŨ',
    sellerId: 1,
  },
  {
    id: 'CH0910',
    name: 'CH0910',
    fullName:
      'ĐỊA ĐIỂM KINH DOANH 7 - CÔNG TY CỔ PHẦN THƯƠNG MẠI - DỊCH VỤ PHONG VŨ',
    sellerId: 1,
  },
  {
    id: 'CH1000',
    name: 'CH1000',
    fullName: 'CHI NHÁNH 30 - CÔNG TY CỔ PHẦN THƯƠNG MẠI - DỊCH VỤ PHONG VŨ',
    sellerId: 1,
  },
  {
    id: 'CH1001',
    name: 'CH1001',
    fullName: 'CHI NHÁNH 30 - CÔNG TY CỔ PHẦN THƯƠNG MẠI - DỊCH VỤ PHONG VŨ',
    sellerId: 1,
  },
  {
    id: 'CP00',
    name: 'CP00',
    fullName:
      'Công Ty CP TM DV Phong Vũ - Tầng 5, 117-119-121 Nguyễn Du, P.Bến Thành, Q1',
    sellerId: 1,
  },
  {
    id: 'CP01',
    name: 'CP01',
    fullName:
      'ĐỊA ĐIỂM KINH DOANH 1 - CÔNG TY CỔ PHẦN THƯƠNG MẠI - DỊCH VỤ PHONG VŨ',
    sellerId: 1,
  },
  {
    id: 'CP02',
    name: 'CP02',
    fullName: 'CHI NHÁNH 2 - CÔNG TY CỔ PHẦN THƯƠNG MẠI - DỊCH VỤ PHONG VŨ',
    sellerId: 1,
  },
  {
    id: 'CP05',
    name: 'CP05',
    fullName:
      'ĐỊA ĐIỂM KINH DOANH 3 - CÔNG TY CỔ PHẦN THƯƠNG MẠI - DỊCH VỤ PHONG VŨ',
    sellerId: 1,
  },
  {
    id: 'CP06',
    name: 'CP06',
    fullName:
      'ĐỊA ĐIỂM KINH DOANH 4 - CÔNG TY CỔ PHẦN THƯƠNG MẠI - DỊCH VỤ PHONG VŨ',
    sellerId: 1,
  },
  {
    id: 'CP07',
    name: 'CP07',
    fullName:
      'ĐỊA ĐIỂM KINH DOANH 5 - CÔNG TY CỔ PHẦN THƯƠNG MẠI - DỊCH VỤ PHONG VŨ',
    sellerId: 1,
  },
  {
    id: 'CP08',
    name: 'CP08',
    fullName:
      'ĐỊA ĐIỂM KINH DOANH 6 - CÔNG TY CỔ PHẦN THƯƠNG MẠI - DỊCH VỤ PHONG VŨ',
    sellerId: 1,
  },
  {
    id: 'CP09',
    name: 'CP09',
    fullName: 'CHI NHÁNH 31 - CÔNG TY CỔ PHẦN THƯƠNG MẠI - DỊCH VỤ PHONG VŨ',
    sellerId: 1,
  },
  {
    id: 'CP10',
    name: 'CP10',
    fullName:
      'ĐỊA ĐIỂM KINH DOANH 41 - CÔNG TY CỔ PHẦN THƯƠNG MẠI - DỊCH VỤ PHONG VŨ',
    sellerId: 1,
  },
  {
    id: 'CP12',
    name: 'CP12',
    fullName: 'CN12 Công Ty CP TM DV  Phong Vũ',
    sellerId: 1,
  },
  {
    id: 'CP14',
    name: 'CP14',
    fullName:
      'ĐỊA ĐIỂM KINH DOANH 16 - CÔNG TY CỔ PHẦN THƯƠNG MẠI - DỊCH VỤ PHONG VŨ',
    sellerId: 1,
  },
  {
    id: 'CP15',
    name: 'CP15',
    fullName:
      'ĐỊA ĐIỂM KINH DOANH 17 - CÔNG TY CỔ PHẦN THƯƠNG MẠI - DỊCH VỤ PHONG VŨ',
    sellerId: 1,
  },
  {
    id: 'CP18',
    name: 'CP18',
    fullName: 'CHI NHÁNH 32 - CÔNG TY CỔ PHẦN THƯƠNG MẠI - DỊCH VỤ PHONG VŨ',
    sellerId: 1,
  },
  {
    id: 'CP19',
    name: 'CP19',
    fullName:
      'ĐỊA ĐIỂM KINH DOANH 21 - CÔNG TY CỔ PHẦN THƯƠNG MẠI - DỊCH VỤ PHONG VŨ',
    sellerId: 1,
  },
  {
    id: 'CP20',
    name: 'CP20',
    fullName: 'CHI NHÁNH 3 - CÔNG TY CỔ PHẦN THƯƠNG MẠI - DỊCH VỤ PHONG VŨ',
    sellerId: 1,
  },
  {
    id: 'CP22',
    name: 'CP22',
    fullName:
      'ĐỊA ĐIỂM KINH DOANH 24 - CÔNG TY CỔ PHẦN THƯƠNG MẠI - DỊCH VỤ PHONG VŨ',
    sellerId: 1,
  },
  {
    id: 'CP24',
    name: 'CP24',
    fullName:
      'ĐỊA ĐIỂM KINH DOANH 25 - CÔNG TY CỔ PHẦN THƯƠNG MẠI - DỊCH VỤ PHONG VŨ',
    sellerId: 1,
  },
  {
    id: 'CP25',
    name: 'CP25',
    fullName:
      'ĐỊA ĐIỂM KINH DOANH 26 - CÔNG TY CỔ PHẦN THƯƠNG MẠI - DỊCH VỤ PHONG VŨ',
    sellerId: 1,
  },
  {
    id: 'CP26',
    name: 'CP26',
    fullName:
      'ĐỊA ĐIỂM KINH DOANH 27 - CÔNG TY CỔ PHẦN THƯƠNG MẠI - DỊCH VỤ PHONG VŨ',
    sellerId: 1,
  },
  {
    id: 'CP27',
    name: 'CP27',
    fullName:
      'ĐỊA ĐIỂM KINH DOANH 28 - CÔNG TY CỔ PHẦN THƯƠNG MẠI - DỊCH VỤ PHONG VŨ',
    sellerId: 1,
  },
  {
    id: 'CP28',
    name: 'CP28',
    fullName:
      'ĐỊA ĐIỂM KINH DOANH 29 - CÔNG TY CỔ PHẦN THƯƠNG MẠI - DỊCH VỤ PHONG VŨ',
    sellerId: 1,
  },
  {
    id: 'CP31',
    name: 'CP31',
    fullName: 'ĐĐKD31 - Công Ty CP TM DV Phong Vũ',
    sellerId: 1,
  },
  {
    id: 'CP32',
    name: 'CP32',
    fullName: 'ĐĐKD32 - Công Ty CP TM DV Phong Vũ',
    sellerId: 1,
  },
  {
    id: 'CP33',
    name: 'CP33',
    fullName:
      'ĐỊA ĐIỂM KINH DOANH 33 - CÔNG TY CỔ PHẦN THƯƠNG MẠI - DỊCH VỤ PHONG VŨ',
    sellerId: 1,
  },
  {
    id: 'CP34',
    name: 'CP34',
    fullName:
      'ĐỊA ĐIỂM KINH DOANH 34 - CÔNG TY CỔ PHẦN THƯƠNG MẠI - DỊCH VỤ PHONG VŨ',
    sellerId: 1,
  },
  {
    id: 'CP35',
    name: 'CP35',
    fullName:
      'ĐỊA ĐIỂM KINH DOANH 35 - CÔNG TY CỔ PHẦN THƯƠNG MẠI - DỊCH VỤ PHONG VŨ',
    sellerId: 1,
  },
  {
    id: 'CP36',
    name: 'CP36',
    fullName:
      'Địa điểm kinh doanh 36 - Công Ty Cổ Phần Thương Mại Dịch Vụ Phong Vũ',
    sellerId: 1,
  },
  {
    id: 'CP37',
    name: 'CP37',
    fullName:
      'Địa điểm kinh doanh 37- Công Ty Cổ Phần Thương Mại Dịch Vụ Phong Vũ',
    sellerId: 1,
  },
  {
    id: 'CP39',
    name: 'CP39',
    fullName:
      'ĐỊA ĐIỂM KINH DOANH 39 - CÔNG TY CỔ PHẦN THƯƠNG MẠI - DỊCH VỤ PHONG VŨ',
    sellerId: 1,
  },
  {
    id: 'CP40',
    name: 'CP40',
    fullName:
      'Địa điểm kinh doanh 48 - Công ty cổ phần Thương mại - Dịch vụ Phong Vũ',
    sellerId: 1,
  },
  {
    id: 'CP41',
    name: 'CP41',
    fullName: 'Chi nhánh 33 - Công Ty Cổ Phần Thương Mại Dịch Vụ Phong Vũ',
    sellerId: 1,
  },
  {
    id: 'CP49',
    name: 'CP49',
    fullName:
      'Địa điểm kinh doanh 49 - Công Ty Cổ Phần Thương Mại Dịch Vụ Phong Vũ',
    sellerId: 1,
  },
  {
    id: 'CT00',
    name: 'CT00',
    fullName: 'Cty TNHH Công Nghệ Viễn Thông Phong Vũ - 31 NTD',
    sellerId: 1,
  },
  { id: 'KTV', name: 'KTV', fullName: 'Kỹ thuật viên phòng máy', sellerId: 1 },
  { id: 'TEKO', name: 'TEKO', fullName: 'Nhân sự Teko', sellerId: 1 },
  { id: 'VP00', name: 'VP00', fullName: 'Văn phòng công ty', sellerId: 1 },
  {
    id: 'VP00',
    name: 'VP00',
    fullName: 'Văn phòng công ty CP TM DV Phong Vũ',
    sellerId: 1,
  },
  {
    id: 'VPDD',
    name: 'VPDD',
    fullName: 'VPĐD 2 - Công ty CP TM DV Phong Vũ',
    sellerId: 1,
  },
  { id: 'K2', name: 'K2', fullName: 'VnTrading - HCM', sellerId: 2 },
  { id: 'K4', name: 'K4', fullName: 'VnTrading - Eton HN', sellerId: 2 },
  { id: 'K5', name: 'K5', fullName: 'VnTrading - Eton HCM', sellerId: 2 },
  { id: 'K6', name: 'K6', fullName: 'VP Eton HCM', sellerId: 2 },
  { id: 'K7', name: 'K7', fullName: 'VnTrading - Eton Q.7 HCM', sellerId: 2 },
  { id: 'K8', name: 'K8', fullName: 'VnTrading - Eton ĐN', sellerId: 2 },
  { id: 'K9', name: 'K9', fullName: 'VNPAYSHOP - ĐĐKD 1', sellerId: 2 },
  { id: 'KC', name: 'KC', fullName: 'VnTrading - HN', sellerId: 2 },
  { id: 'vnshop', name: 'vnshop', fullName: 'VN Shop Web', sellerId: 2 },
  { id: '1511', name: '1511', fullName: 'VM Ba Tháng Hai Online', sellerId: 4 },
  { id: '1513', name: '1513', fullName: 'VM Cộng Hòa Online', sellerId: 4 },
  { id: '1518', name: '1518', fullName: 'VM Mỹ Phước', sellerId: 4 },
  { id: '1527', name: '1527', fullName: 'VM Bàu Cát Online', sellerId: 4 },
  { id: '1528', name: '1528', fullName: 'VM Bình Trưng Online', sellerId: 4 },
  { id: '1530', name: '1530', fullName: 'VM Hà Đông Online', sellerId: 4 },
  { id: '1532', name: '1532', fullName: 'VM Royal City Online', sellerId: 4 },
  { id: '1533', name: '1533', fullName: 'VM Trung Hòa Online', sellerId: 4 },
  { id: '1535', name: '1535', fullName: 'VM Times City Online', sellerId: 4 },
  { id: '1541', name: '1541', fullName: 'VM Long Biên Online', sellerId: 4 },
  { id: '1544', name: '1544', fullName: 'VM Thủ Đức Online', sellerId: 4 },
  { id: '1545', name: '1545', fullName: 'VM Đồng Khởi', sellerId: 4 },
  {
    id: '1546',
    name: '1546',
    fullName: 'VM VCC Đà Nẵng Hub online',
    sellerId: 4,
  },
  { id: '1548', name: '1548', fullName: 'VM Biên Hòa', sellerId: 4 },
  { id: '1549', name: '1549', fullName: 'VM Quang Trung', sellerId: 4 },
  { id: '1551', name: '1551', fullName: 'VM Phan Văn Trị Online', sellerId: 4 },
  {
    id: '1553',
    name: '1553',
    fullName: 'VM Nguyễn Chí Thanh Online',
    sellerId: 4,
  },
  { id: '1560', name: '1560', fullName: 'VM Dĩ An 1', sellerId: 4 },
  { id: '1561', name: '1561', fullName: 'VM Thảo Điền Online', sellerId: 4 },
  { id: '1567', name: '1567', fullName: 'VM Lê Văn Việt Online', sellerId: 4 },
  { id: '1568', name: '1568', fullName: 'VM Nguyễn Duy Trinh', sellerId: 4 },
  { id: '1589', name: '1589', fullName: 'VM Phạm Ngọc Thạch', sellerId: 4 },
  { id: '1590', name: '1590', fullName: 'VM Bắc Từ Liêm Online', sellerId: 4 },
  { id: '1591', name: '1591', fullName: 'VM Long Thành', sellerId: 4 },
  { id: '1596', name: '1596', fullName: 'VM Sài Gòn Res Online', sellerId: 4 },
  { id: '1597', name: '1597', fullName: 'VM Nam Long Online', sellerId: 4 },
  { id: '1608', name: '1608', fullName: 'VM Liễu Giai', sellerId: 4 },
  { id: '1614', name: '1614', fullName: 'VM Long An', sellerId: 4 },
  { id: '1615', name: '1615', fullName: 'VM Tây Ninh', sellerId: 4 },
  { id: '1620', name: '1620', fullName: 'VM Gardenia', sellerId: 4 },
  { id: '1630', name: '1630', fullName: 'VM Landmark 81 Online', sellerId: 4 },
  { id: '1631', name: '1631', fullName: 'VM Phổ Quang Online', sellerId: 4 },
  { id: '1635', name: '1635', fullName: 'VM VCPLZ HNI Skylake', sellerId: 4 },
  { id: '1644', name: '1644', fullName: 'VM HNI Yên Sở Online', sellerId: 4 },
  { id: '1645', name: '1645', fullName: 'VM HNI Cầu Giấy tầng 1', sellerId: 4 },
  { id: '1655', name: '1655', fullName: 'VM HNI Nhật Tân Online', sellerId: 4 },
  { id: '1681', name: '1681', fullName: 'VM HCM Hưng Gia', sellerId: 4 },
  { id: '1683', name: '1683', fullName: 'VM Trung Sơn', sellerId: 4 },
  { id: '1699', name: '1699', fullName: 'VM Ocean Park', sellerId: 4 },
  {
    id: 'VIN-ETON-HCM',
    name: 'VIN-ETON-HCM',
    fullName: 'Vincommerce Hồ Chí Minh',
    sellerId: 4,
  },
  {
    id: 'VIN-ETON-HN',
    name: 'VIN-ETON-HN',
    fullName: 'Vincommerce HN',
    sellerId: 4,
  },
  {
    id: 'SKQALL',
    name: 'SKQALL',
    fullName: 'CÔNG TY TNHH SKINETQ',
    sellerId: 5,
  },
  {
    id: 'BLABALL',
    name: 'BLABALL',
    fullName: 'Công ty TNHH Beauty Lab Cosmetics Việt Nam',
    sellerId: 6,
  },
  {
    id: 'UMBALA',
    name: 'UMBALA',
    fullName: 'Chi nhánh UMBALA toàn quốc',
    sellerId: 8,
  },
  { id: 'BELIE-HCM', name: 'BELIE-HCM', fullName: 'CN Belie HCM', sellerId: 9 },
  {
    id: 'BELIE-HN',
    name: 'BELIE-HN',
    fullName: 'CN BELIE Hà Nội',
    sellerId: 9,
  },
  {
    id: 'FORHEADS-HCM',
    name: 'FORHEADS-HCM',
    fullName: 'CN Forheads HCM',
    sellerId: 10,
  },
  {
    id: 'FORHEADS-HN',
    name: 'FORHEADS-HN',
    fullName: 'CN Forheads Hà Nội',
    sellerId: 10,
  },
  { id: 'SQ-HCM', name: 'SQ-HCM', fullName: 'CN SQ Hồ Chí Minh', sellerId: 11 },
  { id: 'SQ-HN', name: 'SQ-HN', fullName: 'CN SQ Hà Nội', sellerId: 11 },
  {
    id: 'ELMICH-HCM',
    name: 'ELMICH-HCM',
    fullName: 'CN Elmich HCM',
    sellerId: 12,
  },
  {
    id: 'ELMICH-HN',
    name: 'ELMICH-HN',
    fullName: 'CN Elmich Hà Nội',
    sellerId: 12,
  },
  { id: 'GLK-HCM', name: 'GLK-HCM', fullName: 'CN GlinKint HCM', sellerId: 13 },
  {
    id: 'GLK-HN',
    name: 'GLK-HN',
    fullName: 'CN GlinKint Hà Nội',
    sellerId: 13,
  },
  {
    id: 'BRE-HCM',
    name: 'BRE-HCM',
    fullName: 'CN Beauty Republic HCM',
    sellerId: 14,
  },
  {
    id: 'BRE-HN',
    name: 'BRE-HN',
    fullName: 'CN Beauty Republic Hà Nội',
    sellerId: 14,
  },
  { id: 'TFS', name: 'TFS', fullName: 'TheFaceShop toàn quốc', sellerId: 15 },
  { id: 'BTB', name: 'BTB', fullName: 'Beauty Box Toàn Quốc', sellerId: 16 },
  {
    id: 'CMB-HCM',
    name: 'CMB-HCM',
    fullName: 'CN Charming Box HCM',
    sellerId: 17,
  },
  {
    id: 'CMB-HN',
    name: 'CMB-HN',
    fullName: 'CN Charming Box Hà Nội',
    sellerId: 17,
  },
  { id: 'KBC-HCM', name: 'KBC-HCM', fullName: 'CN Kombucha HCM', sellerId: 19 },
  {
    id: 'KBC-HN',
    name: 'KBC-HN',
    fullName: 'CN Kombucha Hà Nội',
    sellerId: 19,
  },
  {
    id: 'TTC-HCM',
    name: 'TTC-HCM',
    fullName: 'CN Thành Thành Công HCM',
    sellerId: 20,
  },
  {
    id: 'TTC-HN',
    name: 'TTC-HN',
    fullName: 'CN Thành Thành Công Hà Nội',
    sellerId: 20,
  },
  { id: 'ICB-HCM', name: 'ICB-HCM', fullName: 'CN Ichiban HCM', sellerId: 21 },
  { id: 'ICB-HN', name: 'ICB-HN', fullName: 'CN Ichiban Hà Nội', sellerId: 21 },
  {
    id: 'GHO-HCM',
    name: 'GHO-HCM',
    fullName: 'CN Green Home HCM',
    sellerId: 22,
  },
  {
    id: 'GHO-HN',
    name: 'GHO-HN',
    fullName: 'CN Green Home Hà Nội',
    sellerId: 22,
  },
  {
    id: 'GHO-KC',
    name: 'GHO-KC',
    fullName: 'TNHH Nguồn sống Thiên Nhiên',
    sellerId: 22,
  },
  { id: 'ATZ-HCM', name: 'ATZ-HCM', fullName: 'CN ATZ HCM', sellerId: 23 },
  { id: 'ATZ-HN', name: 'ATZ-HN', fullName: 'CN ATZ Hà Nội', sellerId: 23 },
  { id: 'MLG-HCM', name: 'MLG-HCM', fullName: 'CN MLG HCM', sellerId: 24 },
  { id: 'MLG-HN', name: 'MLG-HN', fullName: 'CN MLG Hà Nội', sellerId: 24 },
  {
    id: 'MLG-KC',
    name: 'MLG-KC',
    fullName: 'CÔNG TY TNHH ĐỘNG HỌC STELLA',
    sellerId: 24,
  },
  { id: 'AOS-HCM', name: 'AOS-HCM', fullName: 'CN AOS HCM', sellerId: 25 },
  { id: 'AOS-HN', name: 'AOS-HN', fullName: 'CN AOS Hà Nội', sellerId: 25 },
  { id: 'AOS-KC', name: 'AOS-KC', fullName: 'AOS - KC - HN', sellerId: 25 },
  { id: 'EST-HCM', name: 'EST-HCM', fullName: 'CN EST HCM', sellerId: 26 },
  { id: 'EST-KC', name: 'EST-KC', fullName: 'CN KC EST', sellerId: 26 },
  { id: 'MPP-HCM', name: 'MPP-HCM', fullName: 'CN MPP HCM', sellerId: 28 },
  { id: 'EUC-HCM', name: 'EUC-HCM', fullName: 'CN EUC HCM', sellerId: 29 },
  { id: 'VEN-HCM', name: 'VEN-HCM', fullName: 'CN Venus HCM', sellerId: 30 },
  {
    id: 'FHS',
    name: 'FHS',
    fullName: 'CN CTY CP PHÁT HÀNH SÁCH THÀNH PHỐ HỒ CHÍ MINH',
    sellerId: 31,
  },
  { id: 'PNJ', name: 'PNJ', fullName: 'CN PNJ HCM', sellerId: 32 },
  {
    id: 'TKS-HCM-Q1',
    name: 'TKS-HCM-Q1',
    fullName: 'Cửa hàng Takashimaya Quận 1',
    sellerId: 33,
  },
  {
    id: 'PMK-HCM-ETON',
    name: 'PMK-HCM-ETON',
    fullName: 'CN PMK HCM - ETON',
    sellerId: 34,
  },
  { id: 'MIREI', name: 'MIREI', fullName: 'Kho Mirei Online', sellerId: 35 },
  {
    id: 'MML.2001',
    name: 'MML.2001',
    fullName: 'Cửa hàng Văn La',
    sellerId: 36,
  },
  {
    id: 'STD',
    name: 'STD',
    fullName: 'CN CT CỔ PHẦN SAO THÁI DƯƠNG',
    sellerId: 38,
  },
  {
    id: 'LKV-HCM-ETON',
    name: 'LKV-HCM-ETON',
    fullName: 'LKV HCM',
    sellerId: 39,
  },
  { id: 'LKV-HN-ETON', name: 'LKV-HN-ETON', fullName: 'LKV HN', sellerId: 39 },
  { id: 'HF', name: 'HF', fullName: 'SOI-HOMEFRESH', sellerId: 40 },
  { id: 'SBA-HCM', name: 'SBA-HCM', fullName: 'Sabina HCM', sellerId: 41 },
  { id: 'CMM', name: 'CMM', fullName: 'Camimex Food toàn quốc', sellerId: 42 },
  { id: 'VUABIA', name: 'VUABIA', fullName: 'Vua Bia toàn quốc', sellerId: 43 },
  { id: 'JYSK', name: 'JYSK', fullName: 'Kho JYSK online', sellerId: 44 },
  {
    id: 'DXL',
    name: 'DXL',
    fullName: 'Kho Đệm Xinh Luxury online',
    sellerId: 45,
  },
  {
    id: 'ILM',
    name: 'ILM',
    fullName: 'Kho cửa hàng Index Living Mall',
    sellerId: 46,
  },
  { id: 'RHS', name: 'RHS', fullName: 'Kho cửa hàng RIO HOUSE', sellerId: 47 },
  {
    id: 'RPC',
    name: 'RPC',
    fullName: 'Kho cửa hàng R&P Commerce Việt Nam',
    sellerId: 50,
  },
  { id: 'ACR', name: 'ACR', fullName: 'Kho cửa hàng Ancarat', sellerId: 51 },
  { id: 'SLC', name: 'SLC', fullName: 'Kho cửa hàng SABLANCA', sellerId: 52 },
  { id: 'ECD', name: 'ECD', fullName: 'Kho cửa hàng ECODEALS', sellerId: 53 },
  { id: 'ZDS', name: 'ZDS', fullName: 'Kho cửa hàng ZELDA STAR', sellerId: 54 },
  {
    id: 'NCL',
    name: 'NCL',
    fullName: 'Kho cửa hàng Nhựa Chợ Lớn',
    sellerId: 55,
  },
  {
    id: 'AGT',
    name: 'AGT',
    fullName: 'Kho cửa hàng AGT Distribution',
    sellerId: 57,
  },
  { id: 'TMG', name: 'TMG', fullName: 'Kho cửa hàng TMG', sellerId: 58 },
  { id: 'TCM', name: 'TCM', fullName: 'Kho cửa hàng The Cosmo', sellerId: 59 },
  { id: 'THC', name: 'THC', fullName: 'Kho cửa hàng Thasco', sellerId: 60 },
  { id: 'GGM', name: 'GGM', fullName: 'Kho cửa hàng Gigomart', sellerId: 61 },
  { id: 'NNS', name: 'NNS', fullName: 'Kho cửa hàng Nón Sơn', sellerId: 62 },
  { id: 'MCF', name: 'MCF', fullName: 'Kho cửa hàng MC Fashion', sellerId: 65 },
  { id: 'LCK', name: 'LCK', fullName: 'Kho cửa hàng Lock&Lock', sellerId: 66 },
  { id: 'MAY', name: 'MAY', fullName: 'Kho cửa hàng May10', sellerId: 67 },
  { id: 'ONF', name: 'ONF', fullName: 'Kho cửa hàng ONOFF', sellerId: 68 },
  { id: 'GLA', name: 'GLA', fullName: 'Kho cửa hàng Glasslock', sellerId: 70 },
  {
    id: 'AHM',
    name: 'AHM',
    fullName: 'Kho cửa hàng Asian Helmet',
    sellerId: 78,
  },
  { id: 'LST', name: 'LST', fullName: 'Kho cửa hàng Lasante', sellerId: 84 },
  { id: 'MTL', name: 'MTL', fullName: 'Kho cửa hàng Mustela', sellerId: 85 },
  { id: 'DPH', name: 'DPH', fullName: 'Kho cửa hàng Doppelherz', sellerId: 86 },
  { id: 'PHX', name: 'PHX', fullName: 'Kho cửa hàng Phố Xinh', sellerId: 87 },
];
