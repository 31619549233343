import {
  APPROVED,
  CANCELLED,
  DONE,
  DRAFT,
  PENDING,
  REJECTED,
} from './constants';

export const accAccountTypeKeys = [
  'customer_debt',
  'vnpay_debt',
  'vnpay_qr_debt',
  'bank_card_debt',
  'installment_debt',
  'installment_card_debt',
  'tpps_rec',
  'logistic_cod_debt',
  'missing_asset',
  'excess_asset',
  'marketplace_rec',
];

export const accAccountTypeChoices = [
  { id: 'customer_debt', name: 'Phải thu khách hàng' },
  { id: 'vnpay_debt', name: 'Phải thu VNPay' },
  { id: 'vnpay_qr_debt', name: 'Phải thu VNPAYQR' },
  { id: 'bank_card_debt', name: 'Phải thu thẻ' },
  { id: 'installment_debt', name: 'Phải thu trả góp' },
  { id: 'installment_card_debt', name: 'Phải thu trả góp thẻ' },
  { id: 'tpps_rec', name: 'Phải thu TGTT' },
  { id: 'logistic_cod_debt', name: 'Phải thu giao hàng' },
  { id: 'missing_asset', name: 'Tài sản thiếu chờ xử lý' },
  { id: 'excess_asset', name: 'Tài sản thừa chờ xử lý' },
  { id: 'marketplace_rec', name: 'Phải thu sàn' },
];

export const accAccountTypeNameMap = accAccountTypeChoices.reduce(
  (map, choice) => {
    map[choice.name] = choice.id;
    return map;
  },
  {}
);

export const bankAccountTypeChoices = [
  { id: 'bank_transfer', name: 'Chuyển khoản' },
];

export const bankStatementReconStatusChoices = [
  { id: 0, name: 'ui.status.reconPending', _id: false },
  { id: 1, name: 'ui.status.reconDone', _id: true },
];

export const bankStatementStatusChoices = [
  { id: PENDING, name: 'ui.status.pending' },
  { id: APPROVED, name: 'ui.status.approved' },
  { id: REJECTED, name: 'ui.status.rejected' },
  { id: CANCELLED, name: 'ui.status.cancelled' },
  { id: DONE, name: 'ui.status.done' },
];

export const bankStatementSyncStatusChoices = [
  { id: PENDING, name: 'ui.status.syncPending' },
  { id: DONE, name: 'ui.status.syncDone' },
];

export const invoiceStatusChoices = [
  { id: '0', name: 'ui.status.pending', _id: PENDING },
  { id: '1', name: 'ui.status.approved', _id: APPROVED },
  { id: '2', name: 'ui.status.rejected', _id: REJECTED },
];

export const debtTypeChoices = [
  { id: 'goods', name: 'Nợ hàng' },
  { id: 'money', name: 'Nợ tiền' },
];

export const reqStatusChoices = [
  { id: DRAFT, name: 'Chờ chứng từ' },
  { id: PENDING, name: 'ui.status.pending' },
  { id: APPROVED, name: 'ui.status.approved' },
  { id: REJECTED, name: 'ui.status.rejected' },
  { id: DONE, name: 'ui.status.done' },
  { id: CANCELLED, name: 'ui.status.cancelled' },
];
