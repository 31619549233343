// adapted from: https://github.com/marmelab/react-admin/blob/master/examples/demo/src/layout/Menu.js
import React from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { MenuItemLink, setSidebarVisibility, translate } from 'react-admin';
import { withStyles } from '@material-ui/core/styles';

import PeopleIcon from '@material-ui/icons/People';
import PersonIcon from '@material-ui/icons/Person';

const styles = {
  root: {
    // Style applied to the MenuItem from material-ui
    fontSize: '0.9rem',
    padding: '0.5rem 0.75rem',
  },
  active: { fontWeight: 'bold', fontStyle: 'italic' }, // Style applied when the menu item is the active one
  icon: {
    // Style applied to the icon
    paddingRight: '0.75rem',
  },
};

const AdmMenu = ({
  classes,
  onMenuClick,
  // setSidebarVisibility,
  translate,
}) => {
  // const closeSidebar = () => setSidebarVisibility(false);
  return (
    <div>
      <MenuItemLink
        classes={classes}
        to={'/admin/users'}
        primaryText={translate('resources.admin/users/.name')}
        leftIcon={<PersonIcon />}
        onClick={onMenuClick}
        // uncomment the below if we want to close the sidebar automatically on clicking on a page
        // onClick={onMenuClick || closeSidebar}
      />
      <MenuItemLink
        classes={classes}
        to={'/admin/groups'}
        primaryText={translate('resources.admin/groups/.name')}
        leftIcon={<PeopleIcon />}
        onClick={onMenuClick}
      />
    </div>
  );
};

const enhance = compose(
  connect(
    null,
    { setSidebarVisibility }
  ),
  withStyles(styles),
  translate
);

export default enhance(AdmMenu);
