import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { NotFound } from 'react-admin';
import { useFmRolePermissions } from 'src/utils/helpers';

const ImporterComponentSwitch = React.lazy(() =>
  import(/* webpackChunkName: 'ImporterComponentSwitch' */ 'src/pages/receivables/transaction-importer')
);
const GeneralReport = React.lazy(() =>
  import(/* webpackChunkName: 'GeneralReport' */ 'src/pages/receivables/general-report')
);
const ObjectAccount = React.lazy(() =>
  import(/* webpackChunkName: 'ObjectAccount' */ 'src/pages/receivables/reports/object-account')
);
const PendingStatements = React.lazy(() =>
  import(/* webpackChunkName: 'PendingStatements' */ 'src/pages/receivables/banking/pending-statements')
);
const PeriodReporting = React.lazy(() =>
  import(/* webpackChunkName: 'PeriodReporting' */ 'src/pages/receivables/period-reporting')
);
const ReconUpload = React.lazy(() =>
  import(/* webpackChunkName: 'ReconUpload' */ 'src/pages/receivables/banking/statements/recon-upload')
);
const RecTransPerOrder = React.lazy(() =>
  import(/* webpackChunkName: 'RecTransPerOrder' */ 'src/pages/receivables/reports/trans-per-order')
);
const StatementReconcile = React.lazy(() =>
  import(/* webpackChunkName: 'StatementReconcile' */ 'src/pages/receivables/banking/statements/reconcile')
);
const StatementUpload = React.lazy(() =>
  import(/* webpackChunkName: 'StatementUpload' */ 'src/pages/receivables/banking/statements/upload')
);
const CloseTheBooks = React.lazy(() =>
  import(/* webpackChunkName: 'CloseTheBooks' */ 'src/pages/receivables/close-the-books')
);
const DynamicReports = React.lazy(() =>
  import(/* webpackChunkName: 'DynamicReports' */ 'src/pages/receivables/dynamic-reports')
);

const ImporterComponentSwitchWithPermissions = props => {
  const { canAccessTransImporter } = useFmRolePermissions();
  return canAccessTransImporter ? (
    <ImporterComponentSwitch {...props} />
  ) : (
    <NotFound />
  );
};

const GeneralReportWithPermissions = props => {
  const { canAccessReports } = useFmRolePermissions();
  return canAccessReports ? <GeneralReport {...props} /> : <NotFound />;
};

const PeriodReportingWithPermissions = props => {
  const { canAccessPeriodReports } = useFmRolePermissions();
  return canAccessPeriodReports ? <PeriodReporting {...props} /> : <NotFound />;
};

const CloseTheBooksWithPermissions = props => {
  const { canAccessCloseTheBooks } = useFmRolePermissions();
  return canAccessCloseTheBooks ? <CloseTheBooks {...props} /> : <NotFound />;
};

const DynamicReportsWithPermissions = props => {
  const { canAccessCloseTheBooks } = useFmRolePermissions();
  return canAccessCloseTheBooks ? <DynamicReports {...props} /> : <NotFound />;
};

const routes = [
  <Route
    key="rec_0"
    exact
    path="/receivables"
    render={() => <Redirect to="/receivables/reports/report-by-order" />}
  />,
  <Route
    key="rec_1"
    exact
    path="/receivables/reports/report-by-object/:sellerId/object-accounts/:objectAccId"
    render={({ match: { params } }) => (
      <Redirect
        to={`/receivables/reports/report-by-object/${
          params.sellerId
        }/object-accounts/${params.objectAccId}/receivables`}
      />
    )}
  />,
  <Route
    key="rec_2"
    exact
    path="/receivables/reports/report-by-object/:sellerId/object-accounts/:objectAccId/:listType"
    component={ObjectAccount}
  />,
  <Route
    key="rec_3"
    exact
    path="/receivables/reports/report-by-order/trans-per-order/:orderCode&:objectId&:debtType&:objectType"
    component={RecTransPerOrder}
  />,
  <Route
    key="rec_4"
    exact
    path="/receivables/period-reporting"
    component={PeriodReportingWithPermissions}
  />,
  <Route
    key="rec_5"
    path="/receivables/transaction-importer"
    component={ImporterComponentSwitchWithPermissions}
  />,
  <Route
    key="rec_6"
    exact
    path="/receivables/banking/statements/upload"
    component={StatementUpload}
  />,
  <Route
    key="rec_7"
    exact
    path="/receivables/banking/statements/reconcile/upload"
    component={ReconUpload}
  />,
  <Route
    key="rec_8"
    exact
    path="/receivables/banking/statements/:id/reconcile"
    component={StatementReconcile}
  />,
  <Route
    key="rec_9"
    exact
    path="/receivables/banking/pending-statements"
    component={PendingStatements}
  />,
  <Route
    key="rec_10"
    exact
    path="/receivables/general-report"
    component={GeneralReportWithPermissions}
  />,
  <Route
    key="rec_11"
    exact
    path="/receivables/close-the-books"
    component={CloseTheBooksWithPermissions}
  />,
  <Route
    key="rec_12"
    exact
    path="/receivables/dynamic-reports"
    component={DynamicReportsWithPermissions}
  />,
];

export default routes;
