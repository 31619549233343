const IAM_CLIENT_SCOPES = [
  'openid',
  'profile',
  'read:permissions',
  'fms',
  'lo',
  'dca',
  'as',
  'wms',
  'accreport',
];

export const iamConfig = {
  oauthDomain: window.IAM_URL,
  clientId: window.IAM_CLIENT_ID,
  scopes: IAM_CLIENT_SCOPES,
  redirectUri: undefined, // leaving it up to the SDK's default value
  silent: undefined, // leaving it up to the SDK's default value
};
