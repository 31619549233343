export const STORAGE_KEY = 'selected-seller';
export const LOCK_SELLER = 'LOCK_SELLER';
export const UNLOCK_SELLER = 'UNLOCK_SELLER';
export const UPDATE_SELLER = 'UPDATE_SELLER';
export const UPDATE_SELLERS = 'UPDATE_SELLERS';
export const UPDATE_SELLERS_FETCH_ERROR = 'UPDATE_SELLERS_FETCH_ERROR';

export const updateSeller = (dispatch, payload) => {
  dispatch({ type: UPDATE_SELLER, payload });
};
export const updateSellers = (dispatch, payload) => {
  dispatch({ type: UPDATE_SELLERS, payload });
};
export const updateSellersFetchError = (dispatch, payload) => {
  dispatch({ type: UPDATE_SELLERS_FETCH_ERROR, payload });
};
export const lockSeller = dispatch => {
  dispatch({ type: LOCK_SELLER });
};
export const unlockSeller = dispatch => {
  dispatch({ type: UNLOCK_SELLER });
};
