import React from 'react';

import { ACTIVE, BLOCKED } from 'src/utils/constants';
import BaseChip from './base-chip';

const colorDict = {
  [ACTIVE]: 'green',
  [BLOCKED]: 'red',
  waiting: 'blue',
};

// const UserStatusChip = ({ status }) => {
//   const [key, value] = status.includes('activ')
//     ? [ACTIVE, ACTIVE]
//     : status.includes('block')
//     ? [BLOCKED, BLOCKED]
//     : status.includes('wait')
//     ? ['waiting', 'waiting']
//     : [null, null];
//   if (!value) return null;
//   return <BaseChip color={colorDict[key]} label={value} />;
// };

const UserStatusChip = ({ status }) => {
  const key = (status && status.id) || status;
  const value = (status && status.name) || status;
  if (!value) return null;
  return <BaseChip color={colorDict[key]} label={value} />;
};

export default UserStatusChip;
