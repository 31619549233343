import { useContext } from 'react';

import { clearEnums } from 'src/utils/helpers';
import { IdentityContext } from 'src/identity-context';
import { LAST_BROWSED } from 'src/utils/constants';

const { TekoID } = window;

/**
 * Wrapper for user log-in function
 *
 */
export const logUserIn = () => TekoID.user.login();

/**
 * Wrapper for user log-out function
 *
 */
export const logUserOut = () => {
  clearEnums();
  // in case user changes their mind and doesn't want to log out,
  // the following line of code helps the app retrieve user's last location
  sessionStorage.setItem(LAST_BROWSED, window.location.pathname);
  TekoID.user.logout();
};

/**
 * Wrapper for getting asia_id of the currently logged-in user
 * @returns {String|null} user's asia_id or null
 *
 */
export const useUserAsiaId = () => {
  const { meta_data } = useContext(IdentityContext);
  if (meta_data && (meta_data.externalUserId || meta_data.asia_id)) {
    return meta_data.externalUserId || meta_data.asia_id;
  } else return null;
};

/**
 * Wrapper for getting user name of the currently logged-in user
 * @returns {String|null} user's name or null
 *
 */
export const getUserName = () => {
  const info = TekoID.user.getUserInfo();
  if (info && info.name) {
    return info.name;
  } else return null;
};

/**
 * Wrapper for getting email address of the currently logged-in user
 * @returns {String|null} user's email address or null
 *
 */
export const getUserEmail = () => {
  const info = TekoID.user.getUserInfo();
  if (info && info.email) {
    return info.email;
  } else return null;
};

/**
 * Wrapper for getting profile picture url of the currently logged-in user
 * @returns {String|null} user's profile picture url or null
 *
 */
export const getUserPicUrl = () => {
  const info = TekoID.user.getUserInfo();
  if (info && info.picture) {
    return info.picture;
  } else return null;
};
