import React from 'react';
import { Redirect, Route } from 'react-router-dom';

const BranchInvoiceApproveSwitch = React.lazy(() =>
  import(/* webpackChunkName: 'BranchInvoiceApproveSwitch' */ 'src/pages/branch-invoices/one/index approve switch')
);
const BranchInvoiceCreateSwitch = React.lazy(() =>
  import(/* webpackChunkName: 'BranchInvoiceCreateSwitch' */ 'src/pages/branch-invoices/one/index create switch')
);
const BranchInvoiceListSwitch = React.lazy(() =>
  import(/* webpackChunkName: 'BranchInvoiceListSwitch' */ 'src/pages/branch-invoices/list')
);

export default [
  <Route
    key="brainv_0"
    exact
    path="/branch-invoices"
    render={() => <Redirect to="/branch-invoices/list" />}
  />,
  <Route
    key="brainv_1"
    path="/branch-invoices/list"
    component={BranchInvoiceListSwitch}
  />,
  <Route
    key="brainv_2"
    exact
    path="/branch-invoices/create"
    component={BranchInvoiceCreateSwitch}
  />,
  <Route
    key="brainv_3"
    exact
    path="/branch-invoices/detail/:id"
    component={BranchInvoiceApproveSwitch}
  />,
];
