import React, { Fragment, memo } from 'react';
import PropTypes from 'prop-types';
import MuiToolbar from '@material-ui/core/Toolbar';
import { SaveButton as RaSaveButton } from 'react-admin';

import CustomButton from './custom-button';

const SimpleFormToolbar = ({
  classes,
  className,
  onCancel,
  saveCommand,
  saving,
  // props from Redux form
  invalid,
  pristine,
  ...props
}) => (
  <MuiToolbar className={className}>
    <CustomButton
      aria-label="Cancel"
      label="ra.action.cancel"
      onClick={onCancel}
      disabled={saving}
      withSideSpacing
    />
    <RaSaveButton
      disabled={pristine || invalid}
      icon={<Fragment />} // to bypass the propTypes checking of react-admin's SaveButton
      label={saveCommand} // overwrite Save label if needed
      saving={saving}
      {...props}
    />
  </MuiToolbar>
);

SimpleFormToolbar.propTypes = {
  onCancel: PropTypes.func.isRequired,
};

export default memo(SimpleFormToolbar);
