import React from 'react';
import { Resource } from 'react-admin';

const RecMoneyTrans = React.lazy(() =>
  import(/* webpackChunkName: 'RecMoneyTrans' */ 'src/pages/receivables/transactions/type-money')
);
const RecReportByObject = React.lazy(() =>
  import(/* webpackChunkName: 'RecReportByObject' */ 'src/pages/receivables/reports/report-by-object')
);
const RecReportByOrder = React.lazy(() =>
  import(/* webpackChunkName: 'RecReportByOrder' */ 'src/pages/receivables/reports/report-by-order')
);

export default [
  <Resource
    key="recReportByOrder"
    name="receivables/reports/report-by-order"
    list={RecReportByOrder}
  />,
  <Resource
    key="recReportByObject"
    name="receivables/reports/report-by-object"
    list={RecReportByObject}
  />,
  <Resource
    key="recMoneyTrans"
    name="receivables/transactions/type-money"
    list={RecMoneyTrans}
  />,
];
