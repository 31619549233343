import React from 'react';
import { VirtualTable } from '@devexpress/dx-react-grid-material-ui';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  row: {
    height: theme.tableRow.height,
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.grey[100],
    },
  },
});

const RowComponent = withStyles(styles)(({ classes, ...props }) => (
  <VirtualTable.Row {...props} className={classes.row} />
));

export default RowComponent;
