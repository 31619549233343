import React from 'react';

import BaseChip from './base-chip';

const colorDict = {
  done: 'darkGreen',
  error: 'red',
  pending: 'orange',
  wait: 'lightBlue',
};

const SyncStatusChip = ({ status }) => {
  const key = (status && status.id) || status;
  const value = (status && status.name) || status;
  if (!value) return null;
  return <BaseChip color={colorDict[key]} label={value} />;
};

export default SyncStatusChip;
