import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import { CustomButton } from 'src/components';

const NotificationModal = ({ classes, isOpen, onCancel, notiMsg }) => {
  return (
    <Dialog
      disableBackdropClick
      open={isOpen}
      aria-labelledby="notification-dialog-title"
    >
      <DialogTitle id="notification-dialog-title">Thông báo</DialogTitle>
      <DialogContent>
        <DialogContentText>{notiMsg}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <CustomButton
          aria-label="Close modal"
          label="ui.action.close"
          onClick={onCancel}
          color="primary"
        />
      </DialogActions>
    </Dialog>
  );
};

export default NotificationModal;
