//adapted from: https://github.com/marmelab/react-admin/blob/master/packages/ra-ui-materialui/src/auth/Logout.js

import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { compose } from 'recompose';
import ExitIcon from '@material-ui/icons/PowerSettingsNew';
import MenuItem from '@material-ui/core/MenuItem';
import { translate } from 'react-admin';
import { withStyles } from '@material-ui/core/styles';

import { logUserOut } from 'src/utils/helpers';
import { userMenuStyles as styles } from 'src/utils/styles';

const sanitizeRestProps = ({
  classes,
  className,
  translate,
  locale,
  redirectTo,
  ...rest
}) => rest;

/**
 * Logout button component, to be passed to the Admin component
 *
 * Used for the Logout Menu item in the sidebar
 */
const LogoutButton = ({ classes, className, translate, ...rest }) => (
  <MenuItem
    className={classnames('logout', classes.menuItem, className)}
    onClick={logUserOut}
    {...sanitizeRestProps(rest)}
  >
    <span className={classes.iconMenuPaddingStyle}>
      <ExitIcon />
    </span>
    {translate('ra.auth.logout')}
  </MenuItem>
);

LogoutButton.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  translate: PropTypes.func,
  redirectTo: PropTypes.string,
};

const enhance = compose(
  translate,
  withStyles(styles)
);

export default enhance(LogoutButton);
