import React from 'react';
import Chip from '@material-ui/core/Chip';
import classnames from 'classnames';
import { translate } from 'react-admin';
import { withStyles } from '@material-ui/core/styles';

import blue from '@material-ui/core/colors/blue';
import cyan from '@material-ui/core/colors/cyan';
import deepPurple from '@material-ui/core/colors/deepPurple';
import green from '@material-ui/core/colors/green';
import grey from '@material-ui/core/colors/grey';
import lime from '@material-ui/core/colors/lime';
import orange from '@material-ui/core/colors/orange';
import pink from '@material-ui/core/colors/pink';
import red from '@material-ui/core/colors/red';
import teal from '@material-ui/core/colors/teal';

const styles = theme => ({
  chip: {
    height: 22,
    fontSize: theme.tableCell.fontSize, // to match font-size in other table cells
  },
  lightBlue: {
    backgroundColor: blue[100],
    color: blue[800],
  },
  blue: {
    backgroundColor: blue[500],
    color: 'white',
  },
  lightCyan: {
    backgroundColor: cyan[100],
  },
  lightDeepPurple: {
    backgroundColor: deepPurple[100],
  },
  lightGreen: {
    backgroundColor: green.A100,
  },
  green: {
    backgroundColor: green[500],
    color: 'white',
  },
  darkGreen: {
    backgroundColor: green[800],
    color: 'white',
  },
  grey: {
    backgroundColor: grey[600],
    color: 'white',
  },
  lightLime: {
    backgroundColor: lime[300],
  },
  lightOrange: {
    backgroundColor: orange[100],
  },
  orange: {
    backgroundColor: orange[500],
    color: 'white',
  },
  lightPink: {
    backgroundColor: pink[100],
  },
  red: {
    backgroundColor: red[500],
    color: 'white',
  },
  lightTeal: {
    backgroundColor: teal[100],
  },
});

const BaseChip = ({
  classes,
  className,
  color,
  label,
  translate,
  translateOptions,
}) => (
  <Chip
    className={classnames(classes.chip, classes[color], className)}
    label={translate(label, { _: label, ...translateOptions })}
  />
);

export default translate(withStyles(styles)(BaseChip));
