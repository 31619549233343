import React from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-admin';
import Typography from '@material-ui/core/Typography';

const TemplateInstruction = translate(({ fileExt, templateUrl, translate }) => (
  <Typography>
    {translate('ui.action.download')}{' '}
    <a href={templateUrl} target="_blank" rel="noopener noreferrer">
      template
    </a>{' '}
    ({`${fileExt}`})
  </Typography>
));

TemplateInstruction.propTypes = {
  fileExt: PropTypes.string.isRequired,
  templateUrl: PropTypes.string,
  translate: PropTypes.func,
};

export default TemplateInstruction;
