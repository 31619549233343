import MuiTableCell from '@material-ui/core/TableCell';
import MuiTableRow from '@material-ui/core/TableRow';
import { withStyles } from '@material-ui/core/styles';

export const CustomTableCell = withStyles(theme => ({
  root: {
    fontSize: theme.tableCell.fontSize,
    verticalAlign: 'top',
    padding: '0 0 0 12px',
    borderBottom: 'none',
    '&:nth-of-type(odd)': {
      borderRight: `1px solid rgba(224, 224, 224, 1)`,
    },
  },
}))(MuiTableCell);

export const CustomTableHeaderCell = withStyles(theme => ({
  root: {
    fontSize: theme.tableCell.fontSize,
    fontWeight: 'bold',
    color: 'black',
    padding: '0 0 0 12px',
    borderBottom: 'none',
  },
}))(MuiTableCell);

export const CustomTableRow = withStyles(theme => ({
  root: {
    height: 30,
  },
  head: {
    height: theme.spacing.unit * 6,
  },
}))(MuiTableRow);

export default theme => ({
  card: {
    marginBottom: '1rem',
    minHeight: 'fit-content',
    overflow: 'visible',
  },
  cardContent: {
    paddingLeft: theme.spacing.unit / 2,
    paddingRight: theme.spacing.unit / 2,
    paddingTop: theme.spacing.unit / 2,
    '&#thinBottomPad': {
      paddingBottom: theme.spacing.unit / 2,
    },
  },
});
