import React from 'react';

const HTMLTableToXLSXButton = ({
  tableId,
  filename,
  buttonLabel = 'Download',
}) => (
  <button onClick={() => downloadXLSXFromTable(tableId, filename)}>
    {buttonLabel}
  </button>
);

export default HTMLTableToXLSXButton;

const downloadXLSXFromTable = (tableId, filename) => {
  if (!document) {
    if (process.env.NODE_ENV !== 'production') {
      console.error('Failed to access document object');
    }

    return null;
  }

  if (
    document.getElementById(tableId).nodeType !== 1 ||
    document.getElementById(tableId).nodeName !== 'TABLE'
  ) {
    if (process.env.NODE_ENV !== 'production') {
      console.error('Provided table property is not html table element');
    }

    return null;
  }

  const tableElem = document.getElementById(tableId);
  const { XLSX } = window;
  const wb = XLSX.utils.table_to_book(tableElem);
  XLSX.writeFile(wb, `${filename}.xlsx`);
};
