import React, { Fragment, PureComponent } from 'react';

import Confirm from './confirm';

class ButtonWithConfirm extends PureComponent {
  state = { confirmIsOpen: false };

  handleClick = event => {
    event.stopPropagation();
    this.setState({ confirmIsOpen: true });
  };

  handleDialogClose = () => {
    this.setState({ confirmIsOpen: false });
  };

  handleActionConfirm = () => {
    this.props.action();
    this.handleDialogClose();
  };

  render() {
    const { confirmIsOpen } = this.state;
    const { confirmMsg, render, translateOptions } = this.props;
    return (
      <Fragment>
        {render(this.handleClick)}
        {confirmIsOpen && (
          <Confirm
            isOpen={confirmIsOpen}
            title="ui.message.pleaseConfirm"
            content={confirmMsg}
            translateOptions={translateOptions}
            confirm="ra.message.yes"
            cancel="ra.message.no"
            onConfirm={this.handleActionConfirm}
            onClose={this.handleDialogClose}
          />
        )}
      </Fragment>
    );
  }
}

export default ButtonWithConfirm;
