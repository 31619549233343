import React from 'react';
import { withStyles } from '@material-ui/core/styles';

import { inputStyles as styles } from 'src/utils/styles';
import Unstyled from './base';

const DateTimeInput = withStyles(styles)(({ classes, ...props }) => (
  <Unstyled
    {...props}
    className={classes.root}
    InputProps={{
      // disableUnderline: true,
      classes: { root: classes.muiInput },
    }}
  />
));

export default DateTimeInput;
