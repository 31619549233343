// adapted from: https://github.com/marmelab/react-admin/blob/master/packages/ra-ui-materialui/src/layout/AppBar.js
import React, { Children, cloneElement } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import CloseMenuIcon from '@material-ui/icons/ClearAll';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import IconButton from '@material-ui/core/IconButton';
import {
  // LoadingIndicator,
  UserMenu,
  toggleSidebar as toggleSidebarAction,
} from 'react-admin';
import MenuIcon from '@material-ui/icons/Menu';
import MuiAppBar from '@material-ui/core/AppBar';
import MuiToolbar from '@material-ui/core/Toolbar';
import { withStyles } from '@material-ui/core/styles';
import withWidth from '@material-ui/core/withWidth';

const styles = theme => ({
  toolbar: {
    paddingRight: 24,
  },
  menuButton: {
    // margin: 'auto 0.25rem',
  },
});

const AppBar = ({
  children,
  classes,
  className,
  logout,
  open,
  noSideMenu,
  toggleSidebar,
  userMenu,
  width,
  ...rest
}) => (
  <MuiAppBar className={className} color="primary" {...rest}>
    <MuiToolbar
      className={classes.toolbar}
      disableGutters
      variant="dense"
      // variant={width === 'xs' ? 'regular' : 'dense'}
    >
      {!noSideMenu && (
        <IconButton
          aria-label="open drawer"
          className={classnames(classes.menuButton)}
          color="inherit"
          onClick={toggleSidebar}
        >
          {open ? <CloseMenuIcon /> : <MenuIcon />}
        </IconButton>
      )}

      {/* display the child components if there are any */}
      {Children.count(children) > 0 && children}

      {/* the refresh button */}
      {/* <LoadingIndicator /> */}

      {/* the user menu */}
      {cloneElement(userMenu, { logout })}
    </MuiToolbar>
  </MuiAppBar>
);

AppBar.propTypes = {
  children: PropTypes.node,
  classes: PropTypes.object,
  className: PropTypes.string,
  logout: PropTypes.element,
  noSideMenu: PropTypes.bool.isRequired,
  open: PropTypes.bool,
  toggleSidebar: PropTypes.func.isRequired,
  userMenu: PropTypes.node,
  width: PropTypes.string,
};

AppBar.defaultProps = {
  userMenu: UserMenu,
};

const enhance = compose(
  connect(
    state => ({ locale: state.i18n.locale }), // force redraw on locale change
    { toggleSidebar: toggleSidebarAction }
  ),
  withStyles(styles),
  withWidth()
);

export default enhance(AppBar);
