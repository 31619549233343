import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { fetchEnd, fetchStart, showNotification } from 'react-admin';
import { Prompt } from 'react-router-dom';

import { datetimeFilename, getErrorMsgToDisplay } from 'src/utils/helpers';
import ExportButtonView from '../view';
import { fetchListInfinitely, normalDownload } from '../download-helper';
import { LoadingDialog, NotificationModal } from 'src/components';
import {
  SAFE_TOTAL_FOR_WRITE_FILE,
  writeDataToExcelFile,
} from '../writedata-helper';

const RegularExportButton = ({
  disableExport,
  fetchEnd,
  fetchStart,
  filename,
  filterValues,
  isNewExport: isNewExportProp,
  permanentFilter,
  resource,
  showNotification,
  total,
}) => {
  const [notiMessage, setNotiMessage] = useState('');
  const [downloading, setDownloading] = useState(false);
  const [text, setText] = useState('');

  useEffect(() => {
    const confirmExitWhileDownloading = event => {
      if (downloading === true) {
        // Displays the prompt, as stated by the specs
        event.preventDefault();
        // The following two lines provide legacy support for displaying the prompt
        event.returnValue = '';
        return '';
      }
    };
    window.addEventListener('beforeunload', confirmExitWhileDownloading);
    return () => {
      window.removeEventListener('beforeunload', confirmExitWhileDownloading);
    };
  }, [downloading]);

  const abortController = useMemo(() => new AbortController(), []);
  useEffect(() => {
    return () => {
      abortController.abort();
    };
  }, [abortController]);

  const isNewExport = useMemo(
    () => isNewExportProp && total <= SAFE_TOTAL_FOR_WRITE_FILE,
    [isNewExportProp, total]
  );

  const handleExportClick = () => {
    isNewExport ? newExportNow() : exportNow();
  };

  const exportNow = async () => {
    fetchStart();
    const callbacks = {
      error: error => {
        if (error.message && error.message.includes('Báo cáo sẽ được gửi')) {
          setNotiMessage(error.message);
        } else showNotification(getErrorMsgToDisplay(error), 'warning');
      },
      final: () => {
        fetchEnd();
      },
    };
    const abortOption = { signal: abortController.signal };
    normalDownload(
      { ...filterValues, ...permanentFilter },
      resource,
      abortOption,
      `${filename}_${datetimeFilename()}`,
      callbacks
    );
  };

  const newExportNow = async () => {
    setDownloading(true);
    const updateProgress = percentComplete => {
      setText(
        `Đang lấy dữ liệu...${
          percentComplete > 0 ? ` ${Math.round(percentComplete * 100)}%` : ''
        }`
      );
    };

    const abortOption = { signal: abortController.signal };
    const { data, error } = await fetchListInfinitely(
      { ...filterValues, ...permanentFilter },
      resource,
      updateProgress,
      abortOption
    );

    if (data) {
      writeDataToExcelFile(data, resource, `${filename}_${datetimeFilename()}`);
    } else if (!abortController.signal.aborted) {
      showNotification(getErrorMsgToDisplay(error), 'warning');
    }
    setDownloading(false);
  };

  return (
    <Fragment>
      <ExportButtonView
        disabled={total === 0 || disableExport || downloading}
        label="ui.action.exportToExcel"
        onClick={handleExportClick}
      />
      {isNewExport ? (
        <Fragment>
          <LoadingDialog open={downloading} text={text} />
          <Prompt
            when={downloading}
            message="Quá trình tải file sẽ bị hủy nếu bạn chuyển trang. Bạn có chắc chắn muốn rời đi?"
          />
        </Fragment>
      ) : (
        <NotificationModal
          isOpen={!!notiMessage}
          onCancel={() => setNotiMessage('')}
          notiMsg={notiMessage}
        />
      )}
    </Fragment>
  );
};

export default connect(
  null,
  { fetchEnd, fetchStart, showNotification }
)(RegularExportButton);
