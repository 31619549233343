import React from 'react';
import Typography from '@material-ui/core/Typography';

const PageTitle = ({ children, ...props }) => (
  <Typography variant="headline" color="textSecondary" gutterBottom {...props}>
    {children}
  </Typography>
);

export default PageTitle;
