import React from 'react';
import { FormDataConsumer } from 'react-admin';

import BaseEnumInput from './base';
import {
  filterForGoodsVoucherTypes,
  filterForMoneyVoucherTypes,
} from 'src/utils/helpers';
import { SelectInput } from 'src/components';

const VoucherTypeInput = props => (
  <BaseEnumInput
    endpoint="voucher-type"
    render={(choices, errorInfo) => (
      <FormDataConsumer>
        {({ formData, ...rest }) => {
          // help filter voucherTypeChoices per debtType
          if (formData.debtType === 'goods' || /goods/i.test(props.resource))
            choices = filterForGoodsVoucherTypes(choices);
          if (formData.debtType === 'money' || /money/i.test(props.resource))
            choices = filterForMoneyVoucherTypes(choices);
          return (
            <SelectInput
              {...props}
              {...rest}
              choices={choices}
              {...errorInfo}
            />
          );
        }}
      </FormDataConsumer>
    )}
  />
);

export default VoucherTypeInput;
