import React from 'react';

class ChunkLoadErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  render() {
    if (this.state.hasError) {
      return <p>Load trang thất bại! Vui lòng Ctrl + F5 để thử lại.</p>;
    }
    return this.props.children;
  }
}

export default ChunkLoadErrorBoundary;
