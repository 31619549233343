import React, { Fragment } from 'react';
import { Admin } from 'react-admin';
import vietnameseDict from 'ra-language-vietnamese';

import appDict from 'src/i18n';
import appTheme from 'src/theme';
import CustomLayout from './layout/custom-layout';
// import Dashboard from 'src/pages/dashboard';
import dataProvider from 'src/data-provider';
import { EnvRibbon } from 'src/components';
import LogoutButton from './auth/logout-button';
import { SellerProvider } from 'src/contexts';
import { useCustomResources } from './custom-resources';
import { useCustomRoutes } from './custom-routes';
import { browserHistory } from './utils/helpers';

export const dict = { ...appDict, vi: { ...vietnameseDict, ...appDict.vi } };
const i18nProvider = locale => dict[locale];

const App = ({ lastBrowsedPath }) => {
  const customRoutes = useCustomRoutes();
  const customResources = useCustomResources();
  if (window.location.pathname !== lastBrowsedPath) {
    browserHistory.push(lastBrowsedPath);
  }
  return (
    <Fragment>
      <SellerProvider>
        <Admin
          appLayout={CustomLayout}
          customRoutes={customRoutes}
          // dashboard={Dashboard}
          dataProvider={dataProvider}
          history={browserHistory}
          i18nProvider={i18nProvider}
          locale="vi"
          loginPage={false}
          logoutButton={LogoutButton}
          theme={appTheme}
          title="Quản lý Tài chính v3"
        >
          {customResources}
        </Admin>
      </SellerProvider>
      <EnvRibbon />
    </Fragment>
  );
};

export default App;
