// Adapted from: https://github.com/marmelab/react-admin/blob/2.x/packages/ra-ui-materialui/src/input/SelectInput.js
import React, { Fragment, useEffect, useState } from 'react';
import { addField, translate } from 'react-admin';
import { change } from 'redux-form';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import IconButton from '@material-ui/core/IconButton';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { withStyles } from '@material-ui/core/styles';

import appDict from 'src/i18n';
import OperatorIcon from './icon';

export const AVAILABLE_OPERATORS = {
  contains: 'lk',
  // 'notContains': '',
  startsWith: 'sw',
  // 'endsWith': '',
  equal: 'eq',
  notEqual: 'ne',
  greaterThan: 'gt',
  greaterThanOrEqual: 'ge',
  lessThan: 'lt',
  lessThanOrEqual: 'le',
  isNull: 'eqnull',
  isNotNull: 'nenull',
};

const defaultOperators = Object.values(AVAILABLE_OPERATORS);

const styles = theme => ({
  iconButton: {
    marginLeft: -12,
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
    },
    // Because our input's TextField has an 8px margin-bottom, the button is still not aligned
    // horizonally centered wrt the TextField, despite Flexbox's align-items = center. Therefore,
    // we use CSS 'top' to bring the button down a little more.
    top: 8,
  },
  selectMenu: {
    position: 'absolute !important',
  },
  listItemIcon: {
    margin: 0,
  },
});

const OperatorSelectInput = ({
  classes,
  locales,
  operators = defaultOperators,
  source,
  // ReduxForm props
  change,
  input,
  meta,
}) => {
  if (typeof meta === 'undefined') {
    throw new Error(
      "The OperatorSelectInput component wasn't called within a redux-form <Field>. Did you decorate it and forget to add the addField prop to your component? See https://marmelab.com/react-admin/Inputs.html#writing-your-own-input-component for details."
    );
  }
  const [stateValue, setStateValue] = useState(input.value);
  useEffect(() => {
    setStateValue(input.value);
  }, [input.value]);

  const [anchorEl, setAnchorEl] = useState(null);

  const handleButtonClick = event => setAnchorEl(event.currentTarget);
  const handleMenuClose = () => setAnchorEl(null);
  const handleMenuItemClick = eventOrValue => {
    const value = eventOrValue.target
      ? eventOrValue.target.value
      : eventOrValue;
    input.onChange(value);
    setStateValue(value);
    if (value === AVAILABLE_OPERATORS.isNull || value === AVAILABLE_OPERATORS) {
      change(meta.form, source.replace('Operator', 'Operand'), '');
    }
    setAnchorEl(null);
  };

  return (
    <Fragment>
      <IconButton
        disableRipple
        className={classes.iconButton}
        onClick={handleButtonClick}
      >
        <OperatorIcon type={stateValue} />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        MenuListProps={{ dense: true }}
        className={classes.selectMenu}
      >
        {operators.map(operator => (
          <MenuItem
            key={operator}
            selected={operator === stateValue}
            onClick={() => handleMenuItemClick(operator)}
          >
            <ListItemIcon className={classes.listItemIcon}>
              <OperatorIcon type={operator} />
            </ListItemIcon>
            <ListItemText>{getDescription(operator, locales)}</ListItemText>
          </MenuItem>
        ))}
      </Menu>
    </Fragment>
  );
};

const getDescription = (key, locales = 'en') => {
  const descriptions = appDict[locales].operators;
  return descriptions[key];
};

export default compose(
  connect(
    null,
    { change }
  ),
  addField,
  translate,
  withStyles(styles)
)(OperatorSelectInput);
