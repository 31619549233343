import React from 'react';
import classnames from 'classnames';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  ribbon: {
    color: theme.palette.common.white,
    fontSize: 20,
    lineHeight: '20px', // must include unit, or else things will go wrong :(
    textAlign: 'center',
    width: 300,
    left: -100,
    bottom: 25,
    padding: 10,
    zIndex: 9999,
    position: 'fixed',
    transform: 'rotate(45deg)',
    boxSizing: 'border-box',
    boxShadow: '0 0 3px rgba(0,0,0,.3)',
    pointerEvents: 'none',
  },
  amber: {
    /* MUI amber.A700, goes well with deepPurple[500] & pink[600] appbar */
    background: 'rgba(255,171,0,.7)',
  },
  brown: {
    /* MUI brown[500], goes well with teal[700] appbar */
    background: 'rgba(121,85,72,.7)',
  },
  pink: {
    /* MUI pink[500], go well with indigo[500] & blueGrey[500] appbar */
    background: 'rgba(233,30,99,.7)',
  },
});

const EnvRibbon = withStyles(styles)(({ classes }) => {
  const className = classnames(classes.ribbon, {
    [classes.amber]: ['deepPurple', 'pink'].includes(window.PALETTE),
    [classes.brown]: ['teal'].includes(window.PALETTE),
    [classes.pink]: ['blueGrey'].includes(window.PALETTE),
  });
  const label = window.ENV_LABEL;
  return label ? <div className={className}>{label.toUpperCase()}</div> : null;
});

export default EnvRibbon;
