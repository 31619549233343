export const parseObjectTypesValueOrNull = rawValue => {
  let parsed;
  if (Array.isArray(rawValue)) {
    parsed = rawValue;
  } else if (typeof rawValue === 'string') {
    try {
      const parsedTemp = JSON.parse(rawValue);
      if (Array.isArray(parsedTemp)) {
        parsed = parsedTemp;
      }
    } catch (error) {
      // not a json
    }
  }
  if (Array.isArray(parsed)) return parsed;
  else return null;
};
