export const getQueryParams = param => {
  const searchParams = new URLSearchParams(window.location.search);
  return searchParams.get(param);
};

export const getURLSearchParamsFromObject = object => {
  const acceptedTypeList = ['boolean', 'number'];

  return (
    '?' +
    new URLSearchParams(
      Object.entries(object).filter(value => {
        return (
          acceptedTypeList.some(type => typeof value[1] === type) || !!value[1]
        );
      })
    ).toString()
  );
};
