import React from 'react';
import classnames from 'classnames';
import { FormDataConsumer } from 'react-admin';
import { withStyles } from '@material-ui/core/styles';

import { inputStyles } from 'src/utils/styles';
import OperatorSelectInput, {
  AVAILABLE_OPERATORS as OPS,
} from './operator-select-input';
import TextInput from './text-input';

const styles = theme => ({
  ...inputStyles(theme),
  flexContainer: {
    ...inputStyles(theme).root,
    maxHeight: 'unset',
    backgroundColor: 'unset',
    marginRight: 'unset',
    display: 'flex',
    alignItems: 'center',
  },
  operandField: {
    ...inputStyles(theme).root,
    marginRight: 'unset',
    marginLeft: 'unset',
  },
});

const normalTextOperators = [OPS.contains, OPS.equal];
const nullableTextOperators = [OPS.equal, OPS.isNotNull, OPS.isNull];
export const noTextOperators = [OPS.isNull, OPS.isNotNull];

const StrCompareInput = withStyles(styles)(
  ({ classes, className, compSrc, hasNull, label, strSrc, ...props }) => {
    return (
      <div className={classes.flexContainer}>
        <OperatorSelectInput
          source={compSrc}
          locales="vi"
          operators={hasNull ? nullableTextOperators : normalTextOperators}
        />
        <FormDataConsumer>
          {({ formData, ...rest }) => (
            // operator value must exist before user can enter a value for operand
            <TextInput
              disabled={
                !formData[compSrc] ||
                noTextOperators.includes(formData[compSrc])
              }
              source={strSrc}
              label={label}
              className={classnames(classes.operandField, className)}
              {...rest}
              {...props}
            />
          )}
        </FormDataConsumer>
      </div>
    );
  }
);

export default StrCompareInput;
