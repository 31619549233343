import React from 'react';
import { translate } from 'react-admin';

import BaseChip from './base-chip';

const colorDict = {
  undue: 'blue',
  paid: 'darkGreen',
  overdue: 'red',
  overdue7: 'red',
  overdue14: 'red',
  overdue21: 'red',
};

const DebtStatusChip = translate(({ record, translate }) => {
  const { debtStatus, daysPastDue } = record || {};
  const key = (debtStatus && debtStatus.id) || debtStatus;
  const value = (debtStatus && debtStatus.name) || debtStatus;
  if (!value) return null;
  if (/overdue/i.test(key))
    return (
      <BaseChip
        color={colorDict[key]}
        label={translate('ui.message.pastDue', { smart_count: daysPastDue })}
      />
    );
  return <BaseChip color={colorDict[key]} label={value} />;
});

export default DebtStatusChip;
