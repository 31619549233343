import React from 'react';

import BaseEnumInput from './base';
import { SelectInput } from 'src/components';

const SellerInput = ({
  component,
  defaultToFirstChoice,
  defaultValue,
  ...props
}) => (
  <BaseEnumInput
    endpoint="seller"
    fetchNewAlways
    render={(choices, errorInfo) =>
      React.createElement(component || SelectInput, {
        ...props,
        choices,
        defaultValue:
          defaultValue || (defaultToFirstChoice && choices[0] && choices[0].id),
        ...errorInfo,
      })
    }
  />
);

export default SellerInput;
