export default {
  lk: 'Contains',
  // notContains: 'Does not contain',
  sw: 'Starts with',
  // endsWith: 'Ends with',
  eq: 'Equals',
  ne: 'Does not equal',
  gt: 'Greater than',
  ge: 'Greater than or equal to',
  lt: 'Less than',
  le: 'Less than or equal to',
};
