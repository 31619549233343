import React from 'react';
import { FormDataConsumer } from 'react-admin';

import { AutocompleteInput } from 'src/components';
import BaseEnumInput from '../base';
import defaultChoices from './default-choices';
import { useSellerState } from 'src/contexts/seller';

export const sortByLabel = originalChoices => {
  const sortedChoices = [...originalChoices];
  return sortedChoices.sort((a, b) => {
    const labelA = a.name.toUpperCase();
    const labelB = b.name.toUpperCase();
    if (labelA < labelB) return -1;
    if (labelA > labelB) return 1;
    return 0;
  });
};

const BranchInput = ({ component, ...props }) => {
  const { seller = {} } = useSellerState();
  return (
    <BaseEnumInput
      endpoint="branch"
      render={(fetchedChoices, errorInfo) => (
        <FormDataConsumer>
          {({ formData, ...rest }) => {
            const sellerId = formData.sellerId || seller.id;
            let choices = fetchedChoices.length
              ? fetchedChoices
              : defaultChoices;
            if (sellerId) {
              choices = choices.filter(
                c => c.sellerId.toString() === sellerId.toString()
              );
            }
            return React.createElement(component || AutocompleteInput, {
              ...props,
              ...rest,
              choices: sortByLabel(choices),
              ...errorInfo,
            });
          }}
        </FormDataConsumer>
      )}
    />
  );
};

export default BranchInput;
