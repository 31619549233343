import { useFmRolePermissions } from 'src/utils/helpers';

export default translate => {
  const { canAccessAdmin, canAccessReceivables } = useFmRolePermissions();
  return [
    // {
    //   to: '/dashboard',
    //   label: translate('ui.heading.dashboard'),
    // },
    canAccessReceivables
      ? {
          to: '/receivables',
          label: translate('ui.heading.receivables'),
        }
      : null,
    // {
    //   to: '/payables',
    //   label: translate('ui.heading.payables'),
    // },
    // {
    canAccessAdmin
      ? {
          to: '/admin',
          label: translate('ui.heading.admin'),
          ignoreSellers: true,
        }
      : null,
  ];
};
