import React from 'react';

import { APPROVED, PENDING, REJECTED } from 'src/utils/constants';
import BaseChip from './base-chip';
import { invoiceStatusChoices } from 'src/utils/choices';

const colorDict = {
  [PENDING]: 'lightBlue',
  [APPROVED]: 'green',
  [REJECTED]: 'red',
  adjusted: 'lightPink',
  revoked: 'lightOrange',
};

const InvoiceStatusChip = ({ status, isAdjusted, isRevoked }) => {
  let choice;

  if (status !== undefined) {
    choice = invoiceStatusChoices.find(
      c => c.id.toString() === status.toString()
    );
  }

  if (choice === undefined) return <BaseChip label={status || 'Đơn tạo mới'} />;

  let key = choice._id;
  let value = choice.name;

  if (isAdjusted) {
    key = 'adjusted';
    value = 'Đã điều chỉnh';
  } else if (isRevoked) {
    key = 'revoked';
    value = 'Đã thu hồi';
  }

  return <BaseChip color={colorDict[key]} label={value} />;
};

export default InvoiceStatusChip;
