import cyan from '@material-ui/core/colors/cyan';
import indigo from '@material-ui/core/colors/indigo';

export default {
  primary: {
    main: indigo[500],
    dark: indigo[700],
  },
  secondary: {
    // used for TabIndicator
    main: cyan[100],
  },
};
