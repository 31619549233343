import React from 'react';
import DialogActions from '@material-ui/core/DialogActions';
import MuiToolbar from '@material-ui/core/Toolbar';

import { CustomButton } from 'src/components';

const ResultModalToolbar = ({
  onCancel,
  onConfirm,
  onRetry,
  confirmConds,
  retryConds,
}) => (
  <DialogActions>
    <MuiToolbar>
      <CustomButton
        aria-label="Cancel"
        label="ra.action.cancel"
        onClick={onCancel}
        withSideSpacing
      />
      {retryConds ? (
        <CustomButton
          aria-label="Retry"
          color="primary"
          label="ui.action.reupload"
          onClick={onRetry}
          variant="raised"
          withSideSpacing
        />
      ) : confirmConds ? (
        <CustomButton
          aria-label="Confirm"
          color="primary"
          label="ra.action.confirm"
          onClick={onConfirm}
          variant="raised"
          withSideSpacing
        />
      ) : null}
    </MuiToolbar>
  </DialogActions>
);

export default ResultModalToolbar;
