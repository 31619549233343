export default {
  accAccount: 'Tài khoản',
  accountNo: 'Số tài khoản',
  accountStatus: 'Tình trạng tài khoản',
  accountType: 'Loại tài khoản',
  address: 'Địa chỉ',
  amount: 'Số tiền',
  approveDate: 'Ngày duyệt',
  approveUser: 'Người duyệt',
  bank: 'Ngân hàng',
  bankRef: 'Mã sao kê',
  branch: 'Chi nhánh',
  buyerName: 'Tên người mua',
  buyerPhone: 'SĐT người mua',
  cashierCode: 'Mã thu ngân',
  collDate: 'Ngày dữ liệu',
  collDateFrom: 'Ngày dữ liệu từ',
  collDateTo: 'Ngày dữ liệu đến',
  companyName: 'Tên công ty',
  confirmDate: 'Ngày xác nhận',
  contraAccount: 'Tài khoản đối ứng',
  createDate: 'Ngày tạo',
  credit: 'Ghi có',
  currentDebtLimit: 'Hạn mức nợ hiện tại',
  currentDebtTerm: 'Thời hạn nợ hiện tại',
  currentStatus: 'Trạng thái hiện tại',
  customerId: 'Mã khách hàng',
  customerName: 'Tên khách hàng',
  customerType: 'Loại khách hàng',
  debit: 'Ghi nợ',
  debtLimit: 'Hạn mức nợ',
  debtStatus: 'Tình trạng nợ',
  debtTerm: 'Thời hạn nợ (ngày)',
  debtType: 'Loại nợ',
  description: 'Diễn giải',
  detail: 'Chi tiết',
  dueDate: 'Ngày đến hạn',
  enterprise: 'Doanh nghiệp',
  error: 'Lỗi',
  exportCode: 'Mã phiếu xuất',
  exportStore: 'Chi nhánh xuất',
  exportDate: 'Ngày phiếu xuất',
  fileName: 'Tên file',
  goodsDebt: 'Nợ hàng',
  phone: 'Số điện thoại',
  importCode: 'Mã phiếu nhập',
  individual: 'Cá nhân',
  invDate: 'Ngày hóa đơn',
  invDateFrom: 'Ngày hóa đơn từ',
  invDateTo: 'Ngày hóa đơn đến',
  invNo: 'Số hóa đơn',
  invNote: 'Ghi chú hóa đơn',
  invSeri: 'Seri hóa đơn',
  invTemplate: 'Mẫu số',
  moneyDebt: 'Nợ tiền',
  nbRecords: 'Số bản ghi',
  nbRows: 'Số dòng',
  newDebtLimit: 'Hạn mức nợ mới',
  newDebtTerm: 'Thời hạn nợ mới',
  newStatus: 'Trạng thái mới',
  note: 'Ghi chú',
  objectAccId: 'Mã kế toán',
  objectGroup: 'Nhóm đối tượng',
  objectId: 'Mã đối tượng',
  objectName: 'Tên đối tượng',
  objectType: 'Loại đối tượng',
  orderCode: 'Mã đơn hàng',
  orderId: 'ID đơn hàng',
  paymentCode: 'Mã loại thanh toán',
  paymentDate: 'Ngày thanh toán',
  paymentMethod: 'Hình thức thanh toán',
  paymentNo: 'Mã thanh toán',
  paymentTerm: 'Hạn thanh toán (ngày)',
  reconType: 'Loại phân bổ',
  rejectDate: 'Ngày từ chối',
  rejectUser: 'Người từ chối',
  remain: 'Còn nợ',
  remainingDebts: 'Các khoản còn nợ',
  report: 'Báo cáo',
  reportType: 'Loại báo cáo',
  reqDate: 'Ngày tạo yêu cầu',
  reqDateFrom: 'Ngày tạo yêu cầu từ',
  reqDateTo: 'Ngày tạo yêu cầu đến',
  reqStatus: 'Tình trạng xử lý',
  reqUser: 'Người yêu cầu',
  row: 'Dòng',
  salesman: 'NV bán hàng',
  sourceSystem: 'Hệ thống nguồn',
  status: 'Trạng thái',
  subtotal: 'Tiền chưa thuế',
  syncStatus: 'Tình trạng đồng bộ',
  syncToSap: 'Đồng bộ SAP',
  taxAddress: 'Địa chỉ thuế',
  taxCode: 'Mã số thuế',
  totalAmount: 'Tổng tiền thanh toán',
  totalDebt: 'Tổng nợ',
  transDate: 'Ngày ghi nhận',
  transDateFrom: 'Ngày ghi nhận từ',
  transDateTo: 'Ngày ghi nhận đến',
  transImporter: 'Tool import hạch toán',
  updateDate: 'Ngày cập nhật',
  updateUser: 'Người cập nhật',
  uploadDate: 'Ngày tải lên',
  uploadUser: 'Người tải lên',
  user: 'Người dùng',
  userName: 'Tên người dùng',
  validateDate: 'Ngày kiểm tra',
  vat: 'Tiền thuế GTGT',
  voucherCode: 'Mã chứng từ',
  voucherDate: 'Ngày chứng từ',
  voucherId: 'ID chứng từ',
  voucherType: 'Loại chứng từ',
};
