export default {
  lk: 'Chứa',
  // notContains: 'Không chứa',
  sw: 'Bắt đầu bằng',
  // endsWith: 'Kết thúc bằng',
  eq: 'Bằng',
  ne: 'Không bằng',
  gt: 'Lớn hơn',
  ge: 'Lớn hơn hoặc bằng',
  lt: 'Nhỏ hơn',
  le: 'Nhỏ hơn hoặc bằng',
  eqnull: 'Rỗng',
  nenull: 'Có giá trị',
};
