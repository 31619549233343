import React from 'react';

import BaseEnumInput from './base';
import { SelectInput } from 'src/components';

export const sortByCode = originalChoices => {
  const sortedChoices = [...originalChoices];
  return sortedChoices.sort((a, b) => {
    const codeA = a.id.toUpperCase();
    const codeB = b.id.toUpperCase();
    if (codeA < codeB) return -1;
    if (codeA > codeB) return 1;
    return 0;
  });
};

const BankInput = ({ component, ...props }) => (
  <BaseEnumInput
    endpoint="bank"
    render={(choices, errorInfo) =>
      React.createElement(component || SelectInput, {
        ...props,
        choices: sortByCode(choices),
        ...errorInfo,
      })
    }
  />
);

export default BankInput;
