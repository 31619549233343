import React from 'react';
import Typography from '@material-ui/core/Typography';

const SectionHeading = ({ children, className }) => (
  <Typography
    variant="subheading"
    style={{ fontWeight: 'bold' }}
    className={className}
  >
    {children}
  </Typography>
);

export default SectionHeading;
