// adapted from react-admin's DateTimeInput in order to accept second input (in
// adition to hour and minute)
import React from 'react';
import PropTypes from 'prop-types';
import MuiTextField from '@material-ui/core/TextField';
import { FieldTitle, addField } from 'react-admin';

import { format } from './helpers';

const sanitizeRestProps = ({
  basePath,
  defaultValue,
  formClassName,
  ...rest
}) => rest;

/**
 * Input component for entering a date and a time with timezone, using the browser locale
 */
export const DateTimeInput = ({
  className,
  meta: { touched, error },
  input,
  isRequired,
  label,
  options,
  source,
  resource,
  ...rest
}) => (
  <MuiTextField
    {...input}
    className={className}
    type="datetime-local"
    margin="normal"
    error={!!(touched && error)}
    helperText={touched && error}
    label={
      <FieldTitle
        label={label}
        source={source}
        resource={resource}
        isRequired={isRequired}
      />
    }
    InputLabelProps={{
      shrink: true,
    }}
    {...options}
    {...sanitizeRestProps(rest)}
    value={input.value}
  />
);

DateTimeInput.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  input: PropTypes.object,
  isRequired: PropTypes.bool,
  label: PropTypes.string,
  meta: PropTypes.object,
  options: PropTypes.object,
  resource: PropTypes.string,
  source: PropTypes.string,
};

DateTimeInput.defaultProps = {
  options: {},
};

export default addField(DateTimeInput, { format });
