import pink from '@material-ui/core/colors/pink';

export default {
  primary: {
    main: pink[600],
    dark: pink[800],
  },
  secondary: {
    // used for TabIndicator
    main: pink[100],
  },
};
