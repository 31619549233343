import { createMuiTheme } from '@material-ui/core/styles';

import palettes from './palettes';

const commonTheme = {
  appBar: {
    height: 48,
  },
  tableCell: {
    fontSize: '0.75rem', // or 0.8125rem if want larger text
  },
  tableRow: {
    height: 44,
  },
};

export default createMuiTheme({
  palette: palettes[window.PALETTE],
  ...commonTheme,
});

// see more here: https://material-ui.com/customization/themes/#properties (apply a style on all instances of a component type)
