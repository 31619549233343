import React, { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import MuiToolbar from '@material-ui/core/Toolbar';
import SvgIcon from '@material-ui/core/SvgIcon';
import Typography from '@material-ui/core/Typography';

import { getErrorMsgToDisplay } from 'src/utils/helpers';

const SuccessModalView = ({
  additionalInstruction,
  open,
  renderButtons,
  title,
}) => {
  const [error, setError] = useState(null);

  const onError = error => setError(error);

  return (
    <Dialog
      disableBackdropClick
      maxWidth="md"
      open={open}
      aria-labelledby="success-dialog-title"
    >
      <DialogTitle id="success-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <SvgIcon
          nativeColor="green"
          style={{ fontSize: 72, display: 'block', margin: '1rem auto' }}
        >
          <path d="M0 0h24v24H0z" fill="none" />
          <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z" />
        </SvgIcon>
        {additionalInstruction && (
          <Typography
            align="center"
            style={{ width: 360, margin: '0 1rem', color: 'green' }}
          >
            {additionalInstruction}
          </Typography>
        )}
        {error && (
          <Typography
            align="center"
            style={{ width: 360, margin: '0 1rem', color: 'red' }}
          >
            {getErrorMsgToDisplay(error)}
          </Typography>
        )}
      </DialogContent>
      <DialogActions>
        <MuiToolbar>{renderButtons(onError)}</MuiToolbar>
      </DialogActions>
    </Dialog>
  );
};

export default SuccessModalView;
