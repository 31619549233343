import React from 'react';
import classnames from 'classnames';
import { TextInput as Unstyled } from 'react-admin';
import { withStyles } from '@material-ui/core/styles';

import { inputStyles as styles } from 'src/utils/styles';

const TextInput = withStyles(styles)(({ classes, className, ...props }) => (
  <Unstyled
    {...props}
    className={classnames(classes.root, className)}
    InputLabelProps={{
      shrink: true,
      classes: {
        shrink: classes.muiLabelShrink,
      },
      FormLabelClasses: {
        root: classes.muiLabel,
      },
    }}
    InputProps={{
      // disableUnderline: true,
      classes: { root: classes.muiInput },
    }}
  />
));

export default TextInput;
