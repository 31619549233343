import React from 'react';
import { FormDataConsumer } from 'react-admin';

import { AutocompleteInput } from 'src/components';
import ProvinceInputController from './controller';

const ProvinceInput = props => (
  <ProvinceInputController
    render={(choices, errorInfo) => (
      <FormDataConsumer>
        {({ formData, ...rest }) => {
          // help filter provinceChoices per region
          if (formData.region)
            choices = choices.filter(c => c.region === formData.region);
          return (
            <AutocompleteInput
              {...props}
              {...rest}
              choices={choices}
              {...errorInfo}
            />
          );
        }}
      </FormDataConsumer>
    )}
  />
);

export default ProvinceInput;
