export const APP_TITLE = 'Quản lý Tài chính v3';

export const API_URL = window.API_BASE_URL;
export const AREA_URL = window.LO_API_URL + '/admin/area';
export const CUSTOMER_READ_URL = window.SEARCH_API_URL;
export const DCA_ACCOUNTS_URL = window.DCA_API_URL + '/accounts';
export const SERVICE_INV_URL = window.ACCOUNTING_API_URL + '/service_invoice';
export const ACCOUNTING_WEB_URL = window.ACCOUNTING_WEB_URL;
// Warning! Be careful with the trailing slash. Current IAM settings of WMS service require it for the endpoint to work
export const BRANCH_URL = window.WMS_API_URL + '/branches/';

export const ACTIVE = 'active';
export const APPROVED = 'approved';
export const BLOCKED = 'blocked';
export const CANCELLED = 'cancelled';
export const DONE = 'done';
export const DRAFT = 'draft';
export const PENDING = 'pending';
export const REJECTED = 'rejected';

export const ADD_MANY = 'ADD_MANY';

export const LAST_BROWSED = 'last_browsed';

export const ACCOUNT_TYPE_V2 = 'account-type-v2';

const CURRENCY_DECIMAL_SCALE = 0;
export const CURRENCY_OPTIONS = {
  options: { minimumFractionDigits: CURRENCY_DECIMAL_SCALE },
};
export const cf = new Intl.NumberFormat('en-US', CURRENCY_OPTIONS.options);

export const DATETIME_LOCALE = 'en-GB';
export const df = new Intl.DateTimeFormat(DATETIME_LOCALE);
export const dtf = new Intl.DateTimeFormat(DATETIME_LOCALE, {
  year: 'numeric',
  month: '2-digit',
  day: '2-digit',
  hour: '2-digit',
  minute: '2-digit',
  second: '2-digit',
});

export const optionRenderer = choice => `${choice.id} - ${choice.name}`;

export const PAGE_SIZE_OPTIONS = [20, 40, 100];
