import React from 'react';
import { NavLink } from 'react-router-dom';
import { Responsive, translate } from 'react-admin';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

import AccountingNavMenu, {
  menuItemsFull as accountingMenuItems,
} from './accounting-nav-menu';
import AppBar from './base-appbar';
import CustomUserMenu from './custom-user-menu';
import SellerSelect from './seller-select-on-appbar';
import useAppbarTabs from './use-appbar-tabs';

const styles = theme => ({
  flexContainer: {
    height: theme.appBar.height, // '3rem',
    // [theme.breakpoints.down('sm')]: {
    //   height: '3.5rem',
    // },
  },
  spacer: {
    flex: 1,
  },
  tabs: {
    paddingRight: '0.5rem',
    [theme.breakpoints.up('xs')]: {
      width: 'max-content',
    },
    [theme.breakpoints.down('sm')]: {
      width: '380px',
    },
  },
  title: {
    fontFamily: 'Ubuntu, sans-serif',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
});

const AppBarWithTabs = ({
  classes,
  location: { pathname },
  translate,
  ...props
}) => {
  const tabs = useAppbarTabs(translate).filter(Boolean);
  let currentTab = tabs.findIndex(tab => pathname.includes(tab.to));
  if (
    currentTab < 0 &&
    accountingMenuItems.find(m => pathname.includes(m.url)) !== undefined
  ) {
    currentTab = tabs.length;
  }
  const ignoreSellers = !tabs[currentTab] || tabs[currentTab].ignoreSellers;

  return (
    <AppBar {...props} userMenu={<CustomUserMenu />}>
      {/* Navigation tabs */}
      <Tabs
        classes={{ root: classes.tabs, flexContainer: classes.flexContainer }}
        scrollable
        scrollButtons="off"
        value={currentTab}
      >
        {tabs.map(tab => (
          <Tab
            key={tab.to.slice(1)} // exclude the leading forward slash
            component={NavLink}
            to={tab.to}
            label={tab.label}
          />
        ))}
        <AccountingNavMenu />
      </Tabs>

      <span className={classes.spacer} />

      {/* App name */}
      <Responsive
        small={null}
        medium={
          <Typography
            className={classes.title}
            color="inherit"
            variant="headline"
          >
            FMS v3
          </Typography>
        }
        large={
          <Typography
            className={classes.title}
            color="inherit"
            variant="headline"
          >
            Financial Management System v3.0
          </Typography>
        }
      />

      {/* Seller switch box */}
      {!ignoreSellers && <Responsive small={null} medium={<SellerSelect />} />}

      <span className={classes.spacer} />
    </AppBar>
  );
};

export default translate(withStyles(styles)(AppBarWithTabs));
