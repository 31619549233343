import React from 'react';

import BaseEnumInput from './base';
import { SelectInput } from 'src/components';

const RegionInput = props => (
  <BaseEnumInput
    endpoint="region"
    render={(choices, errorInfo) => (
      <SelectInput {...props} choices={choices} {...errorInfo} />
    )}
  />
);

export default RegionInput;
