import React from 'react';

import BaseEnumInput from './base';
import { SelectInput } from 'src/components';

const ObjectTypeInput = ({ component, ...props }) => (
  <BaseEnumInput
    endpoint="object-type"
    render={(choices, errorInfo) =>
      React.createElement(component || SelectInput, {
        ...props,
        choices,
        ...errorInfo,
      })
    }
  />
);

export default ObjectTypeInput;
