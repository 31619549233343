// adapted from: https://github.com/marmelab/react-admin/blob/master/packages/ra-ui-materialui/src/list/List.js
import React from 'react';
import { connect } from 'react-redux';
import {
  ListController,
  changeListParams as changeListParamsAction,
  refreshView as refreshViewAction,
} from 'react-admin';

import ListView from './list-view';
import paramsDiffer, { defaultParams } from './param-helper';

const List = props => {
  const setListToDefaultData = async () => {
    await props.changeListParams(props.resource, defaultParams);
    props.refreshView();
  };

  return (
    <ListController {...props}>
      {controllerProps => {
        if (
          props.location.search === '' &&
          paramsDiffer(controllerProps, props)
        ) {
          // help debugging
          // console.log('PROPS', {
          //   filterDefaultValues: props.filterDefaultValues,
          //   sort: props.sort,
          //   page: props.page,
          //   perPage: props.perPage,
          // });
          // console.log('CONTROLLER PROPS', {
          //   filterValues: controllerProps.filterValues,
          //   currentSort: controllerProps.currentSort,
          //   page: controllerProps.page,
          //   perPage: controllerProps.perPage,
          // });
          setListToDefaultData();
        }
        return <ListView {...props} {...controllerProps} />;
      }}
    </ListController>
  );
};

List.defaultProps = {
  perPage: 20,
};

export default connect(
  undefined,
  {
    changeListParams: changeListParamsAction,
    refreshView: refreshViewAction,
  }
)(List);
