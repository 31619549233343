// adapted from: https://github.com/marmelab/react-admin/blob/master/packages/ra-ui-materialui/src/list/List.js
import React, { Children, cloneElement, useEffect } from 'react';
import classnames from 'classnames';
import { getListControllerProps, Pagination } from 'react-admin';
import { withStyles } from '@material-ui/core/styles';
import ListToolbar from './list-toolbar';

import { PAGE_SIZE_OPTIONS } from 'src/utils/constants';
import { PageTitle } from 'src/components';

export const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  actions: {
    zIndex: 2,
    display: 'flex',
    justifyContent: 'flex-end',
    flexWrap: 'wrap',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignSelf: 'flex-start',
  },
  noResults: { padding: 20 },
  scrollable: {
    overflow: 'auto',
    maxHeight: '100%',
    padding: '0 1rem',
  },
  stickyFooter: {
    left: 0,
    bottom: 0,
    position: 'sticky',
    width: '100%',
    padding: 0,
    height: theme.appBar.height,
    minHeight: theme.appBar.height,
    backgroundColor: 'white',
  },
});

const LargerPagination = props => (
  <Pagination rowsPerPageOptions={PAGE_SIZE_OPTIONS} {...props} />
);

const ListView = ({
  // component props
  actions,
  aside,
  filter,
  filters,
  bulkActionButtons,
  pagination,
  clearRowSelectionsOnUnmount,
  // overridable by user
  children,
  className,
  classes,
  exporter,
  title,
  ...rest
}) => {
  const { defaultTitle, version } = rest;
  const controllerProps = getListControllerProps(rest);

  useEffect(() => {
    return () => {
      if (clearRowSelectionsOnUnmount) controllerProps.onSelect([]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={classnames('list-page', classes.root, className)}>
      {title !== false && <PageTitle>{title || defaultTitle}</PageTitle>}
      {(filters || actions || bulkActionButtons) && (
        <ListToolbar
          filters={filters}
          {...controllerProps}
          actions={actions}
          bulkActionButtons={bulkActionButtons}
          exporter={exporter}
          permanentFilter={filter}
        />
      )}
      <div key={version} className={classes.scrollable}>
        {children &&
          cloneElement(Children.only(children), {
            ...controllerProps,
            hasBulkActions: bulkActionButtons !== false,
          })}
        {pagination &&
          cloneElement(pagination, {
            ...controllerProps,
            classes: { toolbar: classes.stickyFooter },
          })}
      </div>
      {aside && cloneElement(aside, controllerProps)}
    </div>
  );
};

ListView.defaultProps = {
  classes: {},
  pagination: <LargerPagination />,
};

export default withStyles(styles)(ListView);
