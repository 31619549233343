import React from 'react';
import { TableHeaderRow } from '@devexpress/dx-react-grid-material-ui';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  headerRow: {
    height: theme.tableRow.height,
  },
});

const HeaderRowComponent = withStyles(styles)(({ classes, ...props }) => (
  <TableHeaderRow.Row {...props} className={classes.headerRow} />
));

export default HeaderRowComponent;
