import React, { useEffect, useMemo, useState } from 'react';
import Avatar from '@material-ui/core/Avatar';
import classnames from 'classnames';
import MenuItem from '@material-ui/core/MenuItem';
import { withStyles } from '@material-ui/core/styles';

import { API_URL } from 'src/utils/constants';
import fetchNormal from 'src/data-provider/fetch-normal';
import { getUserEmail, getUserPicUrl } from 'src/utils/helpers';
import UserMenu from './base-user-menu';
import { userMenuStyles as styles } from 'src/utils/styles';

const CustomUserMenu = ({ classes, ...props }) => {
  const [webappVersion, setWebappVersion] = useState('');
  const [serviceVersion, setServiceVersion] = useState('');

  useEffect(
    () => setWebappVersion(process.env.REACT_APP_IMAGE_TAG || 'None'),
    []
  );

  useEffect(() => {
    const abortController = new AbortController();
    const options = { signal: abortController.signal };
    const url = `${API_URL}/get-env`;
    const callbacks = {
      success: payload => {
        if (payload.IMAGE_TAG) setServiceVersion(payload.IMAGE_TAG);
        else throw new Error('Empty IMAGE_TAG!');
      },
      error: () => setServiceVersion('Fetch error!'),
    };
    fetchNormal(url, options, callbacks);
    return () => abortController.abort();
  }, []);

  const userEmail = useMemo(() => getUserEmail() || 'Anonymous', []);
  const userProfilePic = useMemo(() => {
    let url = getUserPicUrl();
    if (url) url += '=s48'; // Request a smaller-sized image resource
    return url;
  }, []);

  return (
    <UserMenu
      label={`Signed in as ${userEmail}`}
      icon={
        <Avatar
          className={classes.avatar}
          alt={`Profile picture of ${userEmail}`}
          src={userProfilePic}
          color="primary"
        >
          {userEmail[0].toUpperCase()}
        </Avatar>
      }
      {...props}
    >
      <MenuItem
        className={classnames(classes.menuItem, classes.textMenuItem)}
        button={false}
      >{`Webapp version: ${webappVersion}`}</MenuItem>
      <MenuItem
        className={classnames(classes.menuItem, classes.textMenuItem)}
        button={false}
      >{`Service version: ${serviceVersion}`}</MenuItem>
    </UserMenu>
  );
};

export default withStyles(styles)(CustomUserMenu);
