export default resource => {
  return resource.startsWith('receivables/objects')
    ? 'objects'
    : resource.startsWith('receivables/transactions')
    ? 'receivables/transactions'
    : resource.startsWith('receivables/refundreq')
    ? 'refundreq'
    : resource.startsWith('receivables/banking/accounts')
    ? 'banking/accounts'
    : resource.startsWith('receivables/banking/statements')
    ? 'banking/statements'
    : resource.startsWith('receivables/banking/reconciliations')
    ? 'banking/reconciliations'
    : resource;
};
