import {
  LOCK_SELLER,
  STORAGE_KEY,
  UNLOCK_SELLER,
  UPDATE_SELLER,
  UPDATE_SELLERS,
  UPDATE_SELLERS_FETCH_ERROR,
} from './actions';

export default (state, action) => {
  switch (action.type) {
    case UPDATE_SELLER:
      if (action.payload && action.payload.id) {
        localStorage.setItem(STORAGE_KEY, action.payload.id);
      } else {
        localStorage.removeItem(STORAGE_KEY);
      }
      return { ...state, seller: action.payload };
    case UPDATE_SELLERS:
      return { ...state, sellers: action.payload };
    case UPDATE_SELLERS_FETCH_ERROR:
      return { ...state, error: action.payload };
    case LOCK_SELLER:
      return { ...state, locked: true };
    case UNLOCK_SELLER:
      return { ...state, locked: false };
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
};
