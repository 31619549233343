export default theme => ({
  menuItem: {
    color: theme.palette.text.secondary,
  },
  textMenuItem: {
    outline: 'none',
  },
  iconMenuPaddingStyle: {
    paddingRight: '1.2em',
  },
  avatar: {
    width: theme.appBar.height * 0.6,
    height: theme.appBar.height * 0.6,
    backgroundColor: theme.palette.primary.light,
    border: `1px solid ${theme.palette.secondary.main}`,
  },
});
