import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';

import * as serviceWorker from './serviceWorker';

import './index.css';
import App from './app';
import { getDataFromIframe, inIframe, logUserIn } from 'src/utils/helpers';
import { iamConfig } from './configs';
import { IdentityContext } from './identity-context';
import { LAST_BROWSED } from 'src/utils/constants';

const LoadingView = () => (
  <p className="center-screen center-text">Kiểm tra đăng nhập...</p>
);

const LoginView = () => (
  <p className="center-screen center-text">
    Bạn đã đăng xuất hoặc phiên đăng nhập đã hết hạn. Vui lòng đăng nhập lại{' '}
    <span className="link-text" data-testid="loginLink" onClick={logUserIn}>
      tại đây
    </span>
  </p>
);

export const Root = () => {
  const [lastBrowsedPath, setLastBrowsedPath] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(null);
  const [userInfo, setUserInfo] = useState(null);
  useEffect(() => {
    const {
      clientId: iframeClientId,
      oauthDomain: iframeOauthDomain,
    } = getDataFromIframe();

    const {
      clientId: defaultClientId,
      oauthDomain: defaultOauthDomain,
    } = iamConfig;

    let clientId = defaultClientId;
    let oauthDomain = defaultOauthDomain;
    if (inIframe()) {
      clientId = iframeClientId || clientId;
      oauthDomain = iframeOauthDomain || oauthDomain;
    }

    const { TekoID } = window;
    TekoID.init({
      ...iamConfig,
      clientId,
      oauthDomain,
    }).then(() => {
      setIsLoggedIn(TekoID.user.isLoggedIn());
    });
  }, []);

  useEffect(() => {
    if (isLoggedIn && !userInfo) {
      const { TekoID } = window;
      TekoID.user.getFullUserInfo().then(values => setUserInfo(values));
    }
  }, [isLoggedIn, userInfo]);

  useEffect(() => {
    const { pathname } = window.location;
    if (isLoggedIn) {
      const fromStorage = sessionStorage.getItem(LAST_BROWSED);
      setLastBrowsedPath(fromStorage || pathname);
      sessionStorage.removeItem(LAST_BROWSED);
    } else if (isLoggedIn === false) {
      // save last-browsed path to storage before getting directed to login page
      sessionStorage.setItem(LAST_BROWSED, pathname);
    }
  }, [isLoggedIn]);

  return isLoggedIn === false ? (
    <LoginView />
  ) : isLoggedIn && userInfo && lastBrowsedPath ? (
    <IdentityContext.Provider value={userInfo}>
      <App lastBrowsedPath={lastBrowsedPath} />
    </IdentityContext.Provider>
  ) : (
    <LoadingView />
  );
};

if (process.env.NODE_ENV !== 'test') {
  ReactDOM.render(<Root />, document.getElementById('root'));
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
