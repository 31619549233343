import { CREATE, DELETE, GET_ONE, UPDATE, UPDATE_MANY } from 'react-admin';

/**
 * @param {Object} error HTTP error from fetch()
 * @param {String} type One of the constants appearing at the top of this file, e.g. 'GET_LIST'
 * @returns {Object} Data Provider response
 */
export default (error, type) => {
  switch (type) {
    case CREATE:
    case GET_ONE:
    case UPDATE:
      return { data: { id: null }, error };
    case DELETE:
      return { data: null, error };
    case UPDATE_MANY:
      return { data: [], error };
    default:
      throw error;
  }
};
