import React from 'react';
import { withStyles } from '@material-ui/core/styles';

import Datagrid from './datagrid';

const styles = theme => ({
  scrollable: {
    overflow: 'auto',
    maxHeight: '40vh',
    padding: '0 1rem',
  },
  table: {
    marginBottom: theme.spacing.unit * 2,
  },
});

const UploadedDataListView = ({ classes, children, records = [] }) => {
  if (!records.length || !children) return null;

  const data = records.reduce((dataObj, entry) => {
    dataObj[entry.rowId] = entry;
    return dataObj;
  }, {});

  return (
    <div className={classes.scrollable}>
      <Datagrid
        className={classes.table}
        currentSort={{ field: 'rowId', order: 'ASC' }} // without this props, react-admin's Datagrid will throw error
        data={data}
        ids={records.map(r => r.rowId)}
      >
        {children}
      </Datagrid>
    </div>
  );
};

export default withStyles(styles)(UploadedDataListView);
