import React from 'react';
import classnames from 'classnames';
import MuiButton from '@material-ui/core/Button';
import { translate } from 'react-admin';
import { withStyles } from '@material-ui/core/styles';

const styles = {
  button: {
    position: 'relative',
  },
  withSideMargin: {
    margin: '0 0.5rem',
  },
  buttonIcon: {
    fontSize: 20,
    marginRight: 5,
  },
};

const CustomButton = ({
  className,
  classes = {},
  icon,
  label,
  translate,
  withSideSpacing,
  ...props
}) => (
  <MuiButton
    {...props}
    className={classnames(classes.button, className, {
      [classes.withSideMargin]: withSideSpacing === true,
    })}
  >
    {icon &&
      React.cloneElement(icon, {
        className: classes.buttonIcon,
      })}
    {label && translate(label, { _: label })}
  </MuiButton>
);

export default translate(withStyles(styles)(CustomButton));
