// adapted from https://github.com/DevExpress/devextreme-reactive/blob/master/packages/dx-react-grid-material-ui/src/templates/table-select-all-cell.jsx
import React from 'react';
import PropTypes from 'prop-types';
import Checkbox from '@material-ui/core/Checkbox';
import classnames from 'classnames';
import TableCell from '@material-ui/core/TableCell';
import { withStyles } from '@material-ui/core/styles';

import { styles as headerCellStyles } from '../header-cell';

const styles = theme => ({
  cell: {
    ...headerCellStyles(theme).headerCell,
    overflow: 'visible',
    paddingBottom: 0, // overwriting dx-react-grid headerCellStyles
    paddingTop: 0, // overwriting dx-react-grid headerCellStyles
    textAlign: 'center',
    '&:first-child': {
      ...headerCellStyles(theme).headerCell['&:first-child'],
      paddingLeft: 0, // overwriting dx-react-grid headerCellStyles:first-child
    },
    '&:last-child': {
      ...headerCellStyles(theme).headerCell['&:last-child'],
      paddingRight: 0, // overwriting dx-react-grid headerCellStyles:last-child
    },
  },
  alignWithRowSpan: {
    verticalAlign: 'bottom',
    paddingBottom: theme.spacing.unit * 0.5,
  },
  // pointer: {
  //   cursor: 'pointer',
  // },
});

const TableSelectAllCellBase = ({
  allSelected,
  someSelected,
  disabled,
  onToggle,
  classes,
  className,
  tableRow,
  tableColumn,
  rowSpan,
  ...restProps
}) => {
  const cellClasses = classnames(
    {
      [classes.cell]: true,
      // [classes.pointer]: !disabled,
      [classes.alignWithRowSpan]: rowSpan > 1,
    },
    className
  );

  return (
    <TableCell
      padding="none"
      className={cellClasses}
      rowSpan={rowSpan}
      {...restProps}
    >
      <Checkbox
        // className={classes.checkbox}
        color="primary"
        checked={allSelected}
        // indeterminate={someSelected}
        disabled={disabled}
        onClick={e => {
          if (disabled) return;

          e.stopPropagation();
          onToggle();
        }}
      />
    </TableCell>
  );
};

TableSelectAllCellBase.propTypes = {
  allSelected: PropTypes.bool,
  someSelected: PropTypes.bool,
  disabled: PropTypes.bool,
  onToggle: PropTypes.func,
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  tableRow: PropTypes.object,
  tableColumn: PropTypes.object,
  rowSpan: PropTypes.number,
};

TableSelectAllCellBase.defaultProps = {
  allSelected: false,
  someSelected: false,
  disabled: false,
  onToggle: () => {},
  className: undefined,
  tableRow: undefined,
  tableColumn: undefined,
  rowSpan: undefined,
};

export const TableSelectAllCell = withStyles(styles, {
  name: 'TableSelectAllCell',
})(TableSelectAllCellBase);
