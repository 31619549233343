// import { fade } from '@material-ui/core/styles/colorManipulator';

export default theme => ({
  muiInput: {
    fontSize: '0.9rem',
  },
  muiLabel: {
    fontSize: '0.9rem',
  },
  muiLabelShrink: {
    // to in sync w/ DateInput 1rem x scale(0.75)
    transform: 'translate(0, 1.5px) scale(0.833)',
  },
  root: {
    // backgroundColor: fade(theme.palette.primary.main, 0.08),
    backgroundColor: theme.palette.background.default,
    maxHeight: '44px',
    marginLeft: '10%',
    marginRight: '10%',
    width: '80% !important',
    // maxWidth: '180px',
  },
});
