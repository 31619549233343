import { useAsRolePermissions } from 'src/utils/helpers';

export const useServiceInvoicePermissions = () => {
  const {
    isDeptManager,
    isAccountant,
    isAccountantManager,
  } = useAsRolePermissions();
  const isCreator = isDeptManager || isAccountant || isAccountantManager,
    isApprover = isAccountantManager,
    isAdjustRevoker = isAccountant;
  return {
    isCreator,
    isApprover,
    isAdjustRevoker,
    canAccessServiceInvoice: isCreator || isApprover || isAdjustRevoker,
  };
};
