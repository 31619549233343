import React from 'react';

const Dashboard = () => {
  const title = `Tổng quan`;
  return (
    <iframe
      src="https://gcpf3v.axshare.com/#p=t_ng_quan"
      title={title}
      width="100%"
      height="700"
    />
  );
};
export default Dashboard;
