import React, { Fragment, PureComponent } from 'react';
import { Button as RaButton } from 'react-admin';
import UploadIcon from '@material-ui/icons/Publish';

class UploadButton extends PureComponent {
  state = { modalIsOpen: false };

  openModal = () => {
    this.setState({ modalIsOpen: true });
  };

  closeModal = () => {
    this.setState({ modalIsOpen: false });
  };

  render() {
    const { modalIsOpen } = this.state;
    const {
      className,
      color,
      disabled,
      icon = <UploadIcon />,
      openCommand = 'ui.action.upload',
      render,
      size,
      variant,
    } = this.props;

    return (
      <Fragment>
        <RaButton
          disabled={disabled}
          aria-label="Click to upload files"
          label={openCommand}
          onClick={this.openModal}
          color={color}
          size={size}
          variant={variant}
          className={className}
        >
          {icon}
        </RaButton>
        {render(modalIsOpen, this.closeModal)}
      </Fragment>
    );
  }
}

export default UploadButton;
