import React from 'react';

import { accAccountTypeKeys } from 'src/utils/choices';
import { ACCOUNT_TYPE_V2 } from 'src/utils/constants';
import BaseEnumInput from './base';
import { SelectInput } from 'src/components';

const AccountTypeInput = ({ component, ...props }) => (
  <BaseEnumInput
    endpoint={ACCOUNT_TYPE_V2}
    render={(choices, errorInfo) => {
      if (props.source === 'accAccount')
        choices = choices.filter(c => accAccountTypeKeys.includes(c.id));
      return React.createElement(component || SelectInput, {
        ...props,
        choices,
        ...errorInfo,
      });
    }}
  />
);

export default AccountTypeInput;
