import React from 'react';

import BaseEnumInput from './base';
import { SelectInput } from 'src/components';

const DebtStatusInput = props => (
  <BaseEnumInput
    endpoint="debt-status"
    render={(choices, errorInfo) => (
      <SelectInput {...props} choices={choices} {...errorInfo} />
    )}
  />
);

export default DebtStatusInput;
