import downloadjs from 'downloadjs';
import { HttpError } from 'react-admin';

export default (url, clientOpts = {}, filename, callbacks) => {
  const { TekoID } = window;
  const BEARER_TOKEN = `Bearer ${TekoID.user.getAccessToken()}`;
  const requestHeaders =
    clientOpts.headers || new Headers({ Authorization: BEARER_TOKEN });
  if (!requestHeaders.has('Authorization')) {
    requestHeaders.set('Authorization', BEARER_TOKEN);
  }
  const options = { ...clientOpts, headers: requestHeaders };

  const finalCallback =
    callbacks && callbacks.final && typeof callbacks.final === 'function'
      ? callbacks.final
      : false;

  const errorCallback =
    callbacks && callbacks.error && typeof callbacks.error === 'function'
      ? callbacks.error
      : false;

  return fetch(url, options)
    .then(response =>
      response
        .clone() // using clone() here so that we can re-read 'response' later
        .blob()
        .then(blob => {
          if (blob.type === 'application/json') {
            // throw error
            return response
              .clone()
              .json()
              .then(json => {
                throw new HttpError(
                  json.message || response.statusText,
                  response.status,
                  json
                );
              });
          } else if (blob.type === 'text/html') {
            // throw error
            return response
              .clone()
              .text()
              .then(text => {
                throw new HttpError(response.statusText, response.status, text);
              });
          } else {
            downloadjs(blob, filename);
            if (finalCallback) return finalCallback();
            else return Promise.resolve();
          }
        })
    )
    .catch(error => {
      if (errorCallback) {
        errorCallback(error);
        if (finalCallback) return finalCallback();
        else return Promise.resolve();
      } else {
        // let the consumer handle error
        return Promise.reject(error);
      }
    });
};
