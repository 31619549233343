// adapted from: https://github.com/marmelab/react-admin/blob/master/examples/demo/src/layout/Menu.js
import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { MenuItemLink, setSidebarVisibility, translate } from 'react-admin';
import { withStyles } from '@material-ui/core/styles';

import CalendarIcon from '@material-ui/icons/InsertInvitation';
import GridIcon from '@material-ui/icons/GridOn';
import InstitutionIcon from '@material-ui/icons/AccountBalance';
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import HollowPersonIcon from '@material-ui/icons/PermIdentity';

import SubMenu from '../submenu';
import { useFmRolePermissions } from 'src/utils/helpers';

const styles = {
  root: {
    // Style applied to the MenuItem from material-ui
    fontSize: '0.9rem',
    padding: '0.5rem 0.75rem',
  },
  active: { fontWeight: 'bold', fontStyle: 'italic' },
  icon: { paddingRight: '0.75rem' },
};

const REPORT_PATHS = ['transactions', 'reports', 'general-report'];
const PERIOD_REPORT_PATHS = ['period-report'];

const RecMenu = ({
  classes,
  location: { pathname },
  onMenuClick,
  // setSidebarVisibility,
  sidebarIsOpen,
  translate,
}) => {
  // const closeSidebar = () => setSidebarVisibility(false);
  const {
    canAccessReports,
    canAccessPeriodReports,
    canAccessTransImporter,
    canAccessBanking,
    canAccessCloseTheBooks,
    canAccessObjects,
  } = useFmRolePermissions();

  const getAccessiblePaths = () => {
    let path = '';
    if (canAccessReports) {
      path += REPORT_PATHS.join('|');
    }
    if (canAccessPeriodReports) {
      if (path.length) path += '|';
      path += PERIOD_REPORT_PATHS.join('|');
    }
    return RegExp(path);
  };

  const accessiblePaths = getAccessiblePaths();

  const [menuView, setMenuView] = useState(
    pathname && accessiblePaths.test(pathname)
  );
  const [menuBanking, setMenuBanking] = useState(
    pathname && /banking/.test(pathname)
  );

  return (
    <Fragment>
      {/* THEO DOI CONG NO */}
      {/* *************************************************************** */}
      {(canAccessReports || canAccessPeriodReports) && (
        <SubMenu
          handleToggle={() => setMenuView(!menuView)}
          isOpen={!!menuView}
          sidebarIsOpen={sidebarIsOpen}
          name="Theo dõi công nợ"
          icon={<CalendarIcon />}
        >
          {canAccessReports && (
            <MenuItemLink
              classes={classes}
              to="/receivables/reports/report-by-order"
              primaryText={translate(
                'resources.receivables/reports/report-by-order.name'
              )}
              onClick={onMenuClick}
              // uncomment the below if we want to close the sidebar automatically on clicking on a page
              // onClick={onMenuClick || closeSidebar}
            />
          )}
          {canAccessReports && (
            <MenuItemLink
              classes={classes}
              to="/receivables/reports/report-by-object"
              primaryText={translate(
                'resources.receivables/reports/report-by-object.name'
              )}
              onClick={onMenuClick}
            />
          )}
          {canAccessPeriodReports && (
            <MenuItemLink
              classes={classes}
              to="/receivables/period-reporting"
              primaryText="Báo cáo thời điểm"
              onClick={onMenuClick}
            />
          )}
          {canAccessReports && (
            <MenuItemLink
              classes={classes}
              to="/receivables/general-report"
              primaryText="Báo cáo chung"
              onClick={onMenuClick}
            />
          )}
          {canAccessReports && (
            <MenuItemLink
              classes={classes}
              to="/receivables/transactions/type-money"
              primaryText={translate(
                'resources.receivables/transactions/type-money.name'
              )}
              onClick={onMenuClick}
            />
          )}
          {/* {canAccessReports && (
            <MenuItemLink
              classes={classes}
              to="/receivables/transactions/type-goods"
              primaryText={translate(
                'resources.receivables/transactions/type-goods.name'
              )}
              onClick={onMenuClick}
            />
          )} */}
          {canAccessCloseTheBooks && (
            <MenuItemLink
              classes={classes}
              to="/receivables/dynamic-reports"
              primaryText="Báo cáo động"
              onClick={onMenuClick}
            />
          )}
        </SubMenu>
      )}
      {/* QUAN LY Y/C CHI */}
      {/* *************************************************************** */}
      {/* {canAccessRefundreq && (
        <MenuItemLink
          classes={classes}
          to="/receivables/refundreq"
          primaryText={translate('resources.receivables/refundreq.name')}
          leftIcon={<CashIcon />}
          onClick={onMenuClick}
        />
      )} */}
      {/* QUAN LY DOI TUONG NO */}
      {/* *************************************************************** */}
      {canAccessObjects && (
        <MenuItemLink
          classes={classes}
          to="/receivables/objects"
          leftIcon={<HollowPersonIcon />}
          primaryText={translate('resources.receivables/objects.name')}
          onClick={onMenuClick}
        />
      )}
      {/* TOOL IMPORT HACH TOAN */}
      {/* *************************************************************** */}
      {canAccessTransImporter && (
        <MenuItemLink
          classes={classes}
          to="/receivables/transaction-importer"
          leftIcon={<GridIcon />}
          primaryText={translate('common.transImporter')}
          onClick={onMenuClick}
        />
      )}
      {/* BANKING */}
      {/* *************************************************************** */}
      {canAccessBanking && (
        <SubMenu
          handleToggle={() => setMenuBanking(!menuBanking)}
          isOpen={!!menuBanking}
          sidebarIsOpen={sidebarIsOpen}
          name="Banking"
          icon={<InstitutionIcon />}
        >
          <MenuItemLink
            classes={classes}
            to="/receivables/banking/accounts"
            primaryText={translate(
              'resources.receivables/banking/accounts.name'
            )}
            onClick={onMenuClick}
          />
          <MenuItemLink
            classes={classes}
            to="/receivables/banking/statements"
            primaryText={translate(
              'resources.receivables/banking/statements.name'
            )}
            onClick={onMenuClick}
          />
          <MenuItemLink
            classes={classes}
            to="/receivables/banking/pending-statements"
            primaryText={translate('ui.heading.pendingStatements')}
            onClick={onMenuClick}
          />
          <MenuItemLink
            classes={classes}
            to="/receivables/banking/reconciliations"
            primaryText={translate(
              'resources.receivables/banking/reconciliations.name'
            )}
            onClick={onMenuClick}
          />
        </SubMenu>
      )}
      {/* SNAPSHOT REPORTS */}
      {/* *************************************************************** */}
      {canAccessCloseTheBooks && (
        <MenuItemLink
          classes={classes}
          to="/receivables/close-the-books"
          primaryText="Chốt số liệu"
          leftIcon={<PlaylistAddCheckIcon />}
          onClick={onMenuClick}
        />
      )}
    </Fragment>
  );
};

RecMenu.propTypes = {
  onMenuClick: PropTypes.func,
};

const mapStateToProps = state => ({
  sidebarIsOpen: state.admin.ui.sidebarOpen,
});

const enhance = compose(
  connect(
    mapStateToProps,
    { setSidebarVisibility }
  ),
  withStyles(styles),
  translate
);

export default enhance(RecMenu);
