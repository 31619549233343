import React from 'react';
import classnames from 'classnames';
import { SelectInput as Unstyled } from 'react-admin';
import { withStyles } from '@material-ui/core/styles';

import { inputStyles as styles } from 'src/utils/styles';

const SelectInput = withStyles(styles)(
  ({ classes, className, InputProps, ...props }) => {
    // have to do this combining because we also use SelectInput
    // as base for other input components, e.g. NumCompareInput
    const InputPropsClasses = InputProps
      ? {
          root: classes.muiInput,
          ...(InputProps.classes || {}),
        }
      : { root: classes.muiInput };
    return (
      <Unstyled
        {...props}
        className={classnames(className, classes.root)}
        InputLabelProps={{
          shrink: true,
          classes: {
            shrink: classes.muiLabelShrink,
          },
          FormLabelClasses: {
            root: classes.muiLabel,
          },
        }}
        InputProps={{
          // disableUnderline: true,
          classes: InputPropsClasses,
        }}
        onKeyUp={submitOnEnter}
      />
    );
  }
);

export default SelectInput;

let count = 0;
const submitOnEnter = event => {
  // credit: https://www.w3schools.com/howto/howto_js_trigger_button_enter.asp
  if (event.keyCode === 13) {
    event.preventDefault();
    if (count === 0) count += 1;
    else {
      count = 0;
      document.querySelector('button[type=submit]').click();
    }
  }
};
