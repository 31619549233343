import React from 'react';
import { Resource } from 'react-admin';

const Objects = React.lazy(() =>
  import(/* webpackChunkName: 'Objects' */ 'src/pages/receivables/objects')
);

export default [
  <Resource key="objects" name="receivables/objects" list={Objects} />,
];
