import React, { Fragment, memo } from 'react';
import CardContent from '@material-ui/core/CardContent';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';
import { translate } from 'react-admin';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

import ResultModalToolbar from './result-toolbar';
import SummaryTable from './summary-table';

const styles = theme => ({
  divider: {
    height: 3,
    margin: '1rem auto',
    visibility: 'hidden !important',
  },
  noBottomPad: {
    paddingBottom: 0,
  },
});

const ResultModal = ({
  // logical props
  records = [],
  // presentational props
  classes,
  renderInvalidTable,
  translate,
  // event handlers
  onCancel,
  onConfirm,
  onRetry,
}) => {
  const invalidRecords = records.filter(r => !r.isValid);
  return (
    <Fragment>
      <DialogTitle id="upload-result-dialog-title">
        {translate('ui.heading.updateResult')}
      </DialogTitle>
      <DialogContent className={classes.noBottomPad}>
        <CardContent>
          <SummaryTable
            invalidCount={invalidRecords.length}
            totalCount={records.length}
          />
          {invalidRecords.length ? (
            <Fragment>
              <Divider classes={{ root: classes.divider }} />
              <Typography paragraph variant="subheading">
                {translate('ui.heading.invalidRecords')}
              </Typography>
              {renderInvalidTable(invalidRecords)}
            </Fragment>
          ) : null}
        </CardContent>
      </DialogContent>
      <ResultModalToolbar
        onCancel={onCancel}
        onConfirm={onConfirm}
        onRetry={onRetry}
        confirmConds={records.length}
        retryConds={invalidRecords.length || !records.length}
      />
    </Fragment>
  );
};

export default memo(translate(withStyles(styles)(ResultModal)));
