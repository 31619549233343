import React, { useState } from 'react';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { NavLink } from 'react-router-dom';
import Tab from '@material-ui/core/Tab';

import { ACCOUNTING_WEB_URL } from 'src/utils/constants';
import { useAsRolePermissions } from 'src/utils/helpers';

export const menuItemsFull = [
  {
    name: 'Hoá đơn dịch vụ',
    url: '/service-invoices',
  },
  {
    name: 'Xuất hóa đơn cho chi nhánh',
    url: '/branch-invoices',
  },
  {
    name: 'Báo cáo VAT hàng bán/trả lại theo chi nhánh',
    url: ACCOUNTING_WEB_URL + '/accounting/vat-branch-report',
  },
  {
    name: 'Bảng kê hóa đơn, chứng từ hàng hóa, dịch vụ bán ra',
    url: ACCOUNTING_WEB_URL + '/accounting/vat-out-report',
  },
];

const AccountingNavMenu = props => {
  const {
    isDeptManager,
    isAccountant,
    isAccountantManager,
  } = useAsRolePermissions();
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  let menuItems = [];
  if (isDeptManager) menuItems = menuItemsFull.slice(0, 1);
  if (isAccountant || isAccountantManager) menuItems = menuItemsFull;

  return menuItems.length ? (
    <>
      <Tab
        {...props}
        label="Accounting"
        onClick={handleClick}
        aria-controls="simple-menu--accounting"
        aria-haspopup="true"
      />
      <Menu
        id="simple-menu--accounting"
        anchorEl={anchorEl}
        marginThreshold={40}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {menuItems.map((m, i) =>
          m.url.startsWith('/') ? (
            <MenuItem
              key={'accounting_menu_item_' + i}
              component={NavLink}
              to={m.url}
              onClick={handleClose}
            >
              {m.name}
            </MenuItem>
          ) : (
            <MenuItem
              key={'accounting_menu_item_' + i}
              component="a"
              href={m.url}
              onClick={handleClose}
            >
              {m.name}
            </MenuItem>
          )
        )}
      </Menu>
    </>
  ) : null;
};

export default AccountingNavMenu;
