// adapted from: https://github.com/marmelab/react-admin/blob/master/packages/ra-ui-materialui/src/form/SimpleForm.js
import React, { Children, useCallback } from 'react';
import classnames from 'classnames';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { FormInput } from 'react-admin';
import Grid from '@material-ui/core/Grid';
import MuiButton from '@material-ui/core/Button';
import { reduxForm } from 'redux-form';
import { translate } from 'react-admin';
import { withStyles } from '@material-ui/core/styles';

import { buttonStyles } from 'src/utils/styles';
import SearchInput from './inputs/search-input';

const FORM_NAME = 'search-form';

const styles = theme => ({
  ...buttonStyles(theme),
  button: {
    margin: '10px auto',
    height: 40,
    width: '95%', // use this when there is a rightButton
    // width: '100%', // use this when there is only 1 button
  },
  buttonsContainer: {
    paddingLeft: '10%',
    paddingRight: '10%',
  },
  leftButton: {
    float: 'left',
  },
  rightButton: {
    float: 'right',
  },
});

const FilterForm = ({
  basePath,
  children,
  classes,
  className,
  context,
  filterValues = {},
  hideResetButton,
  isLoading,
  onReset,
  record,
  resource,
  // redux-form props
  handleSubmit,
  invalid,
}) => {
  const clearAllFiltersAndSubmit = useCallback(() => onReset({}), [onReset]);

  if (context === 'quick-search') {
    const input = <SearchInput source="searchNoPrefix" resettable />;
    return (
      <form autoComplete="off" onSubmit={handleSubmit} className={className}>
        <Grid container>
          <Grid item xs={5}>
            <FormInput
              basePath={basePath}
              input={input}
              record={record}
              resource={resource}
            />
          </Grid>
        </Grid>
      </form>
    );
  }
  return (
    <form autoComplete="off" onSubmit={handleSubmit} className={className}>
      <Grid container alignItems="flex-end">
        {Children.map(children, input => (
          <Grid item xs={6} sm={4} md={3} lg={2}>
            <FormInput
              basePath={basePath}
              input={input}
              record={record}
              resource={resource}
            />
          </Grid>
        ))}
        <Grid item xs={6} sm={4} md={3} lg={2}>
          <Grid container classes={{ container: classes.buttonsContainer }}>
            <Grid item xs={6}>
              <MuiButton
                aria-label="Find"
                className={classnames(classes.button, classes.leftButton)}
                color="primary"
                type="submit"
                variant="raised"
                disabled={invalid || isLoading}
              >
                Tìm
              </MuiButton>
            </Grid>
            {!hideResetButton && (
              <Grid item xs={6}>
                <MuiButton
                  aria-label="Reset"
                  classes={{ outlinedPrimary: classes.outlined }}
                  className={classnames(classes.button, classes.rightButton)}
                  color="primary"
                  onClick={clearAllFiltersAndSubmit}
                  type="button"
                  variant="outlined"
                  disabled={Object.keys(filterValues).length === 0 || isLoading}
                >
                  Reset
                </MuiButton>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

const enhance = compose(
  connect((state, props) => ({
    initialValues: props.filterValues, // populate the default values to the input fields
    isLoading: state.admin.loading > 0,
    onReset: (customDefaults = {}) => props.setFilters(customDefaults),
  })),
  translate, // Must be before reduxForm so that it can be used in validation
  reduxForm({
    form: FORM_NAME,
    destroyOnUnmount: false, // do not destroy to preserve state across navigation
    enableReinitialize: true, // reinitialize form every time the initialValues prop changes
    onSubmit: (values, dispatch, props) => props && props.setFilters(values),
  }),
  withStyles(styles)
);

export default enhance(FilterForm);
