import blueGrey from './blue-grey';
import deepPurple from './deep-purple';
import indigo from './indigo';
import pink from './pink';
import teal from './teal';

export default {
  blueGrey,
  deepPurple,
  indigo,
  pink,
  teal,
};
