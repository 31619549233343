import React from 'react';
import classnames from 'classnames';
import { withStyles } from '@material-ui/core/styles';

import { inputStyles } from 'src/utils/styles';
import Unstyled from './base';

const styles = theme => ({
  ...inputStyles(theme),
  // selectMenu: {
  //   overflow: 'auto', // good in case of Chips
  // },
});

const SelectArrayInput = withStyles(styles)(
  ({ classes, className, ...props }) => {
    return (
      <Unstyled
        renderValue={null} // to overwrite the Chip display
        {...props} // these props could overwrite the 'renderValue' above
        className={classnames(className, classes.root)}
        InputLabelProps={{
          shrink: true,
          classes: {
            shrink: classes.muiLabelShrink,
          },
          FormLabelClasses: {
            root: classes.muiLabel,
          },
        }}
        InputProps={{
          // disableUnderline: true,
          classes: { root: classes.muiInput },
        }}
        options={{
          classes: {
            selectMenu: classes.selectMenu, // overflow styling, if any
          },
        }}
      />
    );
  }
);

export default SelectArrayInput;
