import { useAsRolePermissions } from 'src/utils/helpers';

export const useBranchInvoicePermissions = () => {
  const { isAccountant, isAccountantManager } = useAsRolePermissions();
  const isCreator = isAccountant || isAccountantManager,
    isApprover = isAccountantManager,
    isAdjustRevoker = isAccountant;
  return {
    isCreator,
    isApprover,
    isAdjustRevoker,
    canAccessBranchInvoice: isCreator || isApprover || isAdjustRevoker,
  };
};
