// adapted from https://github.com/DevExpress/devextreme-reactive/blob/master/packages/dx-react-grid-material-ui/src/templates/table-select-cell.jsx
import React from 'react';
import PropTypes from 'prop-types';
import Checkbox from '@material-ui/core/Checkbox';
import classnames from 'classnames';
import TableCell from '@material-ui/core/TableCell';
import { withStyles } from '@material-ui/core/styles';

import { styles as cellStyles } from '../cell';

const styles = theme => ({
  cell: {
    ...cellStyles(theme).cell,
    overflow: 'visible',
    padding: 0, // overwriting dx-react-grid cellStyles
    textAlign: 'center',
    '&:first-child': {
      ...cellStyles(theme).cell['&:first-child'],
      paddingLeft: 0, // overwriting dx-react-grid cellStyles:first-child
    },
    '&:last-child': {
      ...cellStyles(theme).cell['&:last-child'],
      paddingRight: 0, // overwriting dx-react-grid cellStyles:last-child
    },
  },
});

export const TableSelectCellBase = ({
  style,
  selected,
  onToggle,
  classes,
  className,
  row,
  tableRow,
  tableColumn,
  ...restProps
}) => (
  <TableCell
    padding="none"
    style={style}
    className={classnames(classes.cell, className)}
    {...restProps}
  >
    <Checkbox
      // className={classes.checkbox}
      color="primary"
      checked={selected}
      onClick={e => {
        e.stopPropagation();
        onToggle();
      }}
    />
  </TableCell>
);

TableSelectCellBase.propTypes = {
  style: PropTypes.object,
  selected: PropTypes.bool,
  onToggle: PropTypes.func,
  classes: PropTypes.object.isRequired,
  row: PropTypes.any,
  tableRow: PropTypes.object,
  tableColumn: PropTypes.object,
  className: PropTypes.string,
};

TableSelectCellBase.defaultProps = {
  style: null,
  selected: false,
  onToggle: () => {},
  row: undefined,
  tableRow: undefined,
  tableColumn: undefined,
  className: undefined,
};

export const TableSelectCell = withStyles(styles, { name: 'TableSelectCell' })(
  TableSelectCellBase
);
