import React from 'react';
import MuiTextField from '@material-ui/core/TextField';
import { translate } from 'react-admin';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core';

import { MAX_DATE_RANGE_INCLUSIVE, calculateDayDiff } from 'src/utils/helpers';

const styles = theme => ({
  datePickersContainer: {
    display: 'flex',
    alignItems: 'baseline',
    marginTop: '1rem',
    '&>p': {
      fontSize: '1rem',
      margin: '0 0.5rem',
    },
  },
});

const DatePickerForm = ({
  classes,
  disabled,
  endDate,
  endDateId,
  endDateLabel,
  onChange,
  startDate,
  startDateId,
  startDateLabel,
  translate,
}) => {
  const dateRange =
    calculateDayDiff(new Date(startDate), new Date(endDate)) + 1;
  return (
    <form className={classes.datePickersContainer}>
      <MuiTextField
        required
        disabled={disabled}
        id={startDateId}
        type="date"
        label={startDateLabel}
        onChange={onChange}
        InputLabelProps={{
          shrink: true,
        }}
        inputProps={{ defaultValue: startDate }}
      />
      <Typography>-</Typography>
      <MuiTextField
        required
        disabled={disabled}
        id={endDateId}
        type="date"
        label={endDateLabel}
        onChange={onChange}
        InputLabelProps={{
          shrink: true,
        }}
        inputProps={{ defaultValue: endDate }}
      />
      <Typography
        style={{
          color: disabled
            ? 'lightgrey'
            : dateRange <= MAX_DATE_RANGE_INCLUSIVE
            ? 'green'
            : 'red',
        }}
      >
        {`(${translate('ui.count.day', { smart_count: dateRange })})`}
      </Typography>
    </form>
  );
};

export default translate(withStyles(styles)(DatePickerForm));
