/**
 * Check whether current app is in iframe or not
 */
export const inIframe = () => {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
};

/**
 * Get data passed from container (staff interface) to iframe (current app)
 */
export const getDataFromIframe = () => {
  return isJsonString(window.name) ? JSON.parse(window.name) : {};
};

const isJsonString = str => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};
