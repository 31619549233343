import { fade } from '@material-ui/core/styles/colorManipulator';
import green from '@material-ui/core/colors/green';
import blue from '@material-ui/core/colors/blue';
import orange from '@material-ui/core/colors/orange';

export default ({ palette }) => ({
  outlined: {
    border: `1.5px solid`,
    '&:hover': {
      border: `1.5px solid`,
    },
  },
  disabled: {
    color: palette.action.disabled,
    background: palette.action.disabledBackground,
  },
  blue: {
    color: blue[500],
    '&:hover': {
      backgroundColor: fade(blue[500], palette.action.hoverOpacity),
    },
  },
  green: {
    color: green[500],
    '&:hover': {
      backgroundColor: fade(green[500], palette.action.hoverOpacity),
    },
  },
  red: {
    color: palette.error.main,
    '&:hover': {
      backgroundColor: fade(palette.error.main, palette.action.hoverOpacity),
    },
  },
  orange: {
    color: orange[500],
    '&:hover': {
      backgroundColor: fade(orange[500], palette.action.hoverOpacity),
    },
  },
  blueFilled: {
    color: palette.common.white,
    backgroundColor: blue[500],
    '&:hover': {
      backgroundColor: blue[700],
    },
  },
  greenFilled: {
    color: palette.common.white,
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[700],
    },
  },
  redFilled: {
    color: palette.getContrastText(palette.error.main),
    backgroundColor: palette.error.main,
    '&:hover': {
      backgroundColor: palette.error.dark,
    },
  },
  orangeFilled: {
    color: palette.common.white,
    backgroundColor: orange[500],
    '&:hover': {
      backgroundColor: orange[700],
    },
  },
  asHyperlinkText: {
    // credit: https://stackoverflow.com/a/1368286
    background: 'none !important',
    border: 'none',
    color: 'blue',
    cursor: 'pointer',
    font: 'inherit',
    padding: '0 !important',
    textDecoration: 'underline',
  },
});
