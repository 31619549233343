import React, { Fragment } from 'react';
import { Button as RaButton } from 'react-admin';
import EmailIcon from '@material-ui/icons/Email';
import VibrationIcon from '@material-ui/icons/Vibration';

const RemindButtons = props => {
  return (
    <Fragment>
      <RaButton disabled label="ui.action.remindByEmail">
        <EmailIcon />
      </RaButton>
      <RaButton disabled label="ui.action.remindByApp">
        <VibrationIcon />
      </RaButton>
    </Fragment>
  );
};

export default RemindButtons;
