import React, { Fragment, memo } from 'react';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import MuiToolbar from '@material-ui/core/Toolbar';
import { translate } from 'react-admin';
import { withStyles } from '@material-ui/core/styles';

import { CustomButton, ErrorText } from 'src/components';

const styles = theme => ({
  topPadded: {
    paddingTop: theme.spacing.unit * 3,
  },
});

const ErrorModal = ({
  // presentational props
  classes,
  message,
  retryCommand = 'ui.action.retry',
  translate,
  // event handlers
  onCancel,
  onRetry,
}) => (
  <Fragment>
    <DialogTitle id="error-dialog-title">
      {translate('ra.page.error')}
    </DialogTitle>
    <DialogContent className={classes.topPadded}>
      <ErrorText error={message} />
    </DialogContent>
    <DialogActions>
      <MuiToolbar>
        <CustomButton
          aria-label="Cancel"
          label="ra.action.cancel"
          onClick={onCancel}
          withSideSpacing
        />
        <CustomButton
          aria-label="Retry"
          color="primary"
          label={retryCommand}
          onClick={onRetry}
          variant="raised"
          withSideSpacing
        />
      </MuiToolbar>
    </DialogActions>
  </Fragment>
);

export default memo(translate(withStyles(styles)(ErrorModal)));
