import {
  CREATE,
  DELETE,
  DELETE_MANY,
  GET_LIST,
  GET_ONE,
  UPDATE,
  UPDATE_MANY,
} from 'react-admin';
import { stringify } from 'query-string';

import { ADD_MANY, API_URL } from 'src/utils/constants';
import getApiEndpoint from './parse-resource';
import parseReqParamsBeforeSend from './parse-params';

/**
 * @param {String} type One of the constants appearing at the top of this file, e.g. 'GET_LIST'
 * @param {String} resource Name of the resource to fetch, e.g. 'posts'
 * @param {Object} params The Data Provider request params, depending on the type
 * @returns {Object} { url, options } The HTTP request parameters
 */
export default (type, resource, params) => {
  const endpoint = getApiEndpoint(resource);
  const { TekoID } = window;
  const options = {
    headers: new Headers({
      Authorization: `Bearer ${TekoID.user.getAccessToken()}`,
    }),
  };

  switch (type) {
    case GET_LIST: {
      let page, perPage;
      if (params.pagination) {
        page = params.pagination.page;
        perPage = params.pagination.perPage;
      }
      if (isNaN(page)) page = 1;
      if (isNaN(perPage)) perPage = 20;

      const query = {
        limit: perPage,
        offset: perPage * (page - 1),
        ...parseReqParamsBeforeSend(params.filter),
      };

      const url = `${API_URL}/${endpoint}?${stringify(query)}`;
      return { url, options };
    }
    case GET_ONE: {
      let url = '';

      const { id } = params;
      let identifier;
      try {
        // our specific use case
        identifier = JSON.parse(id);
        if (typeof identifier !== 'object')
          throw new Error('id is not an object');
        const query = parseReqParamsBeforeSend(identifier);
        url = `${API_URL}/${endpoint}?${stringify(query)}`;
      } catch (e) {
        // general case: id is a string, not an object
        url = `${API_URL}/${endpoint}/${id}`;
      }
      return { url, options };
    }
    case CREATE: {
      const { data } = params;
      const query = data;
      const url = `${API_URL}/${endpoint}?${stringify(query)}`;
      return { url, options: { ...options, method: 'POST' } };
    }
    case UPDATE: {
      const { id, data } = params;
      const query = {
        id,
        ...data,
      };
      let url = '';
      if (!endpoint.startsWith('admin')) {
        delete query.id;
        url = `${API_URL}/${endpoint}/${id}?${stringify(query)}`;
      } else url = `${API_URL}/${endpoint}?${stringify(query)}`; // this if-else is because the current format of our /admin API differs from that of the data API
      return { url, options: { ...options, method: 'PUT' } };
    }
    case UPDATE_MANY: {
      const { ids, data } = params;
      const query = {
        ids: JSON.stringify(ids),
        ...data,
      };
      const url = `${API_URL}/${endpoint}?${stringify(query)}`;
      return { url, options: { ...options, method: 'PUT' } };
    }
    case DELETE: {
      const { id } = params;
      const url = `${API_URL}/${endpoint}/${id}`;
      return { url, options: { ...options, method: 'DELETE' } };
    }
    case DELETE_MANY:
    case ADD_MANY: {
      const { id, ids } = params;
      const query = {
        id,
        ids: JSON.stringify(ids),
      };
      const url = `${API_URL}/${endpoint}?${stringify(query)}`;
      return {
        url,
        options: {
          ...options,
          method: type === DELETE_MANY ? 'DELETE' : 'POST',
        },
      };
    }
    default:
      throw new Error(`Unsupported fetch action type ${type}`);
  }
};
