import React, { memo } from 'react';
import PropTypes from 'prop-types';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import MuiTable from '@material-ui/core/Table';
import MuiTableBody from '@material-ui/core/TableBody';
import MuiTableHead from '@material-ui/core/TableHead';
import { translate } from 'react-admin';
import { withStyles } from '@material-ui/core/styles';

import InfoTableRow from './info-tablerow';
import styles, { CustomTableHeaderCell, CustomTableRow } from './styles';

const OverviewCard = ({ classes, headerCells = [], rows }) => (
  <Card className={classes.card}>
    <CardContent className={classes.cardContent} id="thinBottomPad">
      <MuiTable>
        <MuiTableHead>
          <CustomTableRow>
            {headerCells.map(({ colSpan, content }, index) => (
              <CustomTableHeaderCell key={index} colSpan={colSpan}>
                {content}
              </CustomTableHeaderCell>
            ))}
          </CustomTableRow>
        </MuiTableHead>

        <MuiTableBody>
          {rows.map(row => (
            <InfoTableRow key={row.rowNumber} cells={row.cells} />
          ))}
        </MuiTableBody>
      </MuiTable>
    </CardContent>
  </Card>
);

OverviewCard.propTypes = {
  classes: PropTypes.object,
  headerCells: PropTypes.arrayOf(
    PropTypes.shape({
      colSpan: PropTypes.number,
      content: PropTypes.string,
    })
  ),
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      rowNumber: PropTypes.number,
      cells: PropTypes.array,
    })
  ).isRequired,
};

export default memo(translate(withStyles(styles)(OverviewCard)));
