import red from '@material-ui/core/colors/red';
import teal from '@material-ui/core/colors/teal';

export default {
  primary: {
    main: teal[700],
    dark: teal[900],
  },
  secondary: {
    // used for TabIndicator
    main: red[50],
  },
};
