import React from 'react';

import {
  APPROVED,
  CANCELLED,
  DONE,
  PENDING,
  REJECTED,
} from 'src/utils/constants';
import BaseChip from './base-chip';

const colorDict = {
  [PENDING]: 'blue',
  [APPROVED]: 'green', // TODO: reconciled === false
  // [DONE]: 'darkGreen', // TODO: remainingBalance === 0
  [REJECTED]: 'red',
  // [REMAINING]: 'orange', // TODO: reconciled === true && remainingBalance > 0
  [CANCELLED]: 'grey',
  [DONE]: 'orange',
};

const BankStatementStatusChip = ({ status }) => {
  const key = (status && status.id) || status;
  const value = (status && status.name) || status;
  if (!value) return null;
  return <BaseChip color={colorDict[key]} label={value} />;
};

export default BankStatementStatusChip;
