import React from 'react';
import { Redirect, Route } from 'react-router-dom';

const ServiceInvoiceApproveSwitch = React.lazy(() =>
  import(/* webpackChunkName: 'ServiceInvoiceApproveSwitch' */ 'src/pages/service-invoices/one/index approve switch')
);
const ServiceInvoiceCreateSwitch = React.lazy(() =>
  import(/* webpackChunkName: 'ServiceInvoiceCreateSwitch' */ 'src/pages/service-invoices/one/index create switch')
);
const ServiceInvoiceListSwitch = React.lazy(() =>
  import(/* webpackChunkName: 'ServiceInvoiceListSwitch' */ 'src/pages/service-invoices/list')
);

export default [
  <Route
    key="serinv_0"
    exact
    path="/service-invoices"
    render={() => <Redirect to="/service-invoices/list" />}
  />,
  <Route
    key="serinv_1"
    path="/service-invoices/list"
    component={ServiceInvoiceListSwitch}
  />,
  <Route
    key="serinv_2"
    exact
    path="/service-invoices/create"
    component={ServiceInvoiceCreateSwitch}
  />,
  <Route
    key="serinv_3"
    exact
    path="/service-invoices/detail/:id"
    component={ServiceInvoiceApproveSwitch}
  />,
];
