import React from 'react';

import {
  APPROVED,
  CANCELLED,
  DONE,
  DRAFT,
  PENDING,
  REJECTED,
} from 'src/utils/constants';
import BaseChip from './base-chip';

const colorDict = {
  [DRAFT]: 'lightBlue',
  [PENDING]: 'blue',
  [APPROVED]: 'green',
  [DONE]: 'darkGreen',
  [REJECTED]: 'red',
  [CANCELLED]: 'grey',
};

const ReqStatusChip = ({ status }) => {
  const key = (status && status.id) || status;
  const value = (status && status.name) || status;
  if (!value) return null;
  return <BaseChip color={colorDict[key]} label={value} />;
};

export default ReqStatusChip;
