import React from 'react';
import { Resource } from 'react-admin';

const BankAccounts = React.lazy(() =>
  import(/* webpackChunkName: 'BankAccounts' */ 'src/pages/receivables/banking/accounts')
);
const BankReconciliations = React.lazy(() =>
  import(/* webpackChunkName: 'BankReconciliations' */ 'src/pages/receivables/banking/reconciliations')
);
const BankStatements = React.lazy(() =>
  import(/* webpackChunkName: 'BankStatements' */ 'src/pages/receivables/banking/statements')
);

export default [
  <Resource
    key="bankAccounts"
    name="receivables/banking/accounts"
    list={BankAccounts}
  />,
  <Resource
    key="bankStatements"
    name="receivables/banking/statements"
    list={BankStatements}
  />,
  <Resource
    key="bankRecons"
    name="receivables/banking/reconciliations"
    list={BankReconciliations}
  />,
];
