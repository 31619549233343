export const filterForGoodsVoucherTypes = choices =>
  (choices || []).filter(
    c => c.name.startsWith('Vận đơn') || c.name.startsWith('Lộ trình')
  );

export const filterForMoneyVoucherTypes = choices =>
  (choices || []).filter(
    c =>
      c.name === 'Vận đơn giao thành công' ||
      c.name === 'Vận đơn giao thành công 1 phần' ||
      (!c.name.startsWith('Vận đơn') && !c.name.startsWith('Lộ trình'))
  );
