import { createContext, useContext } from 'react';

export const IdentityContext = createContext();
IdentityContext.displayName = 'IdentityContext';

export const useIdentityContext = () => {
  const context = useContext(IdentityContext);
  if (context === undefined) {
    throw new Error(
      'useIdentityContext must be used within an IdentityContextProvider'
    );
  }
  return context;
};
