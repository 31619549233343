// adapted from https://github.com/DevExpress/devextreme-reactive/blob/master/packages/dx-react-grid-material-ui/src/plugins/table-selection.jsx
import React from 'react';
import { TableSelection as TableSelectionBase } from '@devexpress/dx-react-grid';
import { withComponents } from '@devexpress/dx-react-core';

import { TableSelectAllCell as HeaderCell } from './table-select-all-cell';
import { TableSelectCell as Cell } from './table-select-cell';
import { TableSelectRow as Row } from './table-select-row';

const TableSelectionWithWidth = props => (
  <TableSelectionBase selectionColumnWidth={58} {...props} />
);
TableSelectionWithWidth.components = TableSelectionBase.components;

export const TableSelection = withComponents({ Row, Cell, HeaderCell })(
  TableSelectionWithWidth
);
TableSelection.COLUMN_TYPE = TableSelectionBase.COLUMN_TYPE;
