import React from 'react';
import { withStyles } from '@material-ui/core/styles';

import { inputStyles as styles } from 'src/utils/styles';
import Unstyled from './base';

const AutocompleteInput = withStyles(styles)(
  ({ classes, disabled, options, ...props }) => (
    <Unstyled
      {...props}
      className={classes.root}
      options={{
        disabled,
        InputLabelProps: {
          shrink: true,
          classes: {
            shrink: classes.muiLabelShrink,
          },
          FormLabelClasses: {
            root: classes.muiLabel,
          },
        },
        InputProps: {
          // disableUnderline: true,
          classes: { root: classes.muiInput },
        },
        ...options,
      }}
    />
  )
);

export default AutocompleteInput;
