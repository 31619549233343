import * as React from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  Grid,
  Zoom,
  Dialog,
  DialogContent,
  CircularProgress,
  Icon,
} from '@material-ui/core';
function Transition(props) {
  return <Zoom {...props} />;
}
class LoadingDialog extends React.Component {
  render() {
    const { classes, open, text, isError } = this.props;
    return (
      <Dialog
        open={!!open}
        TransitionComponent={Transition}
        keepMounted
        style={{ zIndex: 1350 }} // keeping it on top of other action dialogs
      >
        <DialogContent style={{ overflow: 'hidden', padding: '20px 24px' }}>
          <Grid container alignItems="center" justify="center">
            {isError ? (
              <Icon className={classes.error}>close</Icon>
            ) : (
              <CircularProgress className={classes.progress} />
            )}
            {text && <span className={classes.textLoading}>{text}</span>}
          </Grid>
        </DialogContent>
      </Dialog>
    );
  }
}
const styles = theme => ({
  textLoading: {
    marginLeft: theme.spacing.unit * 2,
  },
  error: {
    fontSize: '2rem',
    color: '#da6262',
    fontWeight: 'bolder',
  },
});
export default withStyles(styles)(LoadingDialog);
