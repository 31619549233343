import { fetchUtils } from 'react-admin';

import convertDataProviderRequestToHTTP from './convert-request';
import convertHTTPErrorToDataProvider from './convert-error';
import convertHTTPResponseToDataProvider from './convert-response';

/**
 * @param {string} type Request type, e.g GET_LIST
 * @param {string} resource Resource name, e.g. "posts"
 * @param {Object} payload Request parameters. Depends on the request type
 * @returns {Promise} the Promise for response
 */
export default (type, resource, params, clientOptions) => {
  const { fetchJson } = fetchUtils;
  const { url, options } = convertDataProviderRequestToHTTP(
    type,
    resource,
    params
  );
  return fetchJson(url, { ...options, ...clientOptions })
    .then(response =>
      convertHTTPResponseToDataProvider(response, type, resource, params)
    )
    .catch(error => convertHTTPErrorToDataProvider(error, type));
};
