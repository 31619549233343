import React from 'react';
import { Redirect, Route } from 'react-router-dom';

export default [
  <Route
    key="adm_0"
    exact
    path="/admin"
    render={() => <Redirect to="/admin/users" />}
  />,
];
