import { useContext } from 'react';

import { IdentityContext } from 'src/identity-context';

export const useFmRolePermissions = () => {
  const { roles, permissions } = useContext(IdentityContext);
  return checkFmRolePermissions(roles, permissions);
};

const checkFmRolePermissions = (roles, permissions) => {
  let canAccessAdmin = false,
    canAccessBanking = false,
    canAccessCloseTheBooks = false,
    canAccessObjects = false,
    canAccessPeriodReports = false,
    canAccessRefundreq = false,
    canAccessReports = false,
    canAccessTransImporter = false;

  if (permissions && permissions.length) {
    for (const p of permissions) {
      if (p.includes('fms:permission')) canAccessAdmin = true;
      else if (p.includes('fms:tools_import')) canAccessTransImporter = true;
      else if (p.includes('fms:reports')) canAccessReports = true;
      else if (p.includes('fms:period_report')) canAccessPeriodReports = true;
      else if (p.includes('fms:refundreq')) canAccessRefundreq = true;
      else if (p.includes('fms:debt_object')) canAccessObjects = true;
      else if (p.includes('fms:banking')) canAccessBanking = true;
      else if (p.includes('fms:system_admin')) canAccessCloseTheBooks = true;
      if (
        canAccessAdmin &&
        canAccessTransImporter &&
        canAccessReports &&
        canAccessPeriodReports &&
        canAccessRefundreq &&
        canAccessObjects &&
        canAccessBanking &&
        canAccessCloseTheBooks
      )
        break;
    }
  }

  return {
    canAccessAdmin,
    canAccessBanking,
    canAccessCloseTheBooks,
    canAccessObjects,
    canAccessPeriodReports,
    canAccessRefundreq,
    canAccessReports,
    canAccessTransImporter,
    canAccessReceivables:
      canAccessBanking ||
      canAccessObjects ||
      canAccessCloseTheBooks ||
      canAccessPeriodReports ||
      canAccessRefundreq ||
      canAccessReports ||
      canAccessTransImporter,
  };
};

export const useAsRolePermissions = () => {
  const { roles, permissions } = useContext(IdentityContext);
  return checkAsRolePermissions(roles, permissions);
};

const checkAsRolePermissions = (roles, permissions) => {
  let isDeptManager = false,
    isAccountant = false,
    isAccountantManager = false;
  if (roles && roles.length) {
    for (let i = 0; i < roles.length; i++) {
      const role = roles[i];
      if (role === 'as:manager') {
        isDeptManager = true;
      } else if (role === 'as:accountant') {
        isAccountant = true;
      } else if (role === 'as:accountant_manager') {
        isAccountantManager = true;
      }
      if (isDeptManager && isAccountant && isAccountantManager) {
        break;
      }
    }
  }
  return {
    isDeptManager,
    isAccountant,
    isAccountantManager,
  };
};
