import { noTextOperators } from 'src/components/inputs/strcompare-input';

/**
 * Parses request params into correct format before sending to data API
 *
 * @param {Object} params the params to be parsed
 * @returns {Object} the parsed params
 */
export default params => {
  let parsedParams = {};
  for (let k in params) {
    if (isNoPrefixInput(k)) parseNoPrefix(params, parsedParams, k);
    else if (isMultiSelect(k)) parseMultiSelect(params, parsedParams, k);
    else if (isOperatorInput(k)) parseOperator(params, parsedParams, k);
    else if (isOperandInput(k)) parseOperand(params, parsedParams, k);
    else if (isDateInput(k)) parseDate(params, parsedParams, k);
    else if (isStartsWithInput(k)) parseTextStartsWith(params, parsedParams, k);
    else if (isContainsInput(k)) parseTextContains(params, parsedParams, k);
    else parseTextEquals(params, parsedParams, k);
  }
  return parsedParams;
};

const isNoPrefixInput = k => !!k.endsWith('NoPrefix');

const isMultiSelect = k => !!k.endsWith('MultiSelect');

const isOperatorInput = k => !!k.endsWith('Operator');

const isOperandInput = k => !!k.endsWith('Operand');

const isDateInput = k =>
  !!(
    k.endsWith('DateFrom') ||
    k.endsWith('DateTo') ||
    k.endsWith('TimeFrom') ||
    k.endsWith('TimeTo')
  );

const isStartsWithInput = k => !!k.endsWith('StartsWith');

const isContainsInput = k => {
  const containsFields = [
    'accountNo',
    'bankRef',
    'bill',
    // 'branch',
    'cashierCode',
    'createdByEmail',
    'custCode',
    'customerAccId',
    'customerId',
    'customerName',
    'description',
    'email',
    'exportCode',
    'importCode',
    'invNo',
    'invSeri',
    'note',
    'objectAccId',
    'objectGroup',
    'objectId',
    'objectName',
    'objectTypeLike',
    'opsId',
    'opsName',
    'orderCode',
    'originalBill',
    'paymentCode',
    'paymentNo',
    'phone',
    'reqCode',
    'returnOrder',
    'saleOrder',
    'shipperCode',
    'sourceId',
    // 'storeId',
    'taxCode',
    'transportId',
    'userEmail',
    'voucherCode',
    'voucherId',
  ];
  return containsFields.includes(k);
};

const parseNoPrefix = (params, parsedParams, k) => {
  let v = params[k];
  if (typeof v === 'string') {
    // trim any leading and trailing spaces
    v = v.trim();
  } else if (Array.isArray(v)) v = v.join(',');
  k = k.split('NoPrefix')[0]; // e.g. debtorIdNoPrefix --> debtorId
  parsedParams[k] = v;
};

const parseMultiSelect = (params, parsedParams, k) => {
  let v = params[k];
  if (typeof v === 'string') {
    // trim any leading and trailing spaces
    v = v.trim();
  } else if (Array.isArray(v)) v = v.join(',');
  v = `in${v}`;
  k = k.split('MultiSelect')[0]; // e.g. statusMultiSelect --> status

  // if both eq and multiSelect exist for the same param, use eq (the more specific of the two)
  if (parsedParams[k] && parsedParams[k].startsWith('eq')) return;
  else parsedParams[k] = v;
};

const parseOperator = (params, parsedParams, k) => {
  const officialKey = k.split('Operator')[0]; // e.g. debitOperator --> debit
  if (parsedParams[officialKey]) return;

  const operatorVal = params[k];
  if (noTextOperators.includes(operatorVal)) {
    parsedParams[officialKey] = operatorVal;
  } else {
    const operandKey = `${officialKey}Operand`;
    const operandVal = params[operandKey];
    if (operandVal !== undefined)
      parsedParams[officialKey] = `${operatorVal}${operandVal}`;
  }
};

const parseOperand = (params, parsedParams, k) => {
  const officialKey = k.split('Operand')[0]; // e.g. debitOperand --> debit
  if (parsedParams[officialKey]) return;

  const operandVal = isNaN(parseFloat(params[k]))
    ? null
    : parseFloat(params[k]);
  const operatorKey = `${officialKey}Operator`;
  const operatorVal = params[operatorKey];
  if (operatorVal) parsedParams[officialKey] = `${operatorVal}${operandVal}`;
};

const parseDate = (params, parsedParams, k) => {
  const v = params[k];
  const isStartDate = k => k.endsWith('DateFrom') || k.endsWith('TimeFrom');
  if (isStartDate(k)) parsedParams[k] = `ge${v} 00:00:00`;
  else parsedParams[k] = `le${v} 23:59:59`;
};

const parseTextStartsWith = (params, parsedParams, k) => {
  const v = params[k].trim();
  // e.g. objectAccIdStartsWith --> objectAccId
  if (k.endsWith('StartsWith')) k = k.split('StartsWith')[0];
  parsedParams[k] = `sw${v}`;
};

const parseTextContains = (params, parsedParams, k) => {
  const v = params[k].trim();
  if (k.endsWith('Like')) k = k.split('Like')[0]; // e.g. objectTypeLike --> objectType
  parsedParams[k] = `lk${v}`;
};

const parseTextEquals = (params, parsedParams, k) => {
  const v = params[k];
  if (k.endsWith('Exact')) k = k.split('Exact')[0]; // e.g. objectIdExact --> objectId
  parsedParams[k] = `eq${v}`;
};
