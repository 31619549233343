import React from 'react';
import { TableHeaderRow } from '@devexpress/dx-react-grid-material-ui';
import { withStyles } from '@material-ui/core/styles';

export const styles = theme => ({
  headerCell: {
    paddingBottom: 6,
    paddingTop: 6,
    color: theme.palette.grey[600],
    fontSize: '0.8125rem',
    textAlign: 'center',
    borderBottom: `3px solid ${theme.palette.text.secondary}`,
    borderTop: `1px solid ${theme.palette.text.secondary}`,
    borderRight: `1px solid ${theme.palette.text.secondary}`,
    '&:first-child': {
      paddingLeft: 8, // overwriting dx-react-grid TableHeaderCell-cell:first-child
      borderLeft: `1px solid ${theme.palette.text.secondary}`,
    },
    '&:last-child': {
      paddingRight: 8, // overwriting MuiTableCell-root:last-child
    },
    '&>div>div': {
      justifyContent: 'center',
    },
  },
});

const HeaderCellComponent = withStyles(styles)(({ classes, ...props }) => (
  <TableHeaderRow.Cell {...props} className={classes.headerCell} />
));

export default HeaderCellComponent;
