// adapted from https://github.com/DevExpress/devextreme-reactive/blob/master/packages/dx-react-grid-material-ui/src/templates/table-select-row.jsx
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import {
  darken,
  fade,
  lighten,
} from '@material-ui/core/styles/colorManipulator';
import TableRow from '@material-ui/core/TableRow';
import { withStyles } from '@material-ui/core/styles';

const getSelectionColor = theme =>
  theme.palette.type === 'light'
    ? lighten(fade(theme.palette.action.selected, 1), 0.96)
    : darken(fade(theme.palette.action.selected, 1), 0.68);

const styles = theme => ({
  selected: {
    backgroundColor: getSelectionColor(theme),
  },
});

const TableSelectRowBase = ({
  children,
  classes,
  className,
  onToggle,
  row,
  selectByRowClick,
  selected,
  tableColumn,
  tableRow,
  ...restProps
}) => (
  <TableRow
    className={classnames({ [classes.selected]: selected }, className)}
    onClick={e => {
      if (!selectByRowClick) return;
      e.stopPropagation();
      onToggle();
    }}
    {...restProps}
  >
    {children}
  </TableRow>
);

TableSelectRowBase.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  onToggle: PropTypes.func,
  row: PropTypes.any,
  selectByRowClick: PropTypes.bool,
  selected: PropTypes.bool,
  tableColumn: PropTypes.object,
  tableRow: PropTypes.object,
};

TableSelectRowBase.defaultProps = {
  children: undefined,
  className: undefined,
  onToggle: () => {},
  row: undefined,
  selectByRowClick: false,
  selected: false,
  tableColumn: undefined,
  tableRow: undefined,
};

export const TableSelectRow = withStyles(styles, { name: 'TableSelectRow' })(
  TableSelectRowBase
);
