export default {
  'admin/groups/': {
    name: 'Quản lý nhóm quyền',
  },
  'admin/users/': {
    name: 'Quản lý người dùng',
    fields: {
      id_sso: 'SSO ID',
      name: 'Họ tên',
      title: 'Chức vụ',
      department: 'Bộ phận',
    },
  },
  // 'payables/transactions/type-money': {
  //   name: 'Chi tiết nợ tiền',
  // },
  'receivables/banking/accounts': {
    name: 'Danh sách tài khoản',
    fields: {
      balance: 'Số dư',
    },
  },
  'receivables/banking/statements': {
    name: 'Danh sách sao kê',
    fields: {
      credit: 'Tiền vào',
      debit: 'Tiền ra',
      reconStatus: 'Tình trạng phân bổ',
      remainingBalance: 'Chưa phân bổ',
    },
  },
  'receivables/banking/reconciliations': {
    name: 'Lịch sử phân bổ',
    fields: {
      reconAmount: 'Tiền phân bổ',
      reconTime: 'Ngày phân bổ',
      reconTimeFrom: 'Ngày phân bổ từ',
      reconTimeTo: 'Ngày phân bổ đến',
      reconUser: 'Người phân bổ',
    },
  },
  'receivables/reports/report-by-order': {
    name: 'Báo cáo theo đơn hàng',
    fields: {
      dueDateFrom: 'Ngày đến hạn từ',
      dueDateTo: 'Ngày đến hạn đến',
      grandTotal: 'Tổng tiền đơn',
      paid: 'Đã thanh toán',
      returned: 'Hoàn hàng',
      transferred: 'Đã cấn trừ',
    },
  },
  'receivables/reports/report-by-object': {
    name: 'Báo cáo theo đối tượng',
    fields: {
      debtExcess: 'Nợ vượt mức',
      goodsRec: 'Tổng nợ hàng',
      moneyRec: 'Tổng nợ tiền',
      overdueGoodsRec: 'Nợ hàng q.hạn',
      overdueMoneyRec: 'Nợ tiền q.hạn',
      province: 'Tỉnh thành',
      region: 'Khu vực',
    },
  },
  'receivables/transactions/type-goods': {
    name: 'Chi tiết nợ hàng',
    fields: {
      transportId: 'Mã chặng vận chuyển',
      voucherCode: 'Mã y/c vận chuyển',
      voucherId: 'Mã vận đơn',
      voucherType: 'Loại lộ trình',
    },
  },
  'receivables/transactions/type-money': {
    name: 'Chi tiết nợ tiền',
    fields: {
      originalBill: 'Hóa đơn gốc',
    },
  },
  'receivables/refundreq': {
    name: 'Quản lý yêu cầu chi',
    fields: {
      opsId: 'Mã thu ngân',
      opsName: 'Tên thu ngân',
      refundMethod: 'Phương thức yêu cầu',
      reqCode: 'Mã yêu cầu',
    },
  },
  'receivables/objects': {
    name: 'Quản lý đối tượng nợ',
  },
};
