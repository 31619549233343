import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { BulkActionsToolbar, Button as RaButton } from 'react-admin';
import { connect } from 'react-redux';
import FilterIcon from '@material-ui/icons/FilterList';
import MuiToolbar from '@material-ui/core/Toolbar';
import RefreshIcon from '@material-ui/icons/Refresh';
import { refreshView as refreshViewAction } from 'react-admin';
import { withStyles } from '@material-ui/core/styles';

export const styles = theme => ({
  filtersHiddenIcon: {
    transition: theme.transitions.create(['transform'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    transform: 'rotate(0deg)',
  },
  filtersShownIcon: {
    transition: theme.transitions.create(['transform'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    transform: 'rotate(180deg)',
  },
  toolbar: {
    justifyContent: 'flex-end',
  },
  toolbarWrapper: {
    position: 'relative',
    // uncomment the below if want a sticky actionToolbar
    // position: 'sticky',
    // top: theme.appBar.height,
    // zIndex: 1,
    // backgroundColor: 'white',
  },
});

class ListToolbar extends Component {
  state = {
    showFilters: true,
    // (this.props.filterValues &&
    //   Object.keys(this.props.filterValues).length !== 0) ||
    // (this.props.permanentFilter &&
    //   Object.keys(this.props.permanentFilter).length !== 0),
  };

  toggleFiltersVisibility = () => {
    this.setState(state => ({
      showFilters: !state.showFilters,
    }));
  };

  render() {
    const { showFilters } = this.state;
    const {
      bulkActionButtons,
      classes,
      filters,
      filterValues, // dynamically set via the UI by the user
      permanentFilter, // set in the List component by the developer
      actions,
      exporter,
      isLoading,
      refreshView,
      ...rest
    } = this.props;

    return (
      <Fragment>
        {filters &&
          (showFilters || filters.props.context === 'quick-search') &&
          React.cloneElement(filters, {
            ...rest,
            filterValues,
            // context: showFilters ? 'full-search' : 'quick-search',
          })}

        {/* Because BulkActionsToolbar is absolutely positioned, it needs a
        positioned parent element (here's a relative div) or else it will
        "fly" off the roof aka absolutely-positioned wrt the <body> elem */}
        <div className={classes.toolbarWrapper}>
          {bulkActionButtons !== false && bulkActionButtons && (
            <BulkActionsToolbar {...rest}>
              {bulkActionButtons}
            </BulkActionsToolbar>
          )}
          <MuiToolbar className={classes.toolbar}>
            {filters && filters.props.context !== 'quick-search' && (
              <RaButton
                onClick={this.toggleFiltersVisibility}
                label={
                  showFilters ? 'ui.action.closeFilter' : 'ui.action.openFilter'
                }
              >
                <FilterIcon
                  classes={{
                    root: showFilters
                      ? classes.filtersShownIcon
                      : classes.filtersHiddenIcon,
                  }}
                />
              </RaButton>
            )}
            <RaButton
              onClick={refreshView}
              disabled={isLoading}
              label="Refresh"
            >
              <RefreshIcon />
            </RaButton>
            {actions &&
              React.cloneElement(actions, {
                ...rest,
                className: classes.actions,
                exporter,
                filters,
                filterValues,
                permanentFilter,
                ...actions.props,
                isLoading,
              })}
          </MuiToolbar>
        </div>
      </Fragment>
    );
  }
}

ListToolbar.propTypes = {
  classes: PropTypes.object,
  filters: PropTypes.element,
  permanentFilter: PropTypes.object,
  actions: PropTypes.element,
  exporter: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
};

ListToolbar.defaultProps = {
  classes: {},
};

export default connect(
  undefined,
  { refreshView: refreshViewAction }
)(withStyles(styles)(ListToolbar));
