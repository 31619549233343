import React, { useMemo } from 'react';
import ReactSelect from 'react-select';
import { withStyles } from '@material-ui/core/styles';

import { updateSeller, useSellerDispatch, useSellerState } from 'src/contexts';

const styles = theme => ({
  sellerSelect: {
    marginLeft: '1rem',
    flex: 1,
    color: 'initial',
  },
});

const SellerSelect = withStyles(styles)(({ classes }) => {
  const sellerDispatch = useSellerDispatch();
  const { seller, sellers, locked } = useSellerState();
  const sellerOptions = useMemo(() => getOptionsFromEnum(sellers), [sellers]);
  return (
    <ReactSelect
      options={sellerOptions}
      value={getSelectedOption(seller, sellerOptions)}
      onChange={selected =>
        updateSeller(sellerDispatch, getSelectedEnum(selected, sellers))
      }
      className={classes.sellerSelect}
      placeholder="Chọn..."
      isDisabled={locked}
    />
  );
});

export default SellerSelect;

function getOptionsFromEnum(choices) {
  return (choices || []).map(c => ({
    label: `${c.id} - ${c.name}`,
    value: c.id.toString(),
  }));
}
function getSelectedOption(selectedChoice, options) {
  if (!selectedChoice) return null;
  const o = options.find(o => selectedChoice.id.toString() === o.value);
  if (o) return o;
}
function getSelectedEnum(selectedOption, choices) {
  if (!selectedOption) return null;
  const c = choices.find(c => selectedOption.value === c.id.toString());
  if (c) return c;
}
