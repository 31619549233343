import React from 'react';
import classnames from 'classnames';
import { Datagrid as Unstyled } from 'react-admin';
import { withStyles } from '@material-ui/core/styles';

import { listStyles as styles } from 'src/utils/styles';

const Datagrid = withStyles(styles)(
  ({ classes, headerRowClassName, ...props }) => (
    <Unstyled
      classes={{
        headerCell: classes.headerCell,
        headerRow: classnames(classes.headerRow, headerRowClassName),
        row: classes.row,
        rowCell: classes.rowCell,
        rowEven: classes.rowEven,
      }}
      hover={false}
      {...props}
    />
  )
);

export default Datagrid;
