import React from 'react';

import DatesAwareExportButton from './dates-aware';
import RegularExportButton from './regular';

const ExportButton = ({ checkExportDates, ...props }) =>
  checkExportDates ? (
    <DatesAwareExportButton checkExportDates {...props} />
  ) : (
    <RegularExportButton {...props} />
  );

export default ExportButton;
