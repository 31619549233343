import { useEffect, useState } from 'react';

import { AREA_URL } from 'src/utils/constants';
import fetchNormal from 'src/data-provider/fetch-normal';
import { getErrorMsgToDisplay } from 'src/utils/helpers';

const ProvinceInputController = ({ render }) => {
  const [choices, setChoices] = useState([]);
  const [errorInfo, setErrorInfo] = useState(null);

  useEffect(() => {
    const abortController = new AbortController();
    const options = { signal: abortController.signal };
    const url = AREA_URL;
    const callbacks = {
      success: payload => {
        const areas = payload.data.area.children;
        const provinceChoices = parseAreasToProvinceChoices(areas);
        setChoices(provinceChoices);
      },
      error: error =>
        setErrorInfo({
          error: true,
          helperText: getErrorMsgToDisplay(error),
        }),
    };
    fetchNormal(url, options, callbacks);
    return () => abortController.abort();
  }, []);

  return render(choices, errorInfo);
};

export default ProvinceInputController;

const parseAreasToProvinceChoices = areas =>
  areas.map(entry => {
    let province = entry.name;
    // exclude the prefix 'Thành phố' or 'Tỉnh'
    if (province.startsWith('Thành phố')) {
      province = province.split('Thành phố ').pop();
    } else if (province.startsWith('Tỉnh')) {
      province = province.split('Tỉnh ').pop();
    }

    const region =
      entry.region === 'northern'
        ? 'MB'
        : entry.region === 'central'
        ? 'MT'
        : entry.region === 'southern'
        ? 'MN'
        : '';

    return { id: province, name: province, region };
  });
