import { format } from 'src/components/inputs/datetime-input/helpers';

export const MAX_DATE_RANGE_INCLUSIVE = 31;

export const today = new Date();

/**
 * Credit: https://stackoverflow.com/a/1296374;
 */
export const aMonthAgo = new Date(
  new Date().setDate(new Date().getDate() - MAX_DATE_RANGE_INCLUSIVE + 1)
);

/**
 * Returns a date that is (MAX_DATE_RANGE_INCLUSIVE-1) days before the given date
 *
 * @param {Date} d
 */
export const getAMonthBeforeThisDate = d => {
  if (!(d instanceof Date) || isNaN(d)) d = new Date(d);
  const timeDiff = (MAX_DATE_RANGE_INCLUSIVE - 1) * (1000 * 60 * 60 * 24); // in milliseconds
  return new Date(new Date().setTime(d - timeDiff));
};

/**
 * Generates a date string in the format YYYY-MM-DD
 *
 * @param {Date} d the Date object to be formatted
 * @returns {String} the formatted string
 */
export const getDateString = d => {
  if (!(d instanceof Date) || isNaN(d)) return;
  return d.toISOString().split('T')[0];
};

/**
 * Calculates the difference in days between two dates
 *
 * @param {Date} d1
 * @param {Date} d2
 */
export const calculateDayDiff = (d1, d2) => {
  if (!(d1 instanceof Date) || isNaN(d1)) d1 = new Date(d1);
  if (!(d2 instanceof Date) || isNaN(d2)) d2 = new Date(d2);
  const timeDiff = Math.abs(d1 - d2); // in milliseconds
  const dayDiff = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));
  return dayDiff;
};

/**
 * Generates a datetime string to append to a downloaded filename
 *
 * @returns {String} a string in the format YYYYMMDD_HHmmss
 */
export const datetimeFilename = () => {
  const d = new Date();
  const YYYY = d.getFullYear();
  const MM = `${d.getMonth() + 1}`.padStart(2, '0');
  const DD = `${d.getDate()}`.padStart(2, '0');
  const HH = `${d.getHours()}`.padStart(2, '0');
  const mm = `${d.getMinutes()}`.padStart(2, '0');
  const ss = `${d.getSeconds()}`.padStart(2, '0');
  return `${YYYY}${MM}${DD}_${HH}${mm}${ss}`;
};

/**
 * Formats datetime for Excel file uploads
 *
 * @param {Date|String} param the value to be formatted
 * @returns {String} a string in the format yyyy-MM-ddThh:mm:ss
 */
export const formatExcelDatetime = param => {
  const MILLISECONDS_PER_HOUR = 60 * 60 * 1000;
  if (typeof param === 'string') {
    param = param.trim();
    let value = param;
    // without time
    if (param.length === 10) {
      value = new Date(value).getTime() - 7 * MILLISECONDS_PER_HOUR;
    }
    return format(value);
  } else {
    param.setSeconds(param.getSeconds() + 4); // to normalize the difference when XLSX reads in Excel date value
    return format(param);
  }
};
