import has from 'lodash.has';

import { MAX_DATE_RANGE_INCLUSIVE, calculateDayDiff } from 'src/utils/helpers';

const MAX_DAY_DIFF = MAX_DATE_RANGE_INCLUSIVE - 1;

export const hasDateFrom = filters => has(filters, 'transDateFrom');
export const hasDateTo = filters => has(filters, 'transDateTo');

export const hasFiltersOtherThanDates = filters =>
  Object.keys(filters).filter(k => k !== 'transDateFrom' && k !== 'transDateTo')
    .length > 0;

export const hasValidDateRange = filters => {
  if (hasDateFrom(filters) && hasDateTo(filters)) {
    const { transDateFrom, transDateTo } = filters;
    const dayDiff = calculateDayDiff(
      new Date(transDateFrom),
      new Date(transDateTo)
    );
    return dayDiff <= MAX_DAY_DIFF;
  } else if (hasDateFrom(filters)) {
    const { transDateFrom } = filters;
    const dayDiff = calculateDayDiff(new Date(transDateFrom), new Date());
    return dayDiff <= MAX_DAY_DIFF;
  }
  return false;
};
