import isEqual from 'lodash.isequal';

// the default params object from the Redux store
// see here: https://github.com/marmelab/react-admin/blob/master/packages/ra-core/src/controller/ListController.tsx#L253
export const defaultParams = {
  filter: {},
  order: null,
  page: 1,
  perPage: null,
  sort: null,
};

export default (controllerProps, props) => {
  return (
    filterDiffer(controllerProps, props) ||
    sortDiffer(controllerProps, props) ||
    pageDiffer(controllerProps, props) ||
    perPageDiffer(controllerProps, props)
  );
  // help debugging
  // if (filterDiffer(controllerProps, props)) {
  //   console.log('filter diff');
  //   return true;
  // }
  // if (sortDiffer(controllerProps, props)) {
  //   console.log('sort diff');
  //   return true;
  // }
  // if (pageDiffer(controllerProps, props)) {
  //   console.log('page diff');
  //   return true;
  // }
  // if (perPageDiffer(controllerProps, props)) {
  //   console.log('perPage diff');
  //   return true;
  // }
  // return false;
};

const filterDiffer = (controllerProps, props) => {
  if (props.filterDefaultValues)
    return !isEqual(controllerProps.filterValues, props.filterDefaultValues);
  else return !isEqual(controllerProps.filterValues, defaultParams.filter);
};

const sortDiffer = (controllerProps, props) => {
  if (props.sort) return !isEqual(controllerProps.currentSort, props.sort);
  else
    return !isEqual(controllerProps.currentSort, {
      field: 'id',
      order: 'DESC',
    });
};

const pageDiffer = (controllerProps, props) => {
  if (props.page) return !isEqual(controllerProps.page, props.page);
  else return !isEqual(controllerProps.page, defaultParams.page);
};

const perPageDiffer = (controllerProps, props) => {
  if (props.perPage) return !isEqual(controllerProps.perPage, props.perPage);
  else return !isEqual(controllerProps.perPage, 20);
};
