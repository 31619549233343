import React from 'react';
import { Route } from 'react-router-dom';

import Dashboard from 'src/pages/dashboard';
import { useBranchInvoicePermissions } from 'src/pages/branch-invoices/auth-helpers';
import { useFmRolePermissions } from 'src/utils/helpers';
import { useServiceInvoicePermissions } from 'src/pages/service-invoices/auth-helpers';

import adminRoutes from './admin';
import branchInvoicesRoutes from './branch-invoices';
import payablesRoutes from './payables';
import receivablesRoutes from './receivables';
import serviceInvoicesRoutes from './service-invoices';

const allRoutes = [
  <Route key="dashboard" exact path="/dashboard" component={Dashboard} />,
  ...payablesRoutes,
  ...receivablesRoutes,
];

export const useCustomRoutes = () => {
  const routes = allRoutes;

  const { canAccessAdmin } = useFmRolePermissions();
  if (canAccessAdmin) {
    routes.push(...adminRoutes);
  }

  const { canAccessServiceInvoice } = useServiceInvoicePermissions();
  if (canAccessServiceInvoice) {
    routes.push(...serviceInvoicesRoutes);
  }

  const { canAccessBranchInvoice } = useBranchInvoicePermissions();
  if (canAccessBranchInvoice) {
    routes.push(...branchInvoicesRoutes);
  }

  return routes;
};
