import React, { Fragment } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import classnames from 'classnames';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { translate } from 'react-admin';
import { withStyles } from '@material-ui/core/styles';

const styles = {
  loader: {
    margin: '100px 188px', // to make this Modal (almost) match the size of the UploadModal
  },
};

const ProcessingModal = ({ classes, className, translate }) => (
  <Fragment>
    <DialogTitle id="processing-dialog-title">
      {translate('ui.status.processing')}
    </DialogTitle>
    <DialogContent>
      <CircularProgress
        className={classnames(classes.loader, className)}
        size={50}
        thickness={5}
      />
    </DialogContent>
  </Fragment>
);

export default translate(withStyles(styles)(ProcessingModal));
