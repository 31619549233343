export default {
  action: {
    activate: 'Kích hoạt',
    addAccount: 'Thêm tài khoản',
    addAll: 'Thêm tất cả',
    addGroup: 'Thêm nhóm quyền',
    addUser: 'Thêm người dùng',
    adjust: 'Điều chỉnh',
    adjustDebtInfo: 'Điều chỉnh hạn nợ',
    allocateAll: 'Phân bổ hết',
    approve: 'Duyệt',
    backToFilter: 'Trở về để filter',
    block: 'Khóa',
    cancel: 'Hủy', // shorter than ra.action.cancel: Hủy bỏ
    cancelReq: 'Hủy yêu cầu',
    cancelStatement: 'Hủy sao kê',
    check: 'Kiểm tra',
    close: 'Đóng',
    closeFilter: 'Đóng filter',
    deleteAll: 'Xóa tất cả',
    done: 'Hoàn tất',
    download: 'Tải xuống',
    downloadAll: 'Tải tất cả',
    downloadAllUpTo: 'Tải tất cả cho đến %{date}',
    exportReport: 'Xuất báo cáo',
    exportToExcel: 'Tải Excel',
    openFilter: 'Mở filter',
    pickDatesToDownload: 'Chọn ngày để tải',
    processReq: 'Xử lý yêu cầu',
    reconcile: 'Phân bổ',
    reject: 'Từ chối',
    remindByApp: 'Nhắc nợ app',
    remindByEmail: 'Nhắc nợ email',
    retry: 'Thử lại',
    reupload: 'Tải lại',
    revoke: 'Thu hồi',
    syncRole: 'Đồng bộ role',
    undone: 'Bỏ hoàn tất',
    updateAccount: 'Cập nhật thông tin tài khoản',
    updateStatement: 'Cập nhật thông tin sao kê',
    updateStatus: 'Cập nhật trạng thái',
    upload: 'Tải lên',
    uploadDocs: 'Tải chứng từ',
    uploadRecon: 'Upload phân bổ',
    uploadStatement: 'Upload sao kê',
    viewDebts: 'Xem nợ',
  },
  count: {
    day: '%{smart_count} ngày',
  },
  heading: {
    admin: 'Phân quyền',
    branchInvoice: 'Hóa đơn xuất cho chi nhánh',
    branchInvoiceList: 'Danh sách hóa đơn xuất cho chi nhánh',
    customerInfo: 'Thông tin khách hàng',
    dashboard: 'Tổng quan',
    documents: 'Bộ chứng từ',
    generalInfo: 'Thông tin chung',
    invalidRecords: 'Những bản ghi không hợp lệ',
    invIssueInfo: 'Thông tin xuất hóa đơn',
    myRequests: 'Yêu cầu của tôi',
    objectInfo: 'Thông tin đối tượng',
    payables: 'Công nợ phải trả',
    pendingStatements: 'Sao kê chờ duyệt',
    productInfo: 'Thông tin sản phẩm',
    recAccount: 'Tài khoản công nợ',
    receivables: 'Công nợ phải thu',
    reconInfo: 'Thông tin phân bổ',
    refundReqDetail: 'Chi tiết yêu cầu chi',
    reqInfo: 'Thông tin yêu cầu',
    serviceInvoice: 'Hóa đơn dịch vụ',
    serviceInvoiceList: 'Danh sách hóa đơn dịch vụ',
    statusInfo: 'Thông tin trạng thái',
    total: 'Tổng',
    updateResult: 'Kết quả cập nhật',
    paymentInfo: 'Thông tin thanh toán',
  },
  message: {
    bulkActFailure: 'Không thể %{action} %{smart_count} %{name}. %{reason}',
    bulkActSuccess: 'Đã %{action} thành công %{smart_count} %{name}',
    confirmTransDateFilter: 'Xác nhận giá trị filter ngày hạch toán',
    // couldNotCreate: 'Đã xảy ra lỗi. Tạo mới thất bại.',
    // couldNotUpdate: 'Đã xảy ra lỗi. Không thể cập nhật.',
    csvOnly: 'Chỉ chấp nhận file CSV',
    // csvOrExcelOnly: 'Chỉ chấp nhận file CSV hoặc Excel',
    dataError: 'Dữ liệu không hợp lệ. Vui lòng kiểm tra các lỗi sau',
    docsUploaded: 'Tải lên thành công %{smart_count} chứng từ',
    excelOnly: 'Chỉ chấp nhận file Excel',
    maxDateRange:
      'Khuyến cáo chọn tối đa 31 ngày để tránh trường hợp quá nhiều dữ liệu, file tải chậm',
    minFile: 'Phải ít nhất %{min} file hợp lệ',
    missingDateFrom: 'Thiếu ngày từ!',
    missingDates: 'Bạn chưa nhập ngày!',
    noChanges: 'Không có thay đổi mới để lưu',
    noDocsYet: 'Chưa có chứng từ nào',
    pastDue: 'Q.hạn %{smart_count} ngày',
    pdfMax5MbOnly: 'Chỉ chấp nhận file PDF (kích thước tối đa 5MB/file)',
    pleaseConfirm: 'Vui lòng xác nhận',
    toBulkAct:
      'Bạn có chắc chắn muốn %{action} %{name} này? |||| Bạn có chắc chắn muốn %{action} %{smart_count} %{name} này?',
    tooManyRecords: 'Quá nhiều dữ liệu',
    tooManyRecordsAccept: 'Tôi hiểu và vẫn muốn tải xuống',
    tooManyRecordsAdvice:
      'Bạn đang cố gắng tải xuống %{smart_count} dòng. Tốc độ tải sẽ bị ảnh hưởng. Hãy dùng chức năng Filter/Tìm kiếm để lọc bớt số bản ghi.',
    // unsavedChanges: 'Các thay đổi chưa được lưu. Bạn có chắc chắn muốn đóng?',
    updatedAndRefreshing: 'Cập nhật thành công. Đang refresh dữ liệu...',
  },
  status: {
    active: 'Đang hoạt động',
    approved: 'Đã duyệt',
    blocked: 'Bị khóa',
    cancelled: 'Đã hủy',
    done: 'Đã hoàn tất',
    invalid: 'Không hợp lệ',
    pending: 'Chờ duyệt',
    processing: 'Đang xử lý',
    reconDone: 'Đã phân bổ',
    reconPending: 'Chưa phân bổ',
    rejected: 'Đã từ chối',
    syncDone: 'Đã đồng bộ',
    syncPending: 'Chưa đồng bộ',
    valid: 'Hợp lệ',
  },
  validation: {
    atLeastOneNew: 'Ít nhất một trường phải có giá trị mới',
    atLeastOneNonEmpty: 'Ít nhất một trường phải có giá trị',
    greaterThanZero: 'Phải lớn hơn 0',
    integer: 'Phải là số nguyên',
    noFuture: 'Không được chọn ngày tương lai',
    upToBalance: 'Phải <= số dư chưa phân bổ của sao kê',
    upToRemain: 'Phải <= số còn nợ của đơn',
  },
};
