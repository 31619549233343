import React from 'react';
import { Route, Switch } from 'react-router-dom';

import AdmMenu from './menus/adm-menu';
import PayMenu from './menus/pay-menu';
import RecMenu from './menus/rec-menu';

const MenuSwitch = () => (
  <Switch>
    <Route path="/admin" component={AdmMenu} />
    <Route path="/payables" component={PayMenu} />
    <Route path="/receivables" component={RecMenu} />
  </Switch>
);

export default MenuSwitch;
