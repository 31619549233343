import React from 'react';

import { ACTIVE, BLOCKED } from 'src/utils/constants';
import BaseChip from './base-chip';

const colorDict = {
  [ACTIVE]: 'green',
  [BLOCKED]: 'red',
};

const BankAccountStatusChip = ({ status }) => {
  const key = (status && status.id) || status;
  const value = (status && status.name) || status;
  if (!value) return null;
  return <BaseChip color={colorDict[key]} label={value} />;
};

export default BankAccountStatusChip;
