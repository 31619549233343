import React from 'react';
import PropTypes from 'prop-types';
import MuiTable from '@material-ui/core/Table';
import MuiTableBody from '@material-ui/core/TableBody';
import MuiTableCell from '@material-ui/core/TableCell';
import MuiTableHead from '@material-ui/core/TableHead';
import MuiTableRow from '@material-ui/core/TableRow';
import { translate } from 'react-admin';
import { withStyles } from '@material-ui/core/styles';

const BaseInvalidTable = ({
  colHeadNames,
  content,
  records = [],
  translate,
}) => (
  <MuiTable>
    <MuiTableHead>
      <CustomTableRow>
        <CustomTableCell>{translate('common.row')}</CustomTableCell>
        {colHeadNames.map(name => (
          <CustomTableCell key={name}>{translate(name)}</CustomTableCell>
        ))}
        <CustomTableCell>{translate('common.error')}</CustomTableCell>
      </CustomTableRow>
    </MuiTableHead>

    <MuiTableBody>
      {records.map(record => (
        <CustomTableRow key={record.rowId}>
          <CustomTableCell component="th" scope="row">
            {record.rowId}
          </CustomTableCell>
          {content(record)}
          <CustomTableCell>{record.msg}</CustomTableCell>
        </CustomTableRow>
      ))}
    </MuiTableBody>
  </MuiTable>
);

BaseInvalidTable.propTypes = {
  colHeadNames: PropTypes.arrayOf(PropTypes.string).isRequired,
  content: PropTypes.func,
  records: PropTypes.arrayOf(PropTypes.object),
  translate: PropTypes.func.isRequired,
};

export default translate(BaseInvalidTable);

export const CustomTableCell = withStyles(theme => ({
  root: {
    padding: '0 24px',
    textAlign: 'center',
  },
  head: {
    backgroundColor: 'white',
    color: theme.palette.grey[700],
    fontStyle: 'italic',
    position: 'sticky',
    top: 0,
  },
}))(MuiTableCell);

export const CustomTableRow = withStyles(theme => ({
  root: {
    height: theme.tableRow.height,
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.grey[100],
    },
  },
}))(MuiTableRow);
