import React from 'react';
import { connect } from 'react-redux';
import DialogActions from '@material-ui/core/DialogActions';
import MuiToolbar from '@material-ui/core/Toolbar';

import { ButtonWithLoading, CustomButton } from 'src/components';

const ExportModalToolbar = ({
  isLoading,
  handleConfirm,
  handleDialogClose,
}) => (
  <DialogActions>
    <MuiToolbar>
      <CustomButton
        aria-label="Cancel"
        label="ra.action.cancel"
        onClick={handleDialogClose}
        withSideSpacing
      />
      <ButtonWithLoading
        aria-label="Download"
        label="ui.action.download"
        onClick={handleConfirm}
        processing={isLoading}
        color="primary"
        variant="raised"
        withSideSpacing
      />
    </MuiToolbar>
  </DialogActions>
);

const mapStateToProps = state => ({
  isLoading: state.admin.loading > 0,
});

export default connect(
  mapStateToProps,
  {}
)(ExportModalToolbar);
