// adapted from: https://github.com/marmelab/react-admin/blob/master/examples/demo/src/layout/SubMenu.js
import React, { Fragment } from 'react';
import { compose } from 'recompose';

import Collapse from '@material-ui/core/Collapse';
import Divider from '@material-ui/core/Divider';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { withStyles } from '@material-ui/core/styles';

const styles = {
  listItem: {
    boxSizing: 'content-box',
    height: '24px',
    padding: '0.5rem 0.75rem',
  },
  listItemIcon: {
    marginRight: '0.75rem',
  },
  listItemText: {
    paddingLeft: 0,
    fontSize: '0.9rem',
  },
  sidebarIsOpen: {
    paddingLeft: '1rem',
    transition: 'padding-left l95px cubic-bezier(0.4, 0, 0.6, 1) 0ms',
  },
  sidebarIsClosed: {
    paddingLeft: 0,
    transition: 'padding-left l95px cubic-bezier(0.4, 0, 0.6, 1) 0ms',
  },
};

const SubMenu = ({
  classes,
  children,
  handleToggle,
  icon,
  isOpen,
  name,
  sidebarIsOpen,
}) => (
  <Fragment>
    <ListItem button className={classes.listItem} dense onClick={handleToggle}>
      <ListItemIcon classes={{ root: classes.listItemIcon }}>
        {isOpen && sidebarIsOpen ? <ExpandMoreIcon /> : icon}
      </ListItemIcon>
      <ListItemText
        className={classes.listItemText}
        primary={isOpen ? name : ''}
        secondary={isOpen ? '' : name}
      />
    </ListItem>
    <Collapse in={isOpen && sidebarIsOpen} timeout="auto" unmountOnExit>
      <List
        className={
          sidebarIsOpen ? classes.sidebarIsOpen : classes.sidebarIsClosed
        }
        component="div"
        dense
        disablePadding
      >
        {children}
      </List>
      <Divider />
    </Collapse>
  </Fragment>
);

const enhance = compose(withStyles(styles));

export default enhance(SubMenu);
