import admResources from './adm';
import bankResources from './bank';
import objResources from './obj';
import recResources from './rec';
import { useFmRolePermissions } from 'src/utils/helpers';

export const useCustomResources = () => {
  const resources = [];

  const {
    canAccessAdmin,
    canAccessBanking,
    canAccessReports,
    canAccessObjects,
  } = useFmRolePermissions();

  if (canAccessReports) {
    resources.push(...recResources);
  }
  if (canAccessObjects) {
    resources.push(...objResources);
  }
  if (canAccessBanking) {
    resources.push(...bankResources);
  }
  if (canAccessAdmin) {
    resources.push(...admResources);
  }

  return resources;
};
