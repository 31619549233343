import { HttpError } from 'react-admin';

export const fetchJson = (url, options) => {
  return fetch(url, options)
    .then(response =>
      response.text().then(text => ({
        text,
        statusCode: response.status,
        statusText: response.statusText,
        // headers: response.headers,
      }))
    )
    .then(({ text, statusCode, statusText }) => {
      let json;
      try {
        json = JSON.parse(text);
      } catch (e) {
        // not json
        return Promise.reject(new HttpError(statusText, statusCode));
      }
      if (statusCode < 200 || statusCode >= 300) {
        const message = json.message || (json.error && json.error.message);
        const code = json.code || (json.error && json.error.code) || statusCode;
        return Promise.reject(new HttpError(message, code, json));
      } else {
        return Promise.resolve(json);
      }
    });
};

export default (url, clientOpts = {}, callbacks) => {
  const { TekoID } = window;
  const BEARER_TOKEN = `Bearer ${TekoID.user.getAccessToken()}`;
  const requestHeaders =
    clientOpts.headers || new Headers({ Authorization: BEARER_TOKEN });
  if (!requestHeaders.has('Authorization')) {
    requestHeaders.set('Authorization', BEARER_TOKEN);
  }
  if (
    // method != GET && body != FormData
    !requestHeaders.has('Content-Type') &&
    !(clientOpts && (!clientOpts.method || clientOpts.method === 'GET')) &&
    !(clientOpts && clientOpts.body && clientOpts.body instanceof FormData)
  ) {
    requestHeaders.set('Content-Type', 'application/json');
  }

  const options = { ...clientOpts, headers: requestHeaders };

  const successCallback =
    callbacks && callbacks.success && typeof callbacks.success === 'function'
      ? callbacks.success
      : false;

  const errorCallback =
    callbacks && callbacks.error && typeof callbacks.error === 'function'
      ? callbacks.error
      : false;

  return fetchJson(url, options)
    .then(json => {
      if (successCallback) return successCallback(json);
      else return Promise.resolve(json);
    })
    .catch(error => {
      if (errorCallback) return errorCallback(error);
      else return Promise.reject(error);
    });
};
