import React from 'react';
import { Resource } from 'react-admin';

const AdmGroups = React.lazy(() =>
  import(/* webpackChunkName: 'AdmGroups' */ 'src/pages/admin/groups')
);
const AdmUser = React.lazy(() =>
  import(/* webpackChunkName: 'AdmUser' */ 'src/pages/admin/user')
);
const AdmUsers = React.lazy(() =>
  import(/* webpackChunkName: 'AdmUsers' */ 'src/pages/admin/users')
);

export default [
  <Resource key="admUsers" name="admin/users/" list={AdmUsers} />,
  <Resource key="admUser" name="admin/user/" edit={AdmUser} />,
  <Resource key="admGroups" name="admin/groups/" list={AdmGroups} />,
];
