// import { fade } from '@material-ui/core/styles/colorManipulator';

export default theme => ({
  row: {
    height: theme.tableRow.height,
    // disable hover to make table-scanning less flickering
    // '&:hover': {
    //   backgroundColor: fade(theme.palette.primary.main, 0.16),
    // },
    // to be able to style the checkboxes, we resort to
    // nested elem "td" inside "row" like this, instead of
    // a separate entry for "rowCell" -- even though this
    // compromises readability :(
    '&>td': {
      // borderRight: `1px solid ${theme.palette.primary.dark}`,
      // borderBottom: `1px solid ${theme.palette.primary.dark}`,
      // '&:nth-of-type(1)': {
      //   borderLeft: `1px solid ${theme.palette.primary.dark}`,
      // },
      borderRight: `1px solid ${theme.palette.text.secondary}`,
      borderBottom: `1px solid ${theme.palette.text.secondary}`,
      '&:nth-of-type(1)': {
        borderLeft: `1px solid ${theme.palette.text.secondary}`,
        textAlign: 'center', // this fixes alignment for checkbox cells and doesn't significantly affect no-checkbox tables
      },
      '&>span': {
        fontSize: theme.tableCell.fontSize,
      },
    },
  },
  rowEven: {
    // backgroundColor: fade(theme.palette.primary.main, 0.08),
    backgroundColor: theme.palette.grey[100],
  },
  headerRow: {
    // to be able to style the checkboxes, we resort to
    // nested elem "th" inside "headerRow" like this, instead of
    // a separate entry for "headerCell" -- even though this
    // compromises readability :(
    '&>th': {
      backgroundColor: 'white', // or else it will be transparent - seen thru when scrolling down
      // borderBottom: `1px solid ${theme.palette.primary.dark}`,
      // borderRight: `1px solid ${theme.palette.primary.dark}`,
      // '&:nth-of-type(1)': {
      //   borderLeft: `1px solid ${theme.palette.primary.dark}`,
      // },
      paddingBottom: 6,
      paddingTop: 6,
      textAlign: 'center', // this fixes alignment checkbox cells and doesn't significantly affect no-checkbox tables
      position: 'sticky', // make the header fixed-in-place with `position` and `top`
      top: 0,
      zIndex: 2, // to ensure it stacks on top
      // keep the border around the header row when scrolling down
      // credit: https://stackoverflow.com/a/50362884
      '&:before': {
        content: '""',
        position: 'absolute',
        left: 0,
        width: '100%',
        height: '100%',
        // the borders' color below, if not set, will be 'initial'
        // just to be safe, set them to match the td's border color
        borderBottom: `3px solid ${theme.palette.text.secondary}`,
        borderTop: `1px solid ${theme.palette.text.secondary}`,
        borderLeft: `1px solid ${theme.palette.text.secondary}`,
        borderRight: `1px solid ${theme.palette.text.secondary}`,
        // display: 'block',
        top: 0,
      },
    },
  },
  headerCell: {
    // have to separate these from the 'th' above bcz
    // they may undesirably affect the checbox's styling
    color: theme.palette.grey[600], // text.secondary is too light, so we choose grey[600]
    fontSize: '0.8125rem',
    padding: `0 ${theme.spacing.unit}px`,
  },
  rowCell: {
    // have to separate these from the 'tr' above bcz
    // they may undesirably affect the checbox's styling
    padding: `0 ${theme.spacing.unit}px`,
  },
  longTextField: {
    minWidth: '150px',
  },
  booleanField: {
    width: '60px',
  },
});
