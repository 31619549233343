import React from 'react';
import { VirtualTable } from '@devexpress/dx-react-grid-material-ui';
import { withStyles } from '@material-ui/core/styles';

export const styles = theme => ({
  cell: {
    borderRight: `1px solid ${theme.palette.text.secondary}`,
    borderBottom: `1px solid ${theme.palette.text.secondary}`,
    fontSize: theme.tableCell.fontSize,
    overflowWrap: 'break-word',
    padding: `0 ${theme.spacing.unit}px`, // to be consistent with our Datagrid-rowCell
    '&:first-child': {
      borderLeft: `1px solid ${theme.palette.text.secondary}`,
      paddingLeft: theme.spacing.unit, // overwriting dx-react-grid TableCell-cell:first-child
    },
    '&:last-child': {
      paddingRight: theme.spacing.unit, // overwriting MuiTableCell-root:last-child
    },
  },
});

const CellComponent = withStyles(styles)(({ classes, ...props }) => (
  <VirtualTable.Cell {...props} className={classes.cell} />
));

export default CellComponent;
