export default theme => ({
  tab: {
    borderRadius: '0 12px 0 0',
    borderRight: `1px solid ${theme.palette.grey[400]}`,
    borderTop: `1px solid ${theme.palette.grey[400]}`,
    '&:first-of-type': {
      borderLeft: `1px solid ${theme.palette.grey[400]}`,
    },
    color: 'black',
    textTransform: 'none',
  },
  selected: {
    color: 'white',
    backgroundColor: theme.palette.primary.main,
    borderColor: theme.palette.primary.main,
    '&:first-of-type': {
      borderColor: theme.palette.primary.main,
    },
  },
});
