// const getExactDebtStatus = (debtStatus, daysPastDue) => {
//   const statusKey =
//     typeof debtStatus === 'object'
//       ? debtStatus.id
//       : typeof debtStatus === 'string'
//       ? debtStatus
//       : '';
//   if (!statusKey) throw new Error('Missing debtStatus key');
//   if (statusKey === 'undue') return debtStatus.name || 'Chưa đến hạn';
//   if (statusKey === 'paid') return debtStatus.name || 'Thanh toán đủ';
//   if (statusKey.startsWith('overdue')) return `Quá hạn ${daysPastDue} ngày`;
//   else throw new Error('Unknown debtStatus key');
// };

const getOrderReportTemplate = data => {
  const headers = [
    'Ngày ghi nhận',
    'Mã đơn hàng',
    'Mã phiếu xuất',
    'Ngày phiếu xuất',
    'Số hóa đơn',
    'Tên đối tượng',
    'Mã đối tượng',
    'Mã kế toán',
    'Loại đối tượng',
    'Tên NV bán hàng',
    'Mã NV bán hàng',
    'Mã KT NV bán hàng',
    'Tài khoản',
    'Seller',
    'Chi nhánh',
    'Chi nhánh xuất',
    'Tổng tiền đơn',
    'Tổng nợ',
    'Hoàn hàng',
    'Đã cấn trừ',
    'Đã thanh toán',
    'Còn nợ',
    'Loại nợ',
    'Hạn thanh toán (ngày)',
    'Ngày đến hạn',
    'Tình trạng nợ',
    'Số ngày quá hạn',
  ];

  const dataRows = data.map(r => [
    (r.transDate && new Date(r.transDate)) || null,
    r.orderCode,
    r.exportCode,
    (r.exportTime && new Date(r.exportTime)) || null,
    r.bill,
    r.objectName,
    r.objectId,
    r.objectAccId,
    (r.objectType && r.objectType.name) || null,
    r.salesmanName,
    r.salesmanId,
    r.salesmanAccId,
    r.accAccount,
    r.sellerName,
    r.branch,
    r.exportStore,
    r.grandTotal,
    r.totalDebt,
    r.returned,
    r.transferred,
    r.paid,
    r.remain,
    (r.debtType && r.debtType.name) || null,
    r.paymentTerm,
    (r.dueDate && new Date(r.dueDate)) || null,
    (r.debtStatus && r.debtStatus.name) || null,
    r.daysPastDue,
  ]);

  return { headers, dataRows };
};

const getObjectReportTemplate = data => {
  const headers = [
    'Mã đối tượng',
    'Mã kế toán',
    'Tên đối tượng',
    'Loại đối tượng',
    'Nhóm đối tượng',
    'Tổng nợ hàng',
    'Nợ hàng quá hạn',
    'Tổng nợ tiền',
    'Nợ tiền quá hạn',
    'Hạn mức nợ',
    'Nợ vượt mức',
    'Seller',
    'Chi nhánh',
    'Khu vực',
    'Tỉnh thành',
    'Tình trạng tài khoản',
  ];

  const dataRows = data.map(r => [
    r.objectId,
    r.objectAccId,
    r.objectName,
    (r.objectTypes || []).map(type => type.name).join(', '),
    r.objectGroup,
    r.goodsRec,
    r.overdueGoodsRec,
    r.moneyRec,
    r.overdueMoneyRec,
    r.threshold,
    r.debtExcess,
    r.sellerName,
    r.branch,
    r.region,
    r.province,
    (r.status && r.status.name) || null,
  ]);

  return { headers, dataRows };
};

const getTransactionTypeGoodsTemplate = data => {
  const headers = [
    'Ngày ghi nhận',
    'Tên đối tượng',
    'Mã đối tượng',
    'Mã kế toán',
    'Mã đơn hàng',
    'Mã phiếu xuất',
    'Mã vận đơn',
    'Mã chặng vận chuyển',
    'Loại lộ trình',
    'Ghi nợ',
    'Ghi có',
    'Seller',
    'Chi nhánh',
    'Diễn giải',
  ];

  const dataRows = data.map(r => [
    (r.transDate && new Date(r.transDate)) || null,
    r.objectName,
    r.objectId,
    r.objectAccId,
    r.orderCode,
    r.exportCode,
    r.voucherId,
    r.transportId,
    r.voucherType,
    r.debit,
    r.credit,
    r.sellerName,
    r.branch,
    r.description,
  ]);

  return { headers, dataRows };
};

const getTransactionTypeMoneyTemplate = data => {
  const headers = [
    'Ngày ghi nhận',
    'Tên đối tượng',
    'Mã đối tượng',
    'Mã kế toán',
    'Mã chứng từ',
    'Loại chứng từ',
    'Mã đơn hàng',
    'Mã phiếu xuất',
    'Hóa đơn gốc',
    'Tài khoản',
    'Tài khoản đối ứng',
    'Ghi nợ',
    'Ghi có',
    'Seller',
    'Chi nhánh',
    'Ref',
    'Hình thức thanh toán',
    'Diễn giải',
  ];

  const dataRows = data.map(r => [
    (r.transDate && new Date(r.transDate)) || null,
    r.objectName,
    r.objectId,
    r.objectAccId,
    r.voucherCode,
    r.voucherType,
    r.orderCode,
    r.exportCode,
    r.originalBill,
    r.accAccount,
    r.contraAccount,
    r.debit,
    r.credit,
    r.sellerName,
    r.branch,
    r.ref && r.ref2 ? `${r.ref} - ${r.ref2}` : r.ref,
    r.paymentMethod,
    r.description,
  ]);

  return { headers, dataRows };
};

export const getFileTemplate = (resource, data) => {
  switch (resource) {
    case 'receivables/reports/report-by-order': {
      const { headers, dataRows } = getOrderReportTemplate(data);
      return [headers, ...dataRows];
    }
    case 'receivables/reports/report-by-object': {
      const { headers, dataRows } = getObjectReportTemplate(data);
      return [headers, ...dataRows];
    }
    case 'receivables/transactions/type-goods': {
      const { headers, dataRows } = getTransactionTypeGoodsTemplate(data);
      return [headers, ...dataRows];
    }
    case 'receivables/transactions/type-money': {
      const { headers, dataRows } = getTransactionTypeMoneyTemplate(data);
      return [headers, ...dataRows];
    }
    default:
      return [];
  }
};
