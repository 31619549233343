import { HttpError } from 'react-admin';
import { stringify } from 'query-string';

import { API_URL } from 'src/utils/constants';
import fetchDownload from 'src/data-provider/fetch-download';
import getApiEndpoint from 'src/data-provider/parse-resource';
import parseReqParamsBeforeSend from 'src/data-provider/parse-params';

export const normalDownload = (
  filters,
  resource,
  fetchOptions,
  filename,
  callbacks
) => {
  const endpoint = getApiEndpoint(resource);
  // const { field, order } = currentSort;
  const query = {
    // sort: JSON.stringify([field, order]),
    ...parseReqParamsBeforeSend(filters),
  };
  const url = `${API_URL}/${endpoint}/download?${stringify(query)}`;
  return fetchDownload(url, fetchOptions, filename, callbacks);
};

const fecthList = async (filters, resource, limit, offset, clientOpts) => {
  const { TekoID } = window;
  const BEARER_TOKEN = `Bearer ${TekoID.user.getAccessToken()}`;
  const options = {
    method: 'GET',
    headers: { Authorization: BEARER_TOKEN, Accept: 'application/json' },
    ...clientOpts,
  };

  const endpoint = getApiEndpoint(resource);
  const query = {
    ...parseReqParamsBeforeSend(filters),
    limit,
    offset,
  };
  const url = `${API_URL}/${endpoint}?${stringify(query)}`;

  return fetch(url, options)
    .then(async response => {
      if (response.ok) {
        return await response.json().then(json => ({ result: json }));
      } else {
        return await response.json().then(json => {
          throw new HttpError(
            json.message || response.statusText,
            response.status,
            json
          );
        });
      }
    })
    .catch(error => {
      return { error: error };
    });
};

export const fetchListInfinitely = async (
  filters,
  resource,
  updateProgress,
  clientOpts
) => {
  let fetchError = null;
  let dataFetched = [];
  let totalRows = 0;
  const limit = -1;
  updateProgress(0);
  do {
    const offset = dataFetched.length;
    const { result, error } = await fecthList(
      filters,
      resource,
      limit,
      offset,
      clientOpts
    );
    if (!result) {
      fetchError = error;
      break;
    }
    const { data, pagination } = result;
    if (data) {
      totalRows = pagination.totalRows;
      dataFetched = dataFetched.concat(data);
      const percentComplete = (dataFetched.length * 1.0) / totalRows;
      updateProgress(percentComplete);
    } else {
      fetchError = error;
      break;
    }
  } while (dataFetched.length < totalRows);

  if (fetchError) return { error: fetchError };
  return { data: dataFetched };
};
