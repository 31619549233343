import React, { createElement } from 'react';

import ExportButton from './export-button';

const CustomListActions = ({
  checkExportDates,
  children,
  currentSort,
  disableExport,
  exportList,
  exportName,
  filterValues,
  isLoading,
  isNewExport,
  permanentFilter,
  resource,
  total,
}) => {
  return (
    <>
      {children}
      {exportList &&
        createElement(ExportButton, {
          checkExportDates,
          currentSort,
          disableExport,
          filename: exportName,
          filterValues,
          isLoading,
          isNewExport,
          permanentFilter,
          resource,
          total,
        })}
    </>
  );
};

export default CustomListActions;
