import React from 'react';
import { FormDataConsumer } from 'react-admin';
import { withStyles } from '@material-ui/core/styles';

import CurrencyInput from './currency-input';
import { inputStyles } from 'src/utils/styles';
import OperatorSelectInput, {
  AVAILABLE_OPERATORS as OPS,
} from './operator-select-input';

const styles = theme => ({
  ...inputStyles(theme),
  flexContainer: {
    ...inputStyles(theme).root,
    maxHeight: 'unset',
    backgroundColor: 'unset',
    marginRight: 'unset',
    display: 'flex',
    alignItems: 'center',
  },
  operandField: {
    ...inputStyles(theme).root,
    marginRight: 'unset',
    marginLeft: 'unset',
  },
});

const applicableOperators = [
  OPS.equal,
  OPS.notEqual,
  OPS.greaterThan,
  OPS.greaterThanOrEqual,
  OPS.lessThan,
  OPS.lessThanOrEqual,
];

const NumCompareInput = withStyles(styles)(
  ({ classes, compSrc, label, numSrc }) => (
    <div className={classes.flexContainer}>
      <OperatorSelectInput
        source={compSrc}
        locales="vi"
        operators={applicableOperators}
      />
      <FormDataConsumer>
        {({ formData, ...rest }) => (
          // operator value must exist before user can enter a value for operand
          <CurrencyInput
            disabled={!formData[compSrc]}
            source={numSrc}
            label={label}
            className={classes.operandField}
            InputLabelProps={{
              shrink: true,
              classes: {
                shrink: classes.muiLabelShrink,
              },
              FormLabelClasses: {
                root: classes.muiLabel,
              },
            }}
            InputProps={{
              // disableUnderline: true,
              classes: { root: classes.muiInput },
            }}
            {...rest}
          />
        )}
      </FormDataConsumer>
    </div>
  )
);

export default NumCompareInput;
