import blueGrey from '@material-ui/core/colors/blueGrey';

export default {
  primary: {
    main: blueGrey[500],
    dark: blueGrey[700],
  },
  secondary: {
    // used for TabIndicator
    main: blueGrey[50],
  },
};
