import { getFileTemplate } from './download-template';

export const SAFE_TOTAL_FOR_WRITE_FILE = 80000;

export const writeDataToExcelFile = async (data, resource, filename) => {
  const sheetRows = getFileTemplate(resource, data);
  const { XLSX } = window;
  const wb = XLSX.utils.book_new(),
    ws = XLSX.utils.aoa_to_sheet(sheetRows);
  XLSX.utils.book_append_sheet(wb, ws);
  filename = `${filename}.xlsx`;
  XLSX.writeFile(wb, filename);
};
